<div class="training-section-list">
  <ngx-skeleton-loader
  *ngIf="!loader"
  [appearance]="'line'"
  count="1"
  animation="pulse"
  class="dashboard-video__title_skeleton"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="!loader"
    [appearance]="'line'"
    count="1"
    animation="pulse"
    class="dashboard-video__subTitle_skeleton"
  ></ngx-skeleton-loader>
  <div class="mb-3 d-flex align-items-end">
    <h2 class="m-0 mr-2">{{ title }}</h2>
    <p *ngIf="!!showAllLink" class="d-flex back-button m-0" (click)="goToVideos()">
      <span class="ml-2">Ver todos</span>
    </p>
  </div>

  <!-- Mobil -->
<div class="col-12 d-none d-md-block">
  <div class="video-dashboard__container_arrow" *ngIf="!loader" [ngClass]="!loader ? 'skeleton-video-pagination' : ''">
    <ngx-skeleton-loader
      [appearance]="'line'"
      count="1"
      animation="pulse"
      [theme]="{
        'background-color': '#F5F5F5',
        width: '60px',
        height: '22px',
        'border-radius': '0',
        'margin-bottom': 0,
        'padding-bottom': 0
      }"
    ></ngx-skeleton-loader>
  </div>

  <div class="video-dashboard__container_arrow" *ngIf="loader">
    <img src="/assets/icons/arrow-next.svg" class="video-dashboard_arrow_left" (click)="ListPrev()" alt="arrow next" />
    <p class="video-dashboard__arrow mb-0">&nbsp;&nbsp;&nbsp;</p>
    <img
      src="/assets/icons/arrow-next.svg"
      class="video-dashboard_arrow_right"
      (click)="ListNext()"
      alt="arrow previous"
    />
  </div>
</div>
<swiper
  #swiperVideoDashboardDesktop
  class="col-12 pl-md-0 pr-md-0 video-dashboard-banner d-none d-md-block"
  [config]="configSwiperDesktopVideo"
  [ngClass]="loader ? 'trainingSwiperDesktop' : 'hiddenVideoSwiper'"
  modules="{[Pagination,Autoplay, Navigation]}"
  *ngIf="list.length"
>    
  <ng-template swiperSlide *ngFor="let topic of list; let index = index">
    <ngx-skeleton-loader
      *ngIf="!loader"
      [appearance]="'line'"
      count="1"
      animation="pulse"
      [theme]="{
        'background-color': '#F5F5F5',
        'max-width': '262.5px',
        width: '100%',
        height: '211px',
        'border-radius': '16px',
        'margin-bottom': 0,
        'padding-bottom': 0
      }"
    ></ngx-skeleton-loader>
    <ng-container>
      <div class="training-section-list__column">
        <yevo-training-card-v1
          [topic]="topic"
          [cardTitle]="title"
          (clickTopic)="handleClick($event)"
        ></yevo-training-card-v1>
      </div>
    </ng-container>
  </ng-template>
</swiper>


<swiper
  #swiperVideoDashboard
  class="trainingSwiper col-12 pl-md-0 pr-md-0 training-dashboard-banner d-block d-md-none"
  [config]="configSwiperVideo"
  [ngClass]="loader ? 'trainingSwiper' : 'hiddenVideoSwiper'"
  modules="{[Pagination,Autoplay, Navigation]}"
  *ngIf="list.length"
>    
  <ng-template swiperSlide *ngFor="let topic of list; let index = index">
    <ngx-skeleton-loader
      *ngIf="!loader"
      [appearance]="'line'"
      count="1"
      animation="pulse"
      [theme]="{
        'background-color': '#F5F5F5',
        'max-width': '262.5px',
        width: '100%',
        height: '211px',
        'border-radius': '16px',
        'margin-bottom': 0,
        'padding-bottom': 0
      }"
    ></ngx-skeleton-loader>
  <ng-container>
    <div class="training-section-list__column">
      <yevo-training-card-v1
        [topic]="topic"
        [cardTitle]="title"
        (clickTopic)="handleClick($event)"
      ></yevo-training-card-v1>
    </div>
  </ng-container>
  </ng-template>
</swiper>

<div class="arrow-mobile-training d-block d-md-none">
  <div class="position-absolute arrow-mobile-training__container">
    <div class="video-dashboard__container_arrow mt-3" [ngClass]="loader ? 'mt-3' : 'mt-2 bg-white'">
      <img
        [src]="loader ? '/assets/icons/arrow-next.svg' : '/assets/icons/next-grey.png'"
        class="video-dashboard_arrow_left"
        (click)="listPrevMobile()"
        alt="arrow previous"
      />
      <p class="video-dashboard__arrow mb-0"></p>
      <img
        [src]="loader ? '/assets/icons/arrow-next.svg' : '/assets/icons/next-grey.png'"
        class="video-dashboard_arrow_right"
        (click)="listNextMobile()"
        alt="arrow next"
      />
    </div>
  </div>
</div>


<div class="training-section-list__row">
  </div>


</div>
