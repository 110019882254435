import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'yevo-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit {
  showBackButton: boolean = false;
  backButtonText: string = '';
  outlet: string = '';
  className: string = '';

  constructor(private route: ActivatedRoute, private router: Router) {
    const data: any = this.route.snapshot.data;
    this.className = data.type;
    this.showBackButton = data.showBackButton;
    this.backButtonText = data.backButtonText;

    this.outlet = this.route.snapshot.outlet;
  }

  ngOnInit(): void {
    //DO NOTHING
  }

  close(): void {
    this.router
      .navigate([{ outlets: { [this.outlet]: null } }], { queryParamsHandling: 'merge' })
      .catch((e) => console.error(e));
  }
}
