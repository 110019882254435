<div class="event_section container" id="calendarEventsList">
  <div class="title">{{ title }}</div>
  <div class="subtitle">{{ subtitle }}</div>
  <div class="event_cards_container" *ngIf="eventsToShow.length > 0">
    <div class="cards" *ngFor="let event of eventsToShow">
      <yevo-event-card
        [event]="event"
        [isLogged]="isLogged"
        [type]="type"
        class="yevo-event-card_class"
      ></yevo-event-card>
    </div>
  </div>
  <div class="showMoreBtn" *ngIf="!showAll && eventsToShow.length > 0">
    <button (click)="showAllEvents()">
      {{ this.page === 'FEN' ? loadMoreButtonFEN : loadMoreButton }}
    </button>
  </div>
  <div class="empty_videos" *ngIf="eventsToShow.length === 0">
    <div class="image">
      <img src="assets/icons/communities/empty_events.svg" alt="no_events_icon" />
    </div>
    <div class="title">¡Oh no!</div>
    <div class="subtitle">Por el momento no contamos con eventos para este mes.</div>
  </div>
</div>
