import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';

import { BrandHamburgerComponent } from './brand-hamburger/brand-hamburger.component';
import { FaqComponent } from './faq/faq.component';
import { ServicesComponent } from './services/services.component';
import { SolutionsDropdownComponent } from './solutions-dropdown/solutions-dropdown.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DesktopMenuUserComponent } from './desktop-menu-user/desktop-menu-user.component';
import { DesktopMenuComponent } from './desktop-menu/desktop-menu.component';
import { ExpandedMenuComponent } from './expanded-menu/expanded-menu.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SolutionsDropdownComponent,
    ServicesComponent,
    BrandHamburgerComponent,
    FaqComponent,
    ExpandedMenuComponent,
    DesktopMenuComponent,
    DesktopMenuUserComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule, NgbModule, NgxSkeletonLoaderModule],
  exports: [HeaderComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderComponentModule {}
