import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { HeaderModule } from 'src/app/design/templates/header/header.module';
import { CertificateSectionComponent } from './components/certificate-section/certificate-section.component';
import { EventsSectionComponent } from './components/events-section/events-section.component';
import { ProductsSectionComponent } from './components/products-section/products-section.component';
import { VideosSectionComponent } from './components/videos-section/videos-section.component';
import { WebinarSectionComponent } from './components/webinar-section/webinar-section.component';
import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { CarouseVideosComponent } from './components/carouse-videos/carouse-videos.component';
import { CarouseWebinarComponent } from './components/carouse-webinar/carouse-webinar.component';
import { CarouseCourseComponent } from './components/carouse-course/carouse-course.component';
import { BannerComponent } from './components/banner/banner.component';

@NgModule({
  declarations: [
    LandingComponent,
    ProductsSectionComponent,
    CertificateSectionComponent,
    VideosSectionComponent,
    WebinarSectionComponent,
    EventsSectionComponent,
    CarouseVideosComponent,
    CarouseWebinarComponent,
    CarouseCourseComponent,
    BannerComponent,
  ],
  imports: [CommonModule, HeaderModule,LandingRoutingModule, SharedModule],
  exports: [
    LandingRoutingModule,
    ProductsSectionComponent,
    CertificateSectionComponent,
    VideosSectionComponent,
    WebinarSectionComponent,
    EventsSectionComponent,
    BannerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingModule {}
