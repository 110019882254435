<ngb-pagination
  class="btn-page"
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="dataSize"
  [boundaryLinks]="true"
  [maxSize]="1"
  [ellipses]="true"
  (pageChange)="setPage($event)"
>
  <ng-template ngbPaginationFirst>
    <div class="first-item-arrow" alt="Ir al inicio"></div>
  </ng-template>
  <ng-template ngbPaginationLast>
    <div class="last-item-arrow" alt="Ir al final"></div>
  </ng-template>
  <ng-template ngbPaginationPrevious>
    <div class="previous-arrow" alt="Atrás"></div>
  </ng-template>
  <ng-template ngbPaginationNext>
    <div class="next-arrow" alt="Adelante"></div>
  </ng-template>
  <div *ngIf="style === 'secondary'">
    <ng-template ngbPaginationNumber let-page
      >Página <strong>{{ page }}</strong></ng-template
    >
  </div>
</ngb-pagination>
