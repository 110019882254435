<!-- VERSION DESKTOP -->
<ng-container *ngFor="let item of cards">
  <div *ngIf="!(item.type == 'progress' && !showCreditHealthCard)">
    <ngx-skeleton-loader
      *ngIf="!loader"
      [appearance]="'line'"
      count="1"
      animation="pulse"
      [theme]="{
        'background-color': '#F5F5F5',
        width: '166px',
        height: '150px',
        'border-radius': '16px',
        'margin-bottom': 0,
        'padding-bottom': 0
      }"
    ></ngx-skeleton-loader>
    <a [routerLink]="" queryParamsHandling="merge" class="center" (click)="selectedLinkCard(item)" *ngIf="loader">
      <ng-container *ngIf="item.type">
        <yevo-progress-spiral-card
          [type]="item.type == 'course' || item.type == 'negative' ? item.type : typeCreditHealth"
          [isNew]="!showCreditHealthCard ? item.isNew : item.type !== ''"
          [hideHeartShadow]="true"
          [labelCreditHealth]="labelCreditHealth"
        ></yevo-progress-spiral-card>
      </ng-container>

      <div *ngIf="!item.type" class="card link-card__fondo" [ngClass]="item.color">
        <div class="card-body text-center pt-3 link-card__content">
          <div class="new" *ngIf="!showCreditHealthCard ? item.isNew : false">Nuevo</div>
          <img [src]="item.image" alt="finance" class="link-card__image mb-2" />
          <a href="#" class="link-card__link d-none d-md-block px-1">{{ item.title }}</a>
        </div>
      </div>
    </a>
  </div>
</ng-container>
