import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { progressSpiralLevel } from '@shared/constants/progress-spiral.constants';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BenefitsAndVideosData, CreditHealthData, CreditHealthDataResponse } from 'src/app/core/interfaces';
import { DataLayerService } from './data-layer.service';

@Injectable({
  providedIn: 'root',
})
export class CreditHealthService {
  public diagnosticData: CreditHealthData | null = null;

  public benefitsAndVideosData: BenefitsAndVideosData | null = null;

  defaultCreditHealthData: CreditHealthDataResponse = this.initData();
  creditHealthData: CreditHealthDataResponse = this.defaultCreditHealthData;
  public finalIndexCreditHealth: number = 0;

  private isActiveFinalIndex = new BehaviorSubject<boolean>(false);
  activeFinalIndex = this.isActiveFinalIndex.asObservable();

  constructor(private http: HttpClient, private dataLayerService: DataLayerService) {}

  changeFinalIndex(value: boolean) {
    this.isActiveFinalIndex.next(value);
  }

  // CREDIT HEALTH DATA | RATE
  initData() {
    return {
      file: [],
      topic: [],
    };
  }

  getDiagnostic(): Observable<CreditHealthData> {
    return this.fetchAndSaveDiagnosticData();
  }

  private fetchAndSaveDiagnosticData(): Observable<CreditHealthData> {
    return this.getCreditHealthData().pipe(
      tap((res: CreditHealthData) => {
        this.diagnosticData = res;
        res.level = this.translateLevel(res.level);
        sessionStorage.setItem('credit-health', JSON.stringify(res));
      })
    );
  }

  private getCreditHealthData(): Observable<CreditHealthData> {
    return this.http.get<CreditHealthData>(`${environment.apiBaseUrl}/api-academy/rate`);
  }

  private translateLevel(level: string): progressSpiralLevel {
    let event: string = 'Sin clasificación';
    let creditLevel: progressSpiralLevel;

    switch (level) {
      case 'Neutra':
        creditLevel = 'neutral';
        break;
      case 'Moderada':
        event = 'Regular';
        creditLevel = 'moderate';
        break;
      case 'Por mejorar':
        event = 'Mala';
        creditLevel = 'to-improve';
        break;
      case 'Buena':
        event = 'Buena';
        creditLevel = 'good';
        break;
      default:
        creditLevel = 'neutral';
    }

    sessionStorage.setItem('creditHealthStatus', event);
    return creditLevel;
  }

  // BENEFITS AND VIDEO | SUGGESTIONS

  getBenefitsAndVideos(): Observable<BenefitsAndVideosData> {
    const savedData = this.benefitsAndVideosData;
    if (savedData != null) {
      return of(savedData);
    } else {
      return this.fetchBenefitsAndVideos();
    }
  }

  setBenefitsAndVideosData(data: BenefitsAndVideosData): void {
    this.benefitsAndVideosData = data;
  }

  private fetchBenefitsAndVideos(): Observable<BenefitsAndVideosData> {
    if (this.diagnosticData === null) {
      return this.getDiagnostic().pipe(
        switchMap(() => {
          return this.http.get<BenefitsAndVideosData>(`${environment.apiBaseUrl}/api-academy/rate/suggestions`);
        })
      );
    }

    return this.http.get<BenefitsAndVideosData>(`${environment.apiBaseUrl}/api-academy/rate/suggestions`).pipe(
      tap((data) => {
        this.setBenefitsAndVideosData(data);
      })
    );
  }

  clearData() {
    sessionStorage.removeItem('credit-health');
    sessionStorage.removeItem('creditHealthStatus');
    this.benefitsAndVideosData = null;
    this.diagnosticData = null;
  }

  getListSuggestionCreditHealth(): Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/api-academy/rate/suggestions`);
  }

  putDownLoadFile(fileId: string): Observable<any> {
    return this.http.put<any>(
      `${environment.apiBaseUrl}/api-academy/rate/${this.diagnosticData?.userCreditRateId}/file/${fileId}`,
      {}
    );
  }

  putTopicAsSeen(topicId: string): Observable<any> {
    return this.http.put<any>(
      `${environment.apiBaseUrl}/api-academy/rate/${this.diagnosticData?.userCreditRateId}/topic/${topicId}`,
      {}
    );
  }
}
