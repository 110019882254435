<div class="tabs-container overflow">
  <ul class="nav nav-pills tabs-container_mb-4" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let tab of items">
      <a [class.active]="tab.active" class="nav-link d-flex active" data-toggle="pill" (click)="selectTab(tab)"
        ><svg-icon
          [src]="'assets/icons/intranet/training/tabs/' + tab.icon + '.svg'"
          [applyClass]="true"
          class="icon"
        ></svg-icon
        >{{ tab.label }}</a
      >
    </li>
  </ul>
</div>
