import { Component } from '@angular/core';
import { CardWebinar } from '@core/interfaces';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { DataLayerService } from '@core/services/data-layer.service';

@Component({
  selector: 'academy-webinar-section',
  templateUrl: './webinar-section.component.html',
  styleUrls: ['./webinar-section.component.scss'],
})
export class WebinarSectionComponent {
  webinars: CardWebinar[] = [];
  loaded = false;
  isLoggedIn = false;
  modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private dataLayerService: DataLayerService
  ) {
    this.authService.isLogged$.subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
  }

  openRegisterModal(): void {
    this.modalService.open(ModalRegisterComponent, this.modalOptionsNormal);
  }

  handleClick(): void {
    if (this.isLoggedIn) {
      this.dataLayerService.selectedShowMoreButton('Home Interno', 6, 'Ver más webinars');
      this.router.navigate([], { queryParams: { categoria: 'webinars' } });
    } else {
      this.dataLayerService.selectedShowMoreButton('Home Externo', 6, 'Ver más webinars');
      this.openRegisterModal();
    }
  }
}
