import { Component, Input } from '@angular/core';

@Component({
  selector: 'yevo-mibanco-loader',
  templateUrl: './mibanco-loader.component.html',
  styleUrls: ['./mibanco-loader.component.scss']
})
export class MibancoLoaderComponent {
  @Input() loaderWidth: number = 80;
}
