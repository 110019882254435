import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ICheckTermAndCondition } from '@core/interfaces';
import { CHANNELS } from '@shared/constants/channels.constants';
import { BehaviorSubject, Observable, Subscription, combineLatest, timer } from 'rxjs';
import { distinctUntilChanged, map, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/UserProfileModel';
import { LoginResponse } from '../models/login.model';
import { CreditHealthService } from './credit-health.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly subscription$ = new Subscription();

  private readonly _isLogged$ = new BehaviorSubject<boolean>(false);
  private readonly _userProfile$ = new BehaviorSubject<UserProfile | null>(null);
  private readonly accessToken$ = new BehaviorSubject<string | null>(null);
  private readonly appToken$ = new BehaviorSubject<string | null>(null);
  private readonly refreshToken$ = new BehaviorSubject<string | null>(null);

  private accessTokenTimer$: Subscription | null = null;
  private appTokenTimer$: Subscription | null = null;

  public readonly userProfile$ = this._userProfile$.asObservable();
  public readonly isLogged$ = this._isLogged$.asObservable();

  private verifyRefresh: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private creditHealthService: CreditHealthService
  ) {
    (['accessTokenTimer$', 'appTokenTimer$'] as const).forEach((key) =>
      this.subscription$.add(
        this.accessToken$.subscribe((token: string | null) => {
          if (token) {
            // create timers
            const exp = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
            this[key] = timer(exp)
              .pipe(
                withLatestFrom(this._isLogged$),
                map(([_, logged]) => logged)
              )
              .subscribe((logged) => {
                if (!this.localStorageService.get('refresh_token')) {
                  const refreshToken = this.localStorageService.get('refresh_token');
                  if (refreshToken && !this.verifyRefresh) {
                    this.verifyRefresh = true;
                    this.refreshToken(refreshToken).subscribe(
                      (resp) => {
                        this.localStorageService.set('access_token', resp.access_token);
                        this.localStorageService.set('application_token', resp.application_token);
                        if (resp.refreshToken) {
                          this.localStorageService.set('refresh_token', resp.refreshToken);
                        }
                        setTimeout(() => {
                          this.verifyRefresh = false;
                        }, 10000);
                      },
                      (error) => {
                        this.localStorageService.remove('refresh_token');
                        this.logout();
                      }
                    );
                  } else {
                    this.logout();
                  }
                }
              });
          } else if (this[key]) {
            // delete timers
            this[key]?.unsubscribe();
            this[key] = null;
            // !Keep this line commented for testing
            // this.logout();
          }
        })
      )
    );

    this.subscription$.add(
      combineLatest([this.refreshToken$, this.userProfile$])
        .pipe(
          map(
            (values) =>
              values.every(Boolean) &&
              (values.slice(0, 1) as string[]) // only 1 tokens
                .every((token) => !this.tokenExpired(token))
          ),
          distinctUntilChanged()
        )
        .subscribe((s) => this._isLogged$.next(s))
    );
    {
      const user = localStorageService.get('userProfile');
      if (user) {
        const userObj = JSON.parse(user) as UserProfile;
        this._userProfile$.next(this.jsonToUserProfile(userObj));
      }
    }

    this.accessToken$.next(this.localStorageService.get('access_token'));
    this.appToken$.next(this.localStorageService.get('application_token'));
    this.refreshToken$.next(this.localStorageService.get('refresh_token'));
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  headerReCaptcha(reCaptcha: string): any {
    return new HttpHeaders({ 're-captcha-token': reCaptcha });
  }

  /**
   *
   * @param header
   * @param reCaptcha
   * @returns headers
   */
  headerLoginWithIp(ipHeader: any, reCaptcha: string) {
    return new HttpHeaders({ 're-captcha-token': reCaptcha, 'Http-Client-Ip': ipHeader });
  }

  /**
   *
   * @param login
   * @returns token header
   */
  login(login: any, header: string = ''): Observable<boolean> {
    const headers = this.headerLoginWithIp(header, login.recaptcha);

    return this.httpClient
      .post<LoginResponse>(`${environment.apiBaseUrl}/api-identity/public/oauth/token`, login, {
        headers: headers,
      })
      .pipe(
        tap((resp) => {
          this.localStorageService.set('access_token', resp.accessToken);
          this.localStorageService.set('application_token', resp.applicationToken);
          if (resp.refreshToken) {
            this.localStorageService.set('refresh_token', resp.refreshToken);
            this.refreshToken$.next(resp.refreshToken);
          }
          this.accessToken$.next(resp.accessToken);
          this.appToken$.next(resp.applicationToken);
        }),
        mapTo(true)
      );
  }

  loginAD(): Observable<boolean> {
    return this.httpClient
      .post<LoginResponse>(`${environment.apiBaseUrl}/api-identity/public/oauth/ad-token`, null)
      .pipe(
        tap((resp) => {
          this.localStorageService.set('access_token', resp.accessToken);
          this.accessToken$.next(resp.accessToken);
        }),
        mapTo(true)
      );
  }

  register(client: any): Observable<{
    activationRequired: boolean;
    userId: number;
  }> {
    return this.httpClient.post<{ activationRequired: boolean; userId: number }>(
      `${environment.apiBaseUrl}/api-identity/public/users/register`,
      { ...client, creationChannel: CHANNELS.ACADEMY },
      {
        headers: this.headerReCaptcha(client.recaptcha),
      }
    );
  }

  registerWithActivation(client: any): Observable<{
    activationRequired: boolean;
    userId: number;
  }> {
    return this.httpClient.post<{ activationRequired: boolean; userId: number }>(
      `${environment.apiBaseUrl}/api-identity/public/users/activate/email`,
      client,
      {
        headers: this.headerReCaptcha(client.recaptcha),
      }
    );
  }

  getUserProfile(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(`${environment.apiBaseUrl}/api-identity/user`).pipe(
      tap((resp) => {
        this.localStorageService.set('userProfile', JSON.stringify(resp));
        this._userProfile$.next(this.jsonToUserProfile(resp));
      }),
      map((data) => {
        return this.jsonToUserProfile(data) as UserProfile;
      })
    );
  }

  testUserProfile() {
    this.httpClient.get<UserProfile>(`${environment.apiBaseUrl}/api-identity/user`).subscribe();
  }

  activate(activationToken: string): Observable<boolean> {
    return this.httpClient
      .post(`${environment.apiBaseUrl}/api-identity/public/users/activate/email`, {
        activationToken,
      })
      .pipe(mapTo(true));
  }

  recoverPassword(recover: any): Observable<boolean> {
    return this.httpClient
      .post(`${environment.apiBaseUrl}/api-identity/public/users/send-password-token`, recover, {
        headers: this.headerReCaptcha(recover.recaptcha),
      })
      .pipe(mapTo(true));
  }

  changePassword(token: string, newPassword: string, repeatNewPassword: string): Observable<boolean> {
    return this.httpClient
      .put(`${environment.apiBaseUrl}/api-identity/public/users/${token}/password`, {
        newPassword,
        repeatNewPassword,
        passwordChannel: CHANNELS.ACADEMY,
      })
      .pipe(mapTo(true));
  }

  /**
   * Refresh the access token
   * @param redirect
   */

  logout(redirect: string = 'ingresar'): void {
    this.localStorageService.remove('refresh_token');
    sessionStorage.clear();
    this.removeSavedValues();
    this.router.navigate([`/${redirect}`], { queryParamsHandling: 'merge' });
  }

  refreshTokenSession() {
    const refreshToken = this.localStorageService.get('refresh_token') || '';
    this.refreshToken(refreshToken).subscribe(
      (resp) => {
        this.localStorageService.set('access_token', resp.access_token);
        this.localStorageService.set('application_token', resp.application_token);

        if (resp.refreshToken) {
          this.localStorageService.set('refresh_token', resp.refreshToken);
          this.refreshToken$.next(resp.refreshToken);
        }

        this.accessToken$.next(resp.access_token);
        this.appToken$.next(resp.application_token);
      },
      (error) => {
        this.localStorageService.remove('reloaded');
        this.localStorageService.remove('refresh_token');
        this.logout();
      }
    );
  }

  refreshTokenSessionWithoutRedirect(resp: any) {
    const accessToken = resp.access_token ?? resp.accessToken;
    this.accessToken$.next(accessToken);

    const appToken = resp.application_token ?? resp.applicationToken;
    this.appToken$.next(appToken);
    if (resp.refreshToken) {
      this.refreshToken$.next(resp.refreshToken);
    }
  }

  removeSavedValues(): void {
    this.creditHealthService.clearData();
    if (!this.localStorageService.get('refresh_token')) {
      // delete localstorage
      this.localStorageService.set('activePage', '-1');
      this.localStorageService.set('activePageUrlSubmenu', '-1');
      (['access_token', 'application_token', 'userProfile'] as const).forEach((key) => {
        this.localStorageService.remove(key);
      });
      // remove values of BehaviorSubjects
      ([this.accessToken$, this.appToken$, this.refreshToken$, this._userProfile$] as const).forEach((obs) => {
        obs.next(null);
      });
    }
  }

  resentEmail(email: string): Observable<Boolean> {
    return this.httpClient
      .post(`${environment.apiBaseUrl}/api-identity/public/users/resend-activation-email`, { email })
      .pipe(mapTo(true));
  }

  /**
   *
   * @param refreshToken
   * @returns header with refresh token
   */
  refreshToken(refreshToken: string): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/api-identity/public/oauth/refresh-token`, { refreshToken });
  }

  checkDocument(document: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        document,
      },
    });
    return this.httpClient.get(`${environment.apiBaseUrl}/api-identity/public/users/check`, {
      params,
    });
  }

  public checkTermsAndConditions(data: ICheckTermAndCondition): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.apiBaseUrl}/api-identity/user/checkbox`, data).pipe(mapTo(true));
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  // TODO: This function should be part of UserProfile
  private jsonToUserProfile(user: UserProfile): UserProfile | null {
    return new UserProfile(
      user.userId,
      user.typeDocument,
      user.documentNumber,
      user.department,
      user.district,
      user.province,
      user.ubigeo,
      user.latitude,
      user.longitude,
      user.levels,
      user.address,
      user.name,
      user.fatherLastName,
      user.motherLastName,
      user.phone,
      user.email,
      user.directoryId,
      user.businessName,
      user.hasFirstLogin,
      user.client,
      user.hasPunishedDebt,
      user.segment,
      user.shareThirdParties,
      user.worker,
      user.visited,
      user.imageUrl,
      user.imageHeaderUrl,
      user.progress,
      user.hasSeidorAccount,
      user.userInfoResponse,
      user?.segmentCode,
      user?.bornDate,
      user?.roleId
    );
  }
}
