<div class="training-card rounded-top" [ngClass]="dashboard ? 'card pb-2 dashboard__card' : ''" (click)="handleClick()">
  <figure>
    <yevo-training-badge
      *ngIf="topic.newTopic"
      class="mb-2"
      [type]="'new'"
      [top]="true"
      (click)="handleClickVideo(topic.type)"
    ></yevo-training-badge>
    <img [src]="topic.thumbnail" (click)="handleClickVideo(topic.type); toVideo(topic.slug)" alt="cursos" />
  </figure>
  <div class="training-card__info pt-3" [ngClass]="content ? 'pl-0 pr-0' : 'pl-2 pr-2'">
    <div class="d-flex mb-2">
      <yevo-training-badge
        class="mb-2"
        [type]="topic.type"
        [dashboard]="dashboard"
        (click)="handleClickButtonVideo(topic.type); toVideo(topic.slug)"
      ></yevo-training-badge>
    </div>
    <p
      class="training-card__title"
      [ngClass]="dashboard ? 'dashboard__title' : ''"
      (click)="handleClickButtonVideo(topic.type); toVideo(topic.slug)"
    >
      {{ topic.title }}
    </p>
    <p *ngIf="cardTitle !== mostRecent" class="training-card__description" [ngClass]="dashboard ? 'd-none' : ''">
      {{ getTextFromHtml(topic.description) }}
    </p>

    <button
      class="training-card__button"
      (click)="handleClickButtonVideo(topic.type); toVideo(topic.slug)"
      [ngClass]="education ? 'training-card__button_rounded' : ''"
    >
      {{ getActionButtonText(topic.type) }}
    </button>
  </div>
</div>
