import { Injectable } from '@angular/core';
import { LogglyService } from './loggly.service';

@Injectable({
  providedIn: 'root',
})
export class UserInteractionLogService {
  constructor(private logglyService: LogglyService) {}

  logUserAction(
    userIdentifier: string,
    eventIdentifier: 'click' | 'swipe',
    objectiveIdentifier: string,
    moduleName: 'Asesoría' | 'Dashboard' | 'Login'
  ) {
    this.logglyService.log(
      `Usuario: ${userIdentifier} hizo ${eventIdentifier} en ${objectiveIdentifier} en el modulo: ${moduleName}`,
      {}
    );
  }
}
