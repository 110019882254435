<ld-json-script></ld-json-script>

<div
  class="finance_banner d-none"
  [class.d-flex]="!dataSearch.length && !isSearch && !showDetail && !showTabContent && !showTopicContent"
>
  <div class="finance_banner_content animate__animated animate__fadeInLeft">
    <div class="banner_text">
      <img src="assets/icons/money_hand.png" alt="icono de mano con billetes" />
      <p><span>Calcula y pide tu préstamo</span>, con el respaldo de Mibanco.</p>
    </div>
    <a
      class="banner_link"
      href="https://lab.mibanco.com.pe/financiamiento/solicitud?utm_source=academiadelprogreso&utm_medium=bannerpeque%C3%B1o&utm_campaign=pidetuprestamo"
      target="_blank"
      rel="noopener"
    >
      <p>Ingresa aquí</p>
      <img src="assets/icons/banner_finance_arrow_right.png" alt="flecha que apunta hacia la derecha" />
    </a>
  </div>
</div>

<div [class.d-block]="!showDetail" class="d-none trainingListContainer">
  <div class="trainingListItems div-menu-inside mt-md-4 animate__animated animate__fadeInRight" >
    <section
      class="d-none"
      [class.d-block]="!dataSearch.length && !isSearch && !showDetail && !showTabContent && !showTopicContent"
    >
      <h1 class="section-title">
        ¡Hola, {{ user.name.split(' ')[0] }}! <span class="d-none d-md-inline">Mira todo lo que puedes hacer hoy</span>
      </h1>
      <h1 class="section-title d-block d-md-none"><span>Mira todo lo que puedes hacer hoy</span></h1>
      <yevo-link-card
        class="d-flex justify-content-center align-items-center custom-gap my-3 my-md-5 saved-height"
        [loader]="cardsLoaded"
        [typeCreditHealth]="typeCreditHealth"
        [labelCreditHealth]="labelCreditHealth"
      ></yevo-link-card>
    </section>

    <div
      [class.d-block]="showDetail || showTabContent || dataSearch.length || isSearch"
      class="mb-3 mb-lg-5 d-none custom-margin"
    >
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs" queryParamsHandling=""></yevo-breadcrumb>
    </div>

    <h1
      [class.d-block]="!dataSearch.length && !isSearch && !showDetail && !showTabContent && !showTopicContent"
      class="section-title d-none"
    >
      Certifícate con nosotros
    </h1>
    <p class="text-title">¿Qué tema quieres aprender hoy?</p>

    <div class="typeahead-container mb-3 w-100">
      <yevo-typeahead
        placeholder="Escribe aquí el tema a aprender"
        [training]="true"
        [allData]="allDataSearch"
        [startSearchAt]="3"
        [webinarsMostVisited]="webinarsMostVisited"
        (selectItem)="trainingSelected($event)"
        (dataLayerEvent)="pushSearchResultEvent($event)"
        (searchValue)="searchValue($event)"
        (searchEvent)="pushSearchResultEvent($event)"
        (searchClear)="searchClear($event)"
        class="w-100"
      ></yevo-typeahead>

      <div class="notFound w-100 mb-4 d-none" [class.d-flex]="!resultSearch && isSearch">
        <p class="mb-0">
          Lo lamentamos. No encontramos un resultado que coincida con tu búsqueda. Vuelve a buscar o prueba con una
          palabra clave diferente.
        </p>
      </div>
    </div>

    <div class="categories-pills mb-0 mb-3 overflow d-none d-lg-block">
      <yevo-filter-pills
        [items]="topicOptionsDropdown"
        [showSliderPills]="true"
        [itemSelected]="selectedTopic"
        [showSeeMore]="false"
        [dropdownPlacement]="['bottom-right']"
        dropdownTitle="Ver temas"
        [numberPillsShowed]="numberTopicOptionsShowed"
        [showJustifyContentBetween]="false"
        [withHandleOption]="false"
        (selectItem)="getTopicsSelected($event.item?.value || '', $event.item?.label || '')"
        class="pill-training"
      ></yevo-filter-pills>
    </div>
    <div class="categories-pills overflow d-lg-none mb-3">
      <yevo-filter-pills
        [items]="topicOptionsDropdown"
        [showSliderPills]="true"
        [itemSelected]="selectedTopic"
        [showSeeMore]="false"
        [dropdownPlacement]="['bottom-right']"
        dropdownTitle="Ver temas"
        [numberPillsShowed]="numberTopicOptionsShowed"
        [showJustifyContentBetween]="false"
        [withHandleOption]="false"
        (selectItem)="getTopicsSelected($event.item?.value || '', $event.item?.label || '')"
        class="pill-training"
      ></yevo-filter-pills>
    </div>
  </div>
  <div [class.d-block]="!showDetail" class="trainingListItems d-none">
    <yevo-training-tabs
      [items]="tabItems"
      [selectedItem]="selectedTab"
      (selectedItemEmit)="handleSelectedTab($event)"
      class="taps-scroll"
    ></yevo-training-tabs>
  </div>
  <div
    class="d-none"
    [class.d-block]="!dataSearch.length && !isSearch && !showDetail && !showTabContent && !showTopicContent"
  >
    <section class="courses-section">
      <div class="courses-container">
        <academy-carousel cardsType="course" [courses]="courses" [loaded]="true"></academy-carousel>
      </div>
      <div class="view-more" *ngIf="courses.length > 0">
        <button (click)="handleSelectedTab(tabItems[0], 'Cursos'); selectedSwhoMore()" class="d-md-block d-none">
          Ver todos los cursos
        </button>
        <button
          (click)="handleSelectedTab(tabItems[0], 'Cursos'); selectedSwhoMore()"
          class="button-mobile d-md-none d-block"
        >
          Ver más cursos
        </button>
      </div>
    </section>

    <academy-videos-section></academy-videos-section>

    <section class="mt-2 mt-lg-3 mb-lg-5 resources-section">
      <yevo-training-downloads></yevo-training-downloads>
    </section>

    <academy-webinar-section></academy-webinar-section>

    <academy-events-section></academy-events-section>
  </div>
  <ng-container *ngIf="!isSearch">
    <div
      *ngIf="
        (!dataSearch.length && !showDetail && !showTabContent) ||
        (!dataSearch.length && !showDetail && showTabContent && selectedTab.label == 'Cursos')
      "
      class="spacer mt-4"
    >
      <div class="trainingListItems" *ngIf="selectedTab?.label == 'Cursos'">
        <div class="mt-lg-3">
          <yevo-training-section-bank
            title="Cursos"
            [code]="codeSelected"
            [tab]="selectedTab.label"
            [courses]="allCourses"
          ></yevo-training-section-bank>
        </div>
      </div>
    </div>
    <div
      *ngIf="dataSearch.length === 0 && !showDetail && showTopicContent && !showTabContent"
      class="trainingListContainer"
    >
      <div class="trainingListItems">
        <div *ngIf="topics && topics.length > 0 && (selectedTab == undefined || selectedTab.label != 'Cursos')">
          <h2 class="text-title title-topic mt-0">{{ selectedTopic?.label }}</h2>
          <div class="row card-webinars">
            <yevo-training-card-v1
              [topic]="topic"
              [cardTitle]="topic.title"
              (clickTopic)="viewShowDetail($event)"
              *ngFor="let topic of topics"
              class="mb-3"
            ></yevo-training-card-v1>
          </div>
        </div>
        <div *ngIf="codeSelected == codePower && selectedTab.label == 'Todos'">
          <yevo-training-section-bank
            title="Mujeres Poderosas"
            [code]="codeSelected"
            [tab]="selectedTab.label"
            [courses]="allCourses"
          ></yevo-training-section-bank>
        </div>
        <div
          class="w-full centered"
          *ngIf="topics && topics.length == 0 && (selectedTab == undefined || selectedTab.label != 'Cursos') && codeSelected != codePower"
        >
          <br /><br />
          <img
            src="/assets/img/intranet/training/no-filter-option.png"
            alt="Por el momento no contamos con contenido disponible"
          />
          <br /><br />
          <p class="titlewarning">¡Oh no! Por el momento no contamos con contenido disponible para esta sección.</p>
          <br />
          <p class="subtitlewarning">Nos encontramos trabajando para asesorarte con nuevos contenidos.</p>
        </div>
        <div class="w-full box-page trainingPagination d-flex justify-content-center mb-5">
          <yevo-pagination-v2
            [page]="page"
            [dataSize]="total"
            [pageSize]="pageSize"
            (pageChange)="pageChangeEvent($event)"
          ></yevo-pagination-v2>
        </div>
      </div>
    </div>
    <div *ngIf="!dataSearch.length && !showDetail && showTabContent" class="trainingListContainer mt-0 mt-md-4">
      <div class="trainingListItems">
        <h2 class="text-title title-topic mt-0 mb-3 mb-md-5" *ngIf="topics && topics.length">
          {{ selectedTab.label }}
        </h2>
        <div class="row card-webinars">
          <div *ngFor="let topic of topics" class="mb-3">
            <ng-container *ngIf="topic.type == 'html' || topic.type == 'article'">
              <yevo-training-card [topic]="topic" (clickTopic)="viewShowDetail($event)"></yevo-training-card>
            </ng-container>
            <ng-container *ngIf="topic.type == 'video'">
              <yevo-training-card-v1
                [topic]="topic"
                [cardTitle]="topic.title"
                (clickTopic)="viewShowDetail($event)"
              ></yevo-training-card-v1>
            </ng-container>
            <ng-container *ngIf="topic.type == 'webinar'">
              <yevo-training-card-v1
                [topic]="topic"
                [cardTitle]="topic.title"
                (clickTopic)="viewShowDetail($event)"
              ></yevo-training-card-v1>
            </ng-container>
          </div>
        </div>
        <div class="w-full centered" *ngIf="topics && topics.length == 0 && selectedTab.label != 'Cursos'">
          <br /><br />
          <img
            src="/assets/img/intranet/training/no-filter-option.png"
            alt="Por el momento no contamos con contenido disponible"
          />
          <br /><br />
          <p class="titlewarning">¡Oh no! Por el momento no contamos con contenido disponible para esta sección.</p>
          <br />
          <p class="subtitlewarning">Nos encontramos trabajando para asesorarte con nuevos contenidos.</p>
        </div>
        <div class="w-full box-page trainingPagination d-flex justify-content-center mb-5">
          <yevo-pagination-v2
            [page]="page"
            [dataSize]="total"
            [pageSize]="pageSize"
            (pageChange)="pageChangeEvent($event)"
          ></yevo-pagination-v2>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="dataSearch.length && !isSearch" class="trainingListContainer mt-0 mt-md-4">
    <div class="trainingListItems">
      <div class="row card-search mt-4 mt-md-0">
        <div *ngFor="let topic of dataSearch" class="mb-3">
          <yevo-training-card
            [topic]="topic"
            (clickTopic)="viewShowDetail($event)"
            *ngIf="topic.type == 'html' || topic.type == 'article'"
          ></yevo-training-card>
          <yevo-training-card-v1
            *ngIf="topic.type == 'video' || topic.type == 'dir' || topic.type == 'webinar'"
            [topic]="topic"
            [cardTitle]="topic.title"
            (clickTopic)="viewShowDetail($event)"
          ></yevo-training-card-v1>
          <academy-course *ngIf="topic.type == 'course'" [course]="topic" [isLoggedIn]="true"></academy-course>
        </div>
      </div>
      <div class="w-full box-page trainingPagination d-flex justify-content-center mb-5">
        <yevo-pagination-v2
          class="trainingPagination-pagination"
          [page]="pageSearch"
          [dataSize]="auxDataSearch.length"
          [pageSize]="pageSize"
          (pageChange)="pageChangeEventSearch($event)"
        ></yevo-pagination-v2>
      </div>
    </div>
  </div>
  <div class="trainingListContainer" *ngIf="!resultSearch && isSearch">
    <div class="trainingListItems mb_32">
      <div>
        <div class="mb_32 mt-3 mt-md-5">
          <yevo-training-section-list-v1
            title="Los videos más vistos"
            [list]="topicsMostViewed"
            (clickTopic)="viewShowDetail($event, 'Lo más visto')"
            (clickSeeAll)="handleSelectedTab(tabItems[2], 'Videos')"
            [showAllLink]="['/nuevo-intranet/dasboard?categoria=videos']"
          ></yevo-training-section-list-v1>
        </div>
      </div>
    </div>
  </div>
</div>
