<div class="modal-body">
  <div class="d-flex justify-content-end w-100">
    <img src="assets/icons/close.svg" alt="x para cerrar ventana" (click)="validOtp.emit(false)" />
  </div>
  <ng-container *ngIf="!isContactViewVisible; else contactView">
    <h1 class="text-center">Verificación de identidad</h1>
    <p class="text-center my-4">
      Para continuar con este proceso escribe el código de verificación enviado a tu correo
      <span class="green-color">{{ userEmail }}</span>
    </p>
    <p class="text-center"><strong>Escribe el código aquí</strong></p>
    <form class="mt-3" [formGroup]="otpForm" (ngSubmit)="validateOTP()">
      <div class="d-flex justify-content-center">
        <input
          #digit1
          class="otp-digit"
          type="text"
          formControlName="digit1"
          maxlength="1"
          placeholder="0"
          (keypress)="handleDigitKeypress($event, 1)"
          onlynumber
        />
        <input
          #digit2
          class="otp-digit"
          type="text"
          formControlName="digit2"
          maxlength="1"
          placeholder="0"
          (keypress)="handleDigitKeypress($event, 2)"
          (keydown)="handleDigitKeydown($event, 2)"
          onlynumber
        />
        <input
          #digit3
          class="otp-digit"
          type="text"
          formControlName="digit3"
          maxlength="1"
          placeholder="0"
          (keypress)="handleDigitKeypress($event, 3)"
          (keydown)="handleDigitKeydown($event, 3)"
          onlynumber
        />
        <input
          #digit4
          class="otp-digit"
          type="text"
          formControlName="digit4"
          maxlength="1"
          placeholder="0"
          (keypress)="handleDigitKeypress($event, 4)"
          (keydown)="handleDigitKeydown($event, 4)"
          onlynumber
        />
        <input
          #digit5
          class="otp-digit"
          type="text"
          formControlName="digit5"
          maxlength="1"
          placeholder="0"
          (keydown)="handleDigitKeydown($event, 5)"
          onlynumber
        />
      </div>
      <div class="d-flex justify-content-around mt-3">
        <p class="timer">
          Tiempo restante: <span [ngClass]="{ red: timeCounter < 10 }">{{ timeCounter | formatTimeMMSS }}</span>
        </p>
        <div class="attempts-number">
          <yevo-button [className]="'link'" [disabled]="isMaxResendAttemptsReached" (click)="handleResendCode($event)">
            Reenviar código ({{ currentResendAttempts }}/{{ MAX_RESEND_ATTEMPTS }})
          </yevo-button>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <div class="submit-button">
          <yevo-button [type]="'submit'" [disabled]="otpForm.invalid || isSubmitDisabled || isMaxOtpValidationReached">
            {{ submitButtonLabel }}
          </yevo-button>
        </div>
      </div>
    </form>
    <p *ngIf="isOtpMatchErrorVisible" class="validation-error-message text-center mt-2">
      El código no es el correcto. Por favor, inténtalo nuevamente.
    </p>
    <div *ngIf="isMaxOtpValidationReached || isTimeCounterFinished" class="no-attempts-message d-block d-sm-flex mt-2">
      <p class="text-center mr-sm-3">
        <ng-container *ngIf="isCloseModalMessageShown; else elseTemplate">
          Excediste el número de intentos permitidos y/ó el tiempo para completar el código ha terminado. Por favor, haz
          clic en aceptar para cerrar la ventana y vuelve a intentarlo en 1 minuto.
        </ng-container>
        <ng-template #elseTemplate>
          <ng-container *ngIf="isMaxOtpValidationReached">
            Excediste el número de intentos permitidos. Por favor haz clic en "reenviar código" para obtener uno nuevo.
          </ng-container>
          <ng-container *ngIf="isTimeCounterFinished">
            El tiempo para completar el código ha terminado. Por favor haz clic en "reenviar código" para obtener uno
            nuevo
          </ng-container>
        </ng-template>
      </p>
      <div *ngIf="isCloseModalMessageShown">
        <p class="mobile-accept-button text-right mt-1 d-sm-none"><a (click)="validOtp.emit(false)">Aceptar</a></p>
        <div class="d-flex align-items-center h-100">
          <div class="accept-button d-none d-sm-flex">
            <yevo-button [className]="'danger'" (buttonClick)="validOtp.emit(false)"><p>Aceptar</p></yevo-button>
          </div>
        </div>
      </div>
    </div>
    <p class="no-email-received text-center mt-3">
      ¿No recibiste ningún correo? <br />
      <a (click)="isContactViewVisible = true">Contáctanos</a>
    </p>
  </ng-container>
  <ng-template #contactView>
    <div class="contact-view-container">
      <h1 class="text-center">¡Estamos aquí para ayudarte!</h1>
      <p class="text-center my-5">
        Si no recibiste ningún correo es probable que no tengamos el correcto. Envíanos una solicitud para modificar tu
        correo y te atenderemos a la brevedad posible.
      </p>
      <div class="d-flex flex-column align-items-center">
        <div class="send-solicitude">
          <yevo-button (click)="handleSendSolicitude($event)">Enviar solicitud</yevo-button>
        </div>
        <div class="back mt-2">
          <yevo-button [className]="'link'" (buttonClick)="isContactViewVisible = false">Volver</yevo-button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
