import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OtpComponent } from '@shared/components/otp/otp.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OtpModalService {
  private modalInstance!: NgbModalRef;
  private userEmail!: string;
  private modalResults = new Subject<boolean>();
  modalResults$ = this.modalResults.asObservable();

  private isActiveSeidorModal = new BehaviorSubject<boolean>(false);
  activeModalSeidor = this.isActiveSeidorModal.asObservable();

  constructor(private modalService: NgbModal, private authService: AuthService) {
    this.authService.userProfile$.subscribe((user) => {
      this.userEmail = user?.email!;
    });
  }

  changeSeidorError(value: boolean) {
    this.isActiveSeidorModal.next(value);
  }

  open(): Observable<boolean> {
    this.modalInstance = this.modalService.open(OtpComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    this.modalInstance.componentInstance.userEmail = this.userEmail;
    return this.modalInstance.componentInstance.validOtp;
  }

  close() {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }
}
