<div class="videos-section">
  <div class="videos-container">
    <h1>Videos destacados</h1>
    <p>Aprende de manera 100% práctica e interactiva en cuestión de minutos.</p>
     <academy-carouse-videos cardsType="video"></academy-carouse-videos>
    <div class="view-more">
      <button (click)="handleClick()" class="d-md-block d-none">Ver más videos</button>
      <button (click)="handleClick()" class="button-mobile d-md-none d-block">Ver más videos</button>
    </div>
  </div>
</div>
