import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DropdownSmallMenuComponent } from './components/dropdown-small-menu/dropdown-small-menu.component';
import { SharedModule } from '@shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    DesktopHeaderComponent, 
    MobileHeaderComponent, 
    HeaderComponent, 
    DropdownMenuComponent, 
    DropdownSmallMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule, 
    NgbModule, 
    NgxSkeletonLoaderModule,
  ],
  exports: [
    HeaderComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule { }
