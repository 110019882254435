import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../interfaces/header.interface';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private isMobileMenuOpenSubject = new BehaviorSubject<boolean>(false);
  isMobileMenuOpen$ = this.isMobileMenuOpenSubject.asObservable();

  private isDesktopMenuOpenSubject = new BehaviorSubject<boolean>(false);
  isDesktopMenuOpen$ = this.isDesktopMenuOpenSubject.asObservable();

  private isDesktopConfigMenuOpenSubject = new BehaviorSubject<boolean>(false);
  isDesktopConfigMenuOpen$ = this.isDesktopConfigMenuOpenSubject.asObservable();

  private desktopMenuDataSubject = new BehaviorSubject<MenuItem | null>(null);
  desktopMenuData$ = this.desktopMenuDataSubject.asObservable();

  private desktopConfigMenuDataSubject = new BehaviorSubject<MenuItem | null>(null);
  desktopConfigMenuData$ = this.desktopConfigMenuDataSubject.asObservable();

  private isRedirectToCalendarSubject = new BehaviorSubject<boolean>(false);
  isRedirectToCalendar$ = this.isRedirectToCalendarSubject.asObservable();

  private isRedirectToDownloadFilesSubject = new BehaviorSubject<boolean>(false);
  isRedirectToDownloadFiles$ = this.isRedirectToDownloadFilesSubject.asObservable();

  public toggleMobileMenu(isOpen: boolean): void {
    this.isMobileMenuOpenSubject.next(isOpen);
  }

  public toggleDesktopMenu(isOpen: boolean): void {
    this.isDesktopMenuOpenSubject.next(isOpen);
  }

  public toggleDesktopConfigMenu(isOpen: boolean): void {
    this.isDesktopConfigMenuOpenSubject.next(isOpen);
  }

  public setDesktopMenuData(menuData: MenuItem | null): void {
    this.desktopMenuDataSubject.next(menuData);
  }

  public setDesktopConfigMenuData(menuData: MenuItem | null): void {
    this.desktopConfigMenuDataSubject.next(menuData);
  }

  public validateDesktopMenuData(menuData: MenuItem | null): boolean {
    return this.desktopMenuDataSubject.value === menuData;
  }

  public setRedirectToCalendar(status: boolean): void {
    this.isRedirectToCalendarSubject.next(status);
  }

  public setRedirectToDownloadFiles(status: boolean): void {
    this.isRedirectToDownloadFilesSubject.next(status);
  }
}
