<div class="badge-container" [ngClass]="{ 'badge-container-top': top }">
  <span class="badge d-flex justify-content-between align-items-center" [ngClass]="'badge-' + badgeThemeSelected.class">
    <span class="mx-1">{{ badgeThemeSelected.label }}</span>
    <img
      *ngIf="badgeThemeSelected.source"
      [src]="'/assets/icons/intranet/training/' + badgeThemeSelected.source"
      alt="badge-icon"
      class="ml-2 mr-1"
    />
  </span>
</div>
