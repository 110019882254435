import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from 'src/app/core/guards/authenticated.guard';

import { NewIntranetComponent } from './new-intranet.component';
import { TrainingComponent } from '../intranet/training/training.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: NewIntranetComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: '',
        component: TrainingComponent,
        canActivate: [AuthenticatedGuard],
        data: {
          title: 'Dashboard - Academia del Progreso',
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/nuevo-intranet/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewIntranetRoutingModule {}
