export enum SAVINGS_ACCOUNT_MODALS {
  SENT_SOLICITUDE = 'sent-solicitude',
  SWORN_STATEMENT = 'sworn-statement',
  FOREIGN_CLIENT = 'foreign-client',
  BANK_CLIENT = 'bank-client',
  PERSONAL_DATA = 'personal-data',
}

export type savingsAccountModalTypes =
  | 'sent-solicitude'
  | 'sworn-statement'
  | 'foreign-client'
  | 'bank-client'
  | 'personal-data';

export type savingsAccountFormType = 'full' | 'business';

export enum SAVING_ACCOUNT_MARKS_FORM {
  FULL = 'Cuenta Full Ahorro',
  BUSINESS = 'Cuenta Ahorro Negocios',
}
