export enum OtpStatusResponse {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  MAX_ATTEMPTS_REACHED = 'MaxAttemptsReached',
  EXPIRED = 'Expired',
}

export interface IOtpRequest {
  channel: 'email' | 'sms';
  code?: string;
}

export interface IOtpResponse {
  status: OtpStatusResponse;
  valid: boolean;
}
