import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests$ = new BehaviorSubject<HttpRequest<any>[]>([]);

  constructor(private loaderService: LoaderService) {
    this.requests$.subscribe(reqList => {
      if (this.loaderService.isLoading.getValue() !== reqList.length > 0) {
            this.loaderService.isLoading.next(reqList.length > 0);
      }
    });
  }

  removeRequest(req: HttpRequest<any>) {
    this.requests$.pipe(take(1)).subscribe(reqList => {
      const i = reqList.indexOf(req);
      if (i >= 0) reqList.splice(i, 1);
      this.requests$.next(reqList);
    });
  }

  addRequest(req: HttpRequest<any>) {
    this.requests$.pipe(take(1)).subscribe(reqList => {
      this.requests$.next([...reqList, req]);
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.addRequest(req);
    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
