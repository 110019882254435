<a
  class="navbar-brand"
  [routerLink]="brandLink"
  queryParamsHandling="merge"
  routerLinkActive="active"
  [ngClass]="{
    'd-none': !showLogoMobile,
    'd-lg-none': !showLogoDesktop,
    'd-lg-block': showLogoDesktop,
    'no-space-right': !mobileButtonRight
  }"
>
  <div>
    <img
      [src]="brandImage"
      width="118"
      class="d-inline-block align-top img  pading-10"
      loading="lazy"
      [ngClass]="{
        'brand-hamburger-img': open && isLOgged
      }"
      alt="brand-image"
      (click)="removeMenu()"
    />
  </div>
</a>
<div class="center">
  <p *ngIf="!isLOgged" class="session mr-2" [ngClass]="{ green: open }" (click)="login()">Iniciar sesión</p>
  <button
    class="navbar-toggler button-toggle rounded-circle p-0 border-0"
    type="button"
    aria-controls="navbarNav"
    [attr.aria-expanded]="open"
    aria-label="Toggle navigation"
    (click)="onClickMenuHandler()"
  >
    <img src="/assets/icons/closesmallgreen.svg" alt="close" *ngIf="open" />
    <svg-icon-sprite
      [src]="open ? 'close' : 'menu'"
      [width]="open ? '0.7em' : '1em'"
      [height]="open ? '0.7em' : '1em'"
      [ngClass]="open ? 'd-none' : 'd-block'"
    >
    </svg-icon-sprite>
  </button>
</div>