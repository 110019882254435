import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthenticatedGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.checkAuthorization();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuthorization();
  }

  private checkAuthorization(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isPlatformServer(this.platformId)) {
      return true;
    }

    const source: any = this.authService.isLogged$.source;

    const isValid = source.observers.length > 0;
    return this.authService.isLogged$.pipe(
      map((res) => {
        if (!res && isValid) {
          this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        } else if (!isValid) {
          this.authService.testUserProfile();
        }
        return res;
      }),
      catchError((err) => {
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        return of(false);
      })
    );
  }
}
