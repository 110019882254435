<div class="card-course">
  <div class="card-course-body">
    <div class="card-course-img">
      <img [src]="course.thumbnail" [alt]="course.title" />
      <div class="tags-container">
        <span
          class="tag"
          [class.tag_new]="tag === 'Nuevo'"
          [class.tag_certificate]="tag === 'Certificado'"
          *ngFor="let tag of course.tags"
          >{{ tag }}</span
        >
      </div>
    </div>
    <p class="tag">Curso</p>
    <div class="card-course-text">
      <p class="title">{{ course.title }}</p>
      <p class="description">{{ course.description }}</p>
    </div>
  </div>
  <button (click)="navigateCourse(course)">Ver curso</button>
</div>
