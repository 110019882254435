import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from '@core/services/data-layer.service';

@Component({
  selector: 'academy-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, AfterViewInit {
  constructor(
    public dataLayerService: DataLayerService,
    public router: Router,
    @Inject(DOCUMENT) private _document: any
  ) {}

  renderCount = 0;
  animate: boolean = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.animate = true;
    }, 3000);
  }

  ngAfterViewInit(): void {
    this.renderCount++;
    const bannerElement = this._document.getElementById('banner');
    if (
      !bannerElement.classList.contains('animate__animated') &&
      !bannerElement.classList.contains('animate__fadeInLeft')
    ) {
      bannerElement.classList.add('animate__animated', 'animate__fadeInLeft');
    }

    //remover al terminar la animacion
    bannerElement.addEventListener('animationend', () => {
      bannerElement.classList.remove('animate__animated', 'animate__fadeInLeft');
    });
  }

  clickBtnBanner(): void {
    this.dataLayerService.selectedHomeCard('Home Externo', 1, 'Banner Academia del Progreso', 'Regístrate aquí');
    this.router.navigate(['registro']);
  }
}
