<div *ngIf="!showDetail" id="main" class="trainingMainBanner carousel-fade p-0 d-none d-lg-block">
  <ngb-carousel
    #carousel
    [interval]="interval"
    [pauseOnHover]="pauseOnHover"
    [pauseOnFocus]="pauseOnFocus"
    [showNavigationArrows]="false"
    [showNavigationIndicators]="false"
  >
    <ng-template ngbSlide *ngFor="let slide of carouselSlidesArray">
      <div class="carousel-item active" [ngClass]="slide.theme">
        <div class="trainingListItems carousel-information mt-3">
          <br />
          <a class="d-flex back-button" [routerLink]="['/nuevo-intranet', 'dashboard']" queryParamsHandling="merge">
            <span class="icon align-self-center">
              <img class="images__person" src="/assets/img/intranet/training/arrow-left-white.svg" alt="person" />
            </span>
            <span class="ml-2">Regresar</span>
          </a>
          <h2 class="mt-4 mb-3">
            ¡Hola emprendedor, bienvenido a <br />
            nuestra sección de capacitaciones!
          </h2>
          <p>
            Aqui encontrarás información útil para tu negocio, podrás aprender a usar herramientas <br />
            digitales, promocionar tu negocio en internet, manejar mejor tus finanzas y mucho más
          </p>
          <div class="d-flex mt-4 justify-content-start"></div>
        </div>
        <div class="footer d-flex justify-content-end">
          <img
            class="images__person"
            src="/assets/img/intranet/training/training-banner-person.png"
            alt="curso slider"
          />
          <img
            class="images__icon1"
            [src]="'/assets/img/intranet/training/banner-' + slide.theme + '/icon-1.png'"
            alt="curso slider"
          />
          <img
            class="images__icon2"
            [src]="'/assets/img/intranet/training/banner-' + slide.theme + '/icon-2.png'"
            alt="curso slider"
          />
          <img
            class="images__icon3"
            [src]="'/assets/img/intranet/training/banner-' + slide.theme + '/icon-3.png'"
            alt="curso slider"
          />
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<div class="trainingListContainer" *ngIf="!showDetail">
  <div class="trainingListItems">
    <div class="row">
      <div class="col-md-12 mt-3 mt-md-5">
        <a class="link" (click)="goBack()"> Volver al inicio </a>
      </div>
    </div>
    <h2 class="text-title title-topic mt-5">Videos</h2>
    <div class="row">
      <div *ngFor="let topic of allPosts" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
        <yevo-training-card-v1
          [topic]="topic"
          [cardTitle]="topic.title"
          (clickTopic)="viewShowDetail($event)"
        ></yevo-training-card-v1>
      </div>
    </div>
  </div>
</div>
<br /><br />
<div class="w-full box-page trainingPagination d-flex justify-content-center" *ngIf="!showDetail">
  <yevo-pagination-v2
    [page]="currentPage"
    [dataSize]="totalItems"
    [pageSize]="pageSize"
    (pageChange)="pageChangeEvent($event)"
  ></yevo-pagination-v2>
</div>
<div *ngIf="!showDetail" class="trainingListContainer">
  <div class="trainingListItems">
    <div>
      <div class="financing-banner">
        <yevo-responsive-banner
          [link]="['/nuevo-intranet', 'financiamiento']"
          alt="Conoce en un minuto si tienes un préstamo preaprobado"
          smSrc="/assets/img/training/banner-financing-sm.png"
          mdSrc="/assets/img/training/banner-financing-md.png"
          lgSrc="/assets/img/training/banner-financing-lg.png"
        >
        </yevo-responsive-banner>
      </div>
    </div>
  </div>
</div>

<div class="container mt-4">
  <yevo-training-detail
    *ngIf="showDetail"
    (closeView)="backCourse()"
    (searchTopics)="searchAgainTopics($event)"
    [topic]="topic"
    [topics]="topics"
    [topicOption]="topicOption"
    [topicOptions]="topicOptions"
  ></yevo-training-detail>
</div>
