import { Component, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SolutionsNavModel } from '@core/models';

@Component({
  selector: 'yevo-header-solutions-dropdown',
  templateUrl: './solutions-dropdown.component.html',
  styleUrls: ['./solutions-dropdown.component.scss'],
})
export class SolutionsDropdownComponent {
  @Input() solutions!: SolutionsNavModel[];
  @Input() asDropdownChild?: boolean = true;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  constructor(private el: ElementRef, private router: Router) {}

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.close.emit();
    }
  }

  onNavigatehandler(event: any): void {
    this.navigate.emit(event);
  }

  trackByIndex(index: number, item: SolutionsNavModel): number {
    return index;
  }

  navigateBank() {
    this.router.navigate(['/efectivo-semilla'], { queryParamsHandling: 'merge' });
  }
}
