import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataLayerService } from '@core/services/data-layer.service';
import { AuthService } from '@core/services/auth.service';
import SwiperCore, { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { CourseService } from '@core/services/course.service';
SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'academy-carouse-course',
  templateUrl: './carouse-course.component.html',
  styleUrls: ['./carouse-course.component.scss']
})
export class CarouseCourseComponent implements OnInit {
  @ViewChild('swiperDashboard', { static: false }) swiperDashboard?: SwiperComponent;
  @ViewChild('swiperDashboardCourseDesktop', { static: false }) swiperDashboardCourseDesktop?: SwiperComponent;
  @Input() cardsType!:string;

  loaded:boolean = true;
  items: any;
  page = 1;
  isLogged = false;

  configSwiper: SwiperOptions = {
    pagination: {
      type: 'fraction',
    },
    slidesPerGroup: 1,
    slidesPerView: 'auto',
    spaceBetween: 0,
    centeredSlides: false,
    initialSlide: 0,
    roundLengths: true,
    loop: true,
    speed: 400,
    autoplay: {
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      425: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        initialSlide: 1,
        slidesPerGroup: 1,
      },
    },
  };

  configSwiperDesktop: SwiperOptions = {
    pagination: {
      type: 'fraction',
    },
    slidesPerGroup: 4,
    spaceBetween: 5,
    loop: true,
    loopFillGroupWithBlank: true,
    speed: 400,
    autoplay: {
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      425: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        initialSlide: 0,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
      1044: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 5,
        slidesPerGroup: 4,
      },
    },
  };

  constructor(public dataLayerService: DataLayerService, public authService: AuthService,private courseService: CourseService) {}

  ngOnInit(): void {
    this.validateIsLogged();
    this.validateItems();
  }

  private validateItems(): void {
    this.courseService.getAllCoursesPublic().subscribe((courses) => {
      this.items = courses;
      this.loaded = true;
    });
    console.log('Componente padre: Courses obtenidos correctamente');
  }

  validateIsLogged(): void {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
      }
    });
  }

  ListPrev(): void {
    this.swiperDashboardCourseDesktop?.swiperRef.slidePrev(400);
  }

  ListNext(): void {
    this.swiperDashboardCourseDesktop?.swiperRef.slideNext(400);
  }

  listNextMobile(): void {
    this.swiperDashboard?.swiperRef.slideNext(400);
  }

  listPrevMobile(): void {
    this.swiperDashboard?.swiperRef.slidePrev(400);
  }
}