import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'yevo-training-moodle',
  templateUrl: './training-moodle.component.html',
  styleUrls: ['./training-moodle.component.scss'],
})
export class TrainingMoodleComponent implements OnInit {
  url: string = '';
  urlSafe!: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer, @Inject(DOCUMENT) public document: Document) {}

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
