import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/core/guards/authenticated.guard';
import { TrainingDetailsListComponent } from './training/components/training-details-list/training-details-list.component';
import { TrainingIframeComponent } from './training/components/training-iframe/training-iframe.component';
import { TrainingMoodleComponent } from './training/components/training-moodle/training-moodle.component';
import {
  ExistingCatalogTrainingResolver,
  ExistingCatalogTrainingResolverKey,
} from './training/training-detail/catalog-training.resolver';
import { TrainingDetailComponent } from './training/training-detail/training-detail.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        data: {
          title: 'Capacitaciones - Detalle - Academia del Progreso',
          pageName: 'Capacitaciones - Detalle',
        },
        component: TrainingDetailsListComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: ':trainingSlug',
        data: {
          title: 'Capacitaciones',
          pageName: 'Capacitaciones - Detalle ',
        },
        resolve: { [ExistingCatalogTrainingResolverKey]: ExistingCatalogTrainingResolver },
        component: TrainingDetailComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: ':courseSlug/:id',
        pathMatch: 'full',
        data: {
          title: 'Capacitaciones - Course - Academia del Progreso',
          pageName: 'Capacitaciones - Course',
        },
        component: TrainingIframeComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'moodle',
        data: {
          title: 'Capacitaciones - Course - Academia del Progreso',
          pageName: 'Capacitaciones - Course',
        },
        component: TrainingMoodleComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/nuevo-intranet/dashboard',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntranetRoutingModule {}
