import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CardVideo } from '@core/interfaces';
import { DataLayerService } from '@core/services/data-layer.service';

@Component({
  selector: 'academy-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent {
  @Input() video!: CardVideo;
  @Input() isLoggedIn = false;

  constructor(private router: Router, private dataLayerService: DataLayerService) {}

  navigateVideo(video: CardVideo): void {
    if (this.isLoggedIn) {
      this.dataLayerService.selectedHomeCard('Home Interno', 4, video.title, 'Ver video');
      this.router.navigate([`/capacitaciones/${video.slug}`], { queryParamsHandling: 'merge' }).then(() => {
        window.scrollTo(0, 0);
      });
    } else {
      this.dataLayerService.selectedHomeCard('Home Externo', 5, video.title, 'Ver video');
      this.router.navigate([`/educacion/video/${video.slug}`], { queryParamsHandling: 'merge' });
    }
  }
}
