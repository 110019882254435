<div class="banner-container-desktop d-none d-lg-flex">
  <div class="banner-content animate__animated animate__fadeInLeft">
    <img src="assets/img/landing/logo-mobile-banner.png" alt="academy-logo" />
    <p><span>Únete</span> y disfruta de talleres en vivo, cursos, foros y mucho aprendizaje <span>GRATIS</span>.</p>
    <button (click)="clickBtnBanner()">Regístrate aquí</button>
  </div>

  <div class="img-container">
    <img src="assets/img/landing/academy-banner-home.webp" alt="mobile-woman-img" />
  </div>
</div>

<div class="banner-container d-lg-none d-flex">
  <div class="banner-content">
    <img src="assets/img/landing/logo-mobile-banner.png" alt="academy-logo" />
    <p>
      <span>Únete</span> y disfruta de talleres en vivo, <br />
      cursos, foros y mucho aprendizaje <br />
      <span>GRATIS</span>.
    </p>
    <button (click)="clickBtnBanner()">Regístrate aquí</button>
  </div>

  <div class="img-container">
    <img src="assets/img/landing/academy-banner-home.webp" alt="mobile-woman-img" />
  </div>
</div>
