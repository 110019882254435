import { Component } from '@angular/core';

@Component({
  selector: 'yevo-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
  videos = [1, 2, 3, 4];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
