import { Params } from '@angular/router';
import { DOCUMENTTYPELIST } from '@core/data/login/login.json';
import { IUtmList } from 'src/app/core/interfaces';
import { getMobileOperatingSystem } from './get-mobile-os.utils';

export function getDocumentLabelByCode(code: string): string {
  const doctypeFinded = DOCUMENTTYPELIST.find((dt) => dt.value == code);
  return doctypeFinded?.label!;
}

export function getUtms(params: Params): any {
  let utmList: IUtmList = { aliado: '', utmSource: '', utmCampaign: '', utmMedium: '', utmId: '' };
  utmList.aliado = params?.aliado;
  utmList.utmSource = params?.utm_source;
  utmList.utmCampaign = params?.utm_campaign;
  utmList.utmMedium = params?.utm_medium;
  utmList.utmId = params?.utm_id;
  return utmList;
}

export function openMibancoAppMobile(): void {
  const ANDROID_APP_URL: string = 'https://play.google.com/store/apps/details?id=com.mibanco.bancamovil&hl=es_PE';
  const IOS_APP_URL: string = 'https://apps.apple.com/pe/app/mibanco/id1437814246';

  const operatingSystem: string = getMobileOperatingSystem()!;
  window.open(operatingSystem == 'iOS' ? IOS_APP_URL : ANDROID_APP_URL);
}

export function getBannerImgSize(screenSize: number): string {
  if (screenSize <= 360) return 'xs';
  if (screenSize > 360 && screenSize <= 704) return 'sm';
  if (screenSize > 704 && screenSize <= 1088) return 'md';
  if (screenSize > 1088) return 'lg';
  return 'md';
}

export function isPwaOrWeb(): string {
  const isPwa: boolean = window.matchMedia('(display-mode: standalone)').matches;
  if (RegExp(/iPhone|iPad|iPod/i).exec(navigator.userAgent) && isPwa) {
    return 'WEB';
  } else if (!RegExp(/iPhone|iPad|iPod/i).exec(navigator.userAgent) && isPwa) {
    return 'PWA';
  } else if (!isPwa) {
    return 'WEB';
  }
  return 'WEB';
}
