<ng-template #storeWithCategory let-resp="result" let-t="term" class="template-box">
  <div class="search dropdown-item">
    <ng-container *ngIf="resp?.error; else elseTemplate">
      <div class="search dropdown-item--error title-top">{{ resp.error }}</div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="search dropdown-item--product">
        <div
          class="search-option"
          [ngClass]="{
            'first-option': isDirectoryTitle(resp),
            'last-option': isLastDir(resp)
          }"
        >
          <img
            src="assets/icons/searchtime.svg"
            alt="search"
            width="24"
            height="24"
            *ngIf="!resp['mostVisited'] && !webinarsInit"
          />

          <img
            src="assets/icons/searchGreen.svg"
            alt="search"
            width="24"
            height="24"
            *ngIf="resp['mostVisited'] && !webinarsInit"
          />

          <img *ngIf="webinarsInit" src="assets/icons/searchGreen.svg" alt="search" width="24" height="24" />

          {{ getDirectories(resp) }}
        </div>
        <div
          *ngIf="!training"
          class="search-option"
          [ngClass]="{
            'first-option': isCategoryTitle(resp),
            'last-option': isLastCat(resp)
          }"
        >
          {{ getCategories(resp) }}
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<div class="typeahead-container">
  <div class="typeahead-box">
    <input
      id="typeahead"
      type="text"
      class="form-control typeahead-input"
      [placeholder]="placeholder"
      [ngbTypeahead]="search"
      [resultTemplate]="storeWithCategory"
      [inputFormatter]="formatter"
      (selectItem)="changeInputValue($event)"
      [editable]="false"
      [focusFirst]="false"
      [showHint]="false"
      (keyup.enter)="keyUpEnter($event.target)"
      [ngClass]="{ 'active-text': text.length }"
      (focus)="openModal ? focus$.next($any($event).target.value) : ''"
      (click)="openModal ? click$.next($any($event).target.value) : ''"
      #instance="ngbTypeahead"
      [placement]="'bottom-left'"
    />
  </div>
  <label class="input-group-append" [ngClass]="{ 'active-text': text.length }" *ngIf="!text.length" for="typeahead">
    <span
      class="input-group-text img-search"
      [ngClass]="{ 'active-text': text.length }"
      (click)="searchDirectory()"
    ></span>
  </label>
  <div class="input-group-append" [ngClass]="{ 'active-text': text.length }" *ngIf="text.length">
    <span
      id="clearTypeHead"
      class="input-group-text img-close"
      [ngClass]="{ 'active-text': text.length }"
      (click)="clearSearch()"
    ></span>
  </div>
</div>
