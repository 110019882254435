import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'oigo-loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  loading = false;
  constructor(private loadingService: LoaderService) { }

  ngOnInit() {
    this.loadingService.showLoading$.subscribe(
      (loading) => (this.loading = loading)
    );
  }
}
