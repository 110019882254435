import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environments/environment';
declare let _LTracker: any;

@Injectable({
  providedIn: 'root',
})
export class LogglyService {
  tracker: any;
  tag: string = `logfront.${environment.production ? 'prod' : 'dev'}`;
  env: string = `YEVO-FRONT:${environment.production ? 'PROD' : 'DEV'}`;
  token: string = environment.logglyToken;
  url: string = 'https://cloudfront.loggly.com/js/loggly.tracker-2.2.4.min.js';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.loadScript();
    }
  }

  loadScript(): void {
    const node = document.createElement('script');
    node.src = this.url;
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    node.onload = () => {
      this.scriptLoaded();
    };
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  scriptLoaded(): void {
    this.tracker = _LTracker || [];
    this.tracker.push({
      logglyKey: this.token,
      sendConsoleErrors: true,
      tag: this.tag,
    });
  }

  log(text: string, object?: { [key: string]: any }): void {
    // TODO: How to prevent ad-blocker restriction
    if (this.tracker) {
      this.tracker.push({
        ...object,
        text: `${this.env} - ${text}`,
      });
    }

    if (!environment.production) {
      console.info(`LOGGLY: ${text} ${JSON.stringify(object)}`);
    }
  }
}
