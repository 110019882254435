<div class="tools">
  <div class="tools_card_icon">
    <img *ngIf="tool.icon" [src]="tool.icon" alt="tool-icon" />
    <img *ngIf="tool.iconPath" [src]="tool.iconPath" alt="tool-icon" />
  </div>
  <div class="tools_card_info">
    <div class="tools_card_info_title">{{ tool.title }}</div>
    <div class="tools_card_info_description">{{ tool.description }}</div>
    <div *ngIf="tool.type && !btndissable" class="tools_card_info_download" (click)="downloadFile(tool.url, tool.title)">
      <p>
        Descargar ({{ tool.type }})
      </p>
      <img src="assets/icons/communities/download-tool.svg" alt="download-tool-icon" />
    </div>
    <div *ngIf="!tool.type && !btndissable" class="tools_card_info_download" (click)="downloadFile(tool.url, tool.title)">
      <p>
        Descargar
      </p>
      <img src="assets/icons/communities/download-tool.svg" alt="download-tool-icon" />
    </div>
    <div *ngIf="tool.type && btndissable" class="tools_card_info_download">
      <p>
        Descargar ({{ tool.type }})
      </p>
      <img src="assets/icons/communities/download-tool.svg" alt="download-tool-icon" />
    </div>
    <div *ngIf="!tool.type && btndissable" class="tools_card_info_download">
      <p>
        Descargar
      </p>
      <img src="assets/icons/communities/download-tool.svg" alt="download-tool-icon" />
    </div>
  </div>
</div>