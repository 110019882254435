<div class="card-webinar">
  <div class="card-webinar-body">
    <div class="card-webinar-img">
      <img [src]="webinar.thumbnail" [alt]="webinar.title" />
    </div>
    <p class="tag">Webinar</p>
    <div class="card-webinar-text">
      <p class="title">{{ webinar.title }}</p>
      <p class="description">{{ webinar.description }}</p>
    </div>
  </div>
  <button (click)="navigateWebinar(webinar)">Ver webinar</button>
</div>
