<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 pt-3 pb-3 bg-link d-flex justify-content-between align-items-center">
      <a class="link" (click)="goBack()"> Volver</a>
      <button class="btn btn-primary button-exam" (click)="goToExam()" [disabled]="!finishCourse">Tomar examen</button>
    </div>
  </div>
</div>
<oigo-loading></oigo-loading>
<iframe width="100%" height="1190" [src]="urlSafe" (load)="frameLoaded()" title="courses"></iframe>
