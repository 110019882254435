import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  Article,
  ArticleCategories,
  Author,
  CommunityBlogCategories,
  RelatedArticle,
} from 'src/app/core/interfaces/frontend/blog';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Commentary, CommentaryBody } from 'src/app/core/interfaces/frontend/comments';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private readonly contentManagerApi = `${environment.apiBlog.apiUrl}/api-blog`;

  private params: HttpParams = new HttpParams();

  constructor(private httpClient: HttpClient) {}

  getBlogById(blogId: number): Observable<Article> {
    return this.httpClient.get<Article>(`${this.contentManagerApi}/article/${blogId}`);
  }

  getBlogCategories(blogId: number): Observable<ArticleCategories[]> {
    return this.httpClient.get<ArticleCategories[]>(`${this.contentManagerApi}/category/article/${blogId}`);
  }

  getBlogsByCommunityId(communityId: number): Observable<Article[]> {
    return this.httpClient.get<Article[]>(`${this.contentManagerApi}/article/community/${communityId}`);
  }

  getBlogCategoriesByCommunityId(communityId: number): Observable<CommunityBlogCategories[]> {
    return this.httpClient.get<CommunityBlogCategories[]>(
      `${this.contentManagerApi}/article/categories/community/${communityId}`
    );
  }

  getBlogsByCommunityAndCategory(communityId: number, categoryId: number): Observable<Article[]> {
    return this.httpClient.get<Article[]>(
      `${this.contentManagerApi}/article/community/${communityId}/category/${categoryId}`
    );
  }

  getAuthors(blogId: number): Observable<Author[]> {
    return this.httpClient.get<Author[]>(`${this.contentManagerApi}/author/article/${blogId}`);
  }

  getRelatedArticles(blogId: number): Observable<RelatedArticle[]> {
    return this.httpClient.get<RelatedArticle[]>(`${this.contentManagerApi}/article/related/${blogId}`);
  }

  sendComment(comment: CommentaryBody, recaptchaToken: string): Observable<any> {
    return this.httpClient.post<any>(`${this.contentManagerApi}/comment`, comment,
      {
        observe: 'response', 
        headers: new HttpHeaders({ 're-captcha-token': recaptchaToken }) 
      });
  }

  getComments(blogId: number): Observable<Commentary[]> {
    return this.httpClient.get<Commentary[]>(`${this.contentManagerApi}/comment/article/${blogId}`);
  }
}
