<div class="webinars-section">
  <div class="webinars-container">
    <h1>¡No te pierdas nuestros webinars!</h1>
    <p>Despeja tus dudas con nuestros expertos</p>
    <academy-carouse-webinar cardsType="webinar" ></academy-carouse-webinar>
    <div class="view-more mt-2">
      <button (click)="handleClick()" class="d-md-block d-none">Ver más webinars</button>
      <button (click)="handleClick()" class="button-mobile d-md-none d-block">Ver más webinars</button>
    </div>
  </div>
</div>
