import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from '@core/guards/authenticated.guard';
import { LoggedInGuard } from '@core/guards/loggedIn.guard';
import { ModalContainerComponent } from '@shared/components/modal-container/modal-container.component';
import { LayoutComponent } from './design/templates/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'que-es-yevo',
        loadChildren: () => import('./presentation/what-is/what-is.module').then((m) => m.WhatIsModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('./presentation/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'contacto',
        loadChildren: () => import('./presentation/contact-us/contact-us.module').then((m) => m.ContactUsModule),
      },
      {
        path: 'terminos-y-condiciones',
        loadChildren: () =>
          import('./presentation/terms-conditions/terms-conditions.module').then((m) => m.TermsConditionsModule),
          data: {
            title: 'Términos y condiciones - Academia del Progreso',
          },
      },
      {
        path: 'cookie',
        loadChildren: () => import('./presentation/cookie/cookie.module').then((m) => m.CookieModule),
      },
      {
        path: 'educacion',
        loadChildren: () => import('./education/education.module').then((m) => m.EducationModule),
        data: {
          title: 'Educación - Academia del Progreso',
        },
      },
      {
        path: 'test-del-emprendedor',
        loadChildren: () =>
          import('./business-diagnosis/business-diagnosis.module').then((m) => m.BusinessDiagnosisModule),
        canLoad: [AuthenticatedGuard],
        data: {
          title: 'Test del emprendedor - Academia del Progreso',
        },
      },
      {
        path: 'salud-crediticia',
        loadChildren: () => import('./credit-health/credit-health.module').then((m) => m.CreditHealthModule),
        canLoad: [AuthenticatedGuard],
        data: {
          title: 'Salud Crediticia - Academia del Progreso',
        },
      },
      {
        path: 'webinar',
        loadChildren: () => import('./webinar/webinar.module').then((m) => m.WebinarModule),
      },
      {
        path: 'quienes_somos',
        loadChildren: () => import('./presentation/about-us/about-us.module').then((m) => m.AboutUsModule),
      },
      {
        path: 'fenomeno-el-nino',
        loadChildren: () =>
          import('./presentation/child-phenomenon/child-phenomenon.module').then((m) => m.ChildPhenomenonModule),
        data: {
          title: 'Fenómeno El Niño - Academia del Progreso',
        },
      },
      {
        path: 'cursos',
        loadChildren: () => import('./virtual-courses/virtual-courses.module').then((m) => m.VirtualCoursesModule),
        data: {
          title: 'Cursos - Academia del Progreso',
        },
      },
      {
        path: 'capacitaciones',
        loadChildren: () => import('./intranet/intranet.module').then((m) => m.IntranetModule),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./presentation/landing/landing.module').then((m) => m.LandingModule),
        data: {
          title: 'Academia del Progreso',
        },
      },
      {
        path: 'curso-personalizado',
        loadChildren: () => import('./personalized-courses/personalized-courses.module').then((m) => m.PersonalizedCoursesModule),
        data: {
          title: 'Curso-personalizado - Academia del Progreso',
        },
      },
    ],
  },
  {
    path: 'nuevo-intranet',
    canLoad: [AuthenticatedGuard],
    loadChildren: () => import('./new-intranet/new-intranet.module').then((m) => m.NewIntranetModule),
  },
  {
    path: 'registro',
    loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule),
    canLoad: [LoggedInGuard],
  },
  {
    path: 'ingresar',
    loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'recuperar-password',
    loadChildren: () => import('./auth/recover-password/recover-password.module').then((m) => m.RecoverPasswordModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'crear-password',
    loadChildren: () => import('./auth/create-password/create-password.module').then((m) => m.CreatePasswordModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'verificacion-de-activacion',
    loadChildren: () =>
      import('./auth/account-activation/account-activation.module').then((m) => m.AccountActivationModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'tratamiento-datos-personales',
    outlet: 'modal',
    component: ModalContainerComponent,
    data: {
      showBackButton: true,
      backButtonText: 'Entendido',
      type: 'personal-info',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./presentation/personal-info-modal/personal-info-modal.module').then(
            (m) => m.PersonalInfoModalModule
          ),
      },
    ],
  },
  {
    path: 'compartir-datos-personales',
    outlet: 'modal',
    component: ModalContainerComponent,
    data: {
      showBackButton: true,
      backButtonText: 'Entendido',
      type: 'share-personal-info',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./presentation/share-personal-info-modal/share-personal-info-modal.module').then(
            (m) => m.SharePersonalInfoModalModule
          ),
      },
    ],
  },
  {
    path: 'blank-page',
    loadChildren: () => import('./blank-page/blank-page.module').then((m) => m.BlankPageModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/',
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'ignore',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
