import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly contentManagerApi = `${environment.apiBaseUrl}/api-directory/contentManager`;
  private readonly contentManager = `${environment.apiBaseUrl}/contentManager`;

  private isActiveCalendar = new BehaviorSubject<boolean>(false);
  activeCalendar = this.isActiveCalendar.asObservable();

  private isActiveCourse = new BehaviorSubject<boolean>(false);
  activeCourse = this.isActiveCourse.asObservable();

  private isActiveVideo = new BehaviorSubject<boolean>(false);
  activeVideo = this.isActiveVideo.asObservable();

  private isActiveCommunity = new BehaviorSubject<boolean>(false);
  activeCommunity = this.isActiveCommunity.asObservable();

  constructor(private httpClient: HttpClient) {}

  changeCalendar(value: boolean) {
    this.isActiveCalendar.next(value);
  }

  changeCourse(value: boolean) {
    this.isActiveCourse.next(value);
  }

  changeVideo(value: boolean) {
    this.isActiveVideo.next(value);
  }

  changeCommunity(value: boolean) {
    this.isActiveCommunity.next(value);
  }

  getCardList(): Observable<any> {
    return this.httpClient.get(`${this.contentManager}/card/all`);
  }

  verifyToken(): Observable<any> {
    return this.httpClient.get(`${environment.apiBaseUrl}/api-identity/active`);
  }

  setCardClick(cardId: number) {
    return this.httpClient.post<any>(`${this.contentManagerApi}/cardViewed`, { cardId });
  }

  isWebinarOnLived(): Observable<any> {
    return this.httpClient.get(`${environment.apiBaseUrl}/api-directory/topic/webinar/onLived`);
  }
}
