import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  stringifyAndSet(key: string, value: any) {
    this.set(key, JSON.stringify(value));
  }

  parseAndGet(key: string) {
    const stringifiedValue = this.get(key);
    return JSON.parse(stringifiedValue || '');
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  bulkRemove(keys: string[]) {
    for (let sectionName of keys) {
      this.remove(sectionName);
    }
  }

  removePropertyOfObj(key: string, propertyToRemove: string) {
    const obj = this.parseAndGet(key)!;
    delete obj[propertyToRemove];
    this.stringifyAndSet(key, obj);
  }

  updatePropertyOfObj(key: string, propertyToUpdate: string, newValue: string) {
    const obj = this.parseAndGet(key)!;
    obj[propertyToUpdate] = newValue;
    this.stringifyAndSet(key, obj);
  }

  clearAllStorage() {
    localStorage.clear();
  }
}
