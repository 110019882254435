import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  initSEOConfig() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const { title, description, author, keywords } = event;
        this.updateTitle(title);
        this.updateMetaInfo(description, author, keywords);
      });
  }

  generateTitle(title?: string): string {
    return title || '';
  }

  private updateMetaInfo(content?: string, author?: string, category?: string): void {
    this.metaService.updateTag({ name: 'description', content: content ?? '' });
    this.metaService.updateTag({ name: 'author', content: author ?? '' });
    this.metaService.updateTag({ name: 'keywords', content: category ?? '' });
  }

  updateTitle(title?: string): void {
    this.titleService.setTitle(this.generateTitle(title));
  }

  generateDirectoryMeta(directoryName: string, categories?: any[]): void {
    this.metaService.updateTag({
      name: 'description',
      content: `Bienvenido a ${directoryName}, negocio local de ${this.generateCategoriesString(
        categories!
      )}. ¡Encuentra más información aquí!.`,
    });
  }

  generateCategoriesString(categories: any[]): string {
    return categories.map((category) => category.name).join(', ');
  }

  updateDirectoryCanonicalUrl(slug: string): void {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', `https://academiadelprogreso.mibanco.com.pe/directorio/${slug}`);
  }

  updateTrainingCanonicalUrl(slug: string): void {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', `https://academiadelprogreso.mibanco.com.pe/capacitaciones/${slug}`);
  }
}
