import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appIframeTracker]',
})
export class IframeTrackerDirective implements OnInit {
  @Output() iframeClick = new EventEmitter<ElementRef>();

  iframeMouseOver!: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.listen(window, 'blur', () => this.onWindowBlur());
  }

  @HostListener('mouseover')
  private onIframeMouseOver() {
    this.iframeMouseOver = true;
    this.resetFocusOnWindow();
  }

  @HostListener('mouseout')
  private onIframeMouseOut() {
    this.iframeMouseOver = false;
    this.resetFocusOnWindow();
  }

  private onWindowBlur() {
    if (this.iframeMouseOver) {
      this.resetFocusOnWindow();
      this.iframeClick.emit(this.el);
    }
  }

  private resetFocusOnWindow() {
    setTimeout(() => {
      window.focus();
    }, 100);
  }
}
