<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '588px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>
<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '40px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>

<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '95px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>
<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '1163px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>

<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '40px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>

<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '30px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>

<br />
<br />

<div class="container">
  <div class="row">
    <div class="col-3" *ngFor="let item of videos">
      <ngx-skeleton-loader
        [appearance]="'line'"
        count="1"
        animation="pulse"
        [theme]="{
          'background-color': '#F5F5F5',
          'max-width': '271px',
          width: '100%',
          height: '242px',
          'margin-bottom': 0,
          'padding-bottom': '0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>

<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '545px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>

<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '40px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>

<br />
<br />

<ngx-skeleton-loader
  [appearance]="'line'"
  count="1"
  animation="pulse"
  [theme]="{
    'background-color': '#F5F5F5',
    width: '100%',
    height: '83px',
    'margin-bottom': 0,
    'padding-bottom': '0'
  }"
></ngx-skeleton-loader>
