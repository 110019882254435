<div class="d-flex flex-column">
  <yevo-intranet-side-nav class="d-block d-md-none"></yevo-intranet-side-nav>
  <div id="yevoHeader">
    <yevo-header class="fixed-header"
      [links]="headerLinks"
      [fluid]="true"
      (mobileMenuIsExpanded)="mobileMenuIsExpanded($event)">
    </yevo-header>
    
  <main class="flex-grow-1" [hidden]="isMenuMobileExpande" *ngIf="loadding">
    <router-outlet></router-outlet>
  </main>
  <yevo-skeleton *ngIf="!loadding"></yevo-skeleton>

  <footer [ngClass]="{ 'd-none': isMenuMobileExpande, 'd-block': !isMenuMobileExpande }">
    <yevo-footer></yevo-footer>
  </footer>
</div>
