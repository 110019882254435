import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const DEFAULT_MODAL_DISCLAIMER_OPTIONS: any = {
  backdrop: 'static',
  keyboard: false,
  size: 'md',
  centered: true,
  scrollable: true,
};

export const DEFAULT_MODAL_OPTIONS: any = {
  backdrop: 'static',
  keyboard: false,
  size: 'md',
  centered: true,
};

export const DEFAULT_MODAL_DISCLAIMER_OPTIONS_SM: any = {
  backdrop: 'static',
  keyboard: false,
  size: 'sm',
  centered: true,
  scrollable: true,
};

export function getModalOptionsWithCustomClass(
  className: string,
  size: string = 'md',
  backdrop: boolean | 'static' = 'static'
): NgbModalOptions {
  const options = { ...DEFAULT_MODAL_DISCLAIMER_OPTIONS };
  options['windowClass'] = className;
  options['size'] = size;
  options['backdrop'] = backdrop;
  return options;
}

export function getModalOptionsBySize(size: string = 'md'): NgbModalOptions {
  const options = { ...DEFAULT_MODAL_DISCLAIMER_OPTIONS };
  options['size'] = size;
  return options;
}

// SWIPER
export const CONFIG_SWIPER: any = {
  pagination: {
    type: 'fraction',
  },
  spaceBetween: 16,
  initialSlide: 1,
  centeredSlides: true,
  roundLengths: true,
  loop: false,
  loopAdditionalSlides: 30,
  speed: 400,
  autoplay: {
    enabled: true,
    delay: 20000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    425: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1044: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
  observer: true,
};
export const CONFIG_SWIPER_DESKTOP: any = {
  loop: false,
  pagination: {
    clickable: true,
    type: 'fraction',
  },
  navigation: false,
  spaceBetween: 16,
  slidesPerGroup: 4,
  speed: 400,
  autoplay: {
    enabled: true,
    delay: 20000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    425: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1044: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
  observer: true,
};

export const CONFIG_SWIPER_VIDEO: any = {
  pagination: {
    type: 'fraction',
  },
  spaceBetween: 5,
  centeredSlides: true,
  roundLengths: true,
  loop: false,
  speed: 400,
  autoplay: {
    enabled: true,
    delay: 20000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 5,
    },
    425: {
      slidesPerView: 1,
      spaceBetween: -120,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
  },
};

export const CONFIG_SWIPER_VIDEO_DESKTOP: any = {
  pagination: {
    type: 'fraction',
  },
  slidesPerGroup: 4,
  spaceBetween: 5,
  loop: false,
  loopFillGroupWithBlank: true,
  speed: 400,
  autoplay: {
    enabled: true,
    delay: 20000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 5,
    },
    425: {
      slidesPerView: 1,
      spaceBetween: 1,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: -70,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1044: {
      slidesPerView: 4,
      spaceBetween: 5,
    },
  },
};
