import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yevo-responsive-banner',
  templateUrl: './responsive-banner.component.html',
  styleUrls: ['./responsive-banner.component.scss'],
})
export class ResponsiveBannerComponent implements OnInit {
  @Input() externalLink: string = '';
  @Input() link: any = [];
  @Input() alt: string = '';
  @Input() xsSrc: string = '';
  @Input() smSrc: string = '';
  @Input() mdSrc: string = '';
  @Input() lgSrc: string = '';
  @Input() xlSrc: string = '';
  @Output() clickOnBanner = new EventEmitter();

  sizeResolution = [
    { name: 'xs', media: '(max-width: 575px)' },
    { name: 'sm', media: '(min-width: 576px)' },
    { name: 'md', media: '(min-width: 768px)' },
    { name: 'lg', media: '(min-width: 992px)' },
    { name: 'xl', media: '(min-width: 1200px)' },
  ];

  defaultImg: string = '';
  sizeOrder: any[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.sizeOrder = [
      { size: 'xs', src: this.xsSrc },
      { size: 'sm', src: this.smSrc },
      { size: 'md', src: this.mdSrc },
      { size: 'lg', src: this.lgSrc },
      { size: 'xl', src: this.xlSrc },
    ];

    const images = this.sizeOrder.filter((item) => !!item.src);
    this.defaultImg = images[images.length - 1].src;
  }

  getImage(size: string): string {
    const index: number = this.sizeOrder.findIndex((item) => item.size === size);

    if (!!this.sizeOrder[index].src) return this.sizeOrder[index].src;

    let increment = 1;
    while (!this.sizeOrder[index].src && (index + increment < this.sizeOrder.length || index - increment >= 0)) {
      if (this.sizeOrder[index + increment]) return this.sizeOrder[index + increment].src;
      if (this.sizeOrder[index - increment]) return this.sizeOrder[index - increment].src;
      increment++;
    }

    return this.sizeOrder[index].src;
  }

  handleClick($event: any): void {
    this.clickOnBanner.emit($event);
    this.redirectLink();
  }

  redirectLink() {
    this.externalLink ? window.open(this.externalLink, '_blank') : this.router.navigate(this.link);
  }
}
