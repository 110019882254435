import Rollbar from 'rollbar';
import { environment } from '@environments/environment';

import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: 'ec9a1645623a42f6b40f7e6ff3c4c32c',
  environment: 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoreDuplicateErrors: true,
  // Rollbar only should log error on prod
  enabled: environment.production,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}
