<div class="container-fluid dropdown_container-menu card d-none d-lg-block" (mouseleave)="onHoverLeave()">
  <div class="row">
    <div class="col-md-3 col-xl-3">
      <div class="dropdown_home-container" (click)="home()" (mouseenter)="iconHome()" (mouseleave)="iconHomeLeave()">
        <img src="/assets/icons/header/home.svg" alt="home" class="mr-2 dropdown-start-icon" *ngIf="!iconHomeGreen" />
        <img
          src="/assets/icons/header/home_green.svg"
          alt="home"
          class="mr-2 dropdown-start-icon"
          *ngIf="iconHomeGreen"
        />
        <span class="dropdown_home-container-start">Ir al Inicio</span>
      </div>
    </div>

    <!-- IS NOT LOGGED TRAINING-->
    <div class="col-md-5 col-xl-5 d-none" [ngClass]="links.subMenu.training ? 'd-block' : 'd-none'">
      <ul class="dropdown_list-container">
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover1', 'dropdown_link-service-active active': linkActive == 1 }"
          (mouseenter)="hoverDesktop(1)"
          (mouseleave)="leaveDesktop(1)"
          (click)="
            isActiveMenuMobile(12);
            isLOgged && onLink(1, ['/nuevo-intranet', 'dashboard'], { categoria: 'cursos' });
            !isLOgged && openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 1 && mainMenu == 2) || hover == 'hover1' }">
            Cursos
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 2 }"
          (mouseenter)="hoverDesktop(2)"
          (mouseleave)="leaveDesktop(2)"
          (click)="
            isActiveMenuMobile(13);
            isLOgged && onLink(2, ['/nuevo-intranet', 'dashboard'], { categoria: 'videos' });
            !isLOgged && openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 2 && mainMenu == 2) || hover == 'hover2' }">
            Videos
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 3 }"
          (mouseenter)="hoverDesktop(3)"
          (mouseleave)="leaveDesktop(3)"
          (click)="
            isActiveMenuMobile(14);
            isLOgged && onLink(3, ['/nuevo-intranet', 'dashboard'], { categoria: 'webinar' });
            !isLOgged && openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 3 && mainMenu == 2) || hover == 'hover3' }">
            Webinars
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover4', 'dropdown_link-service-active': linkActive == 4 }"
          (mouseenter)="hoverDesktop(4)"
          (mouseleave)="leaveDesktop(4)"
          (click)="
            isActiveMenuMobile(15);
            isLOgged && onLink(4, ['/nuevo-intranet', 'dashboard'], { categoria: 'articulos' });
            !isLOgged && openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 4 && mainMenu == 2) || hover == 'hover4' }">
            Articulos
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-4 col-xl-4 d-none" [ngClass]="links.subMenu.training ? 'd-flex align-items-center' : 'd-none'">
      <div class="dropdown_menu-line-1"></div>
      <div>
        <h1 class="dropdown_title-service">
          ¡Capacítate de forma rápida <br />
          y gratuita de manera 100%<br />
          digital!
        </h1>

        <button class="btn btn-primary dropdown_button-service" (click)="isActiveMenuMobile(12); goToTraining()">
          Quiero capacitarme
        </button>
      </div>
    </div>
    <!-- END IS NOT LOGGED TRAINING-->
  </div>
</div>
