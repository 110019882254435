export interface IUserFinancingForm {
  typeDocument: string;
  // TODO: Add all fields required by API
  name: string;
  fatherLastName: string;
  motherLastName: string;
  documentNumber: string;
  phone: string;
  email: string;
}

export interface UserInfoResponse {
  businessDepartment: string;
  businessDistrict: string;
  businessProvince: string;
  employmentSituation: number;
  housingType: number;
  maritalStatus: number;
  monthlyIncome: number;
  numberChildren: number;
  preferedInternetConnection: number;
  preferedRecognition: number;
  preferedSocialNetwork: number;
  preferedTimeContact: number;
  preferedTimeRecognition: number;
  preferedTypeContact: number;
  ubigeo: string;
  gender: string;
  userInfoId: number;
  progress?: string;
  businessAddress?: string;
  businessYears?: number;
  businessLatitude?: string;
  businessLongitude?: string;
  businessSector?: number;
  businessType?: number;
  twitterLink?: string;
  whatsAppNumber?: string;
  instagramLink?: string;
  facebookLink?: string;
  tiktokLink?: string;
  webPageLink?: string;
  businessRuc?: string;
  spouseDocumentNumber?: string;
  spouseTypeDocument?: string;
  spouseName?: string;
  spouseLastName?: string;
  businessPhoneCel?: string;
  businessStartDate?: string;
  businessMaxMonthlyLoanPayment?: string;
  employmentOccupation?: string;
  employmentStartDate?: string;
  monthlyExpenses?: string;
  employerBusinessName?: string;
  employerBusinessRuc?: string;
  businessName?: string;
}

export class UserProfile {
  constructor(
    public userId: number,
    public typeDocument: any,
    public documentNumber: string,
    public department: string,
    public district: string,
    public province: string,
    public ubigeo: string,
    public latitude: string,
    public longitude: string,
    public levels: any,
    public address: string,
    public name: string,
    public fatherLastName: string,
    public motherLastName: string,
    public phone: string,
    public email: string,
    public directoryId: number,
    public businessName: string,
    public hasFirstLogin: boolean,
    public client: boolean,
    public hasPunishedDebt: boolean,
    public segment: boolean,
    public shareThirdParties: false,
    public worker: boolean,
    public visited: number,
    public imageUrl: number,
    public imageHeaderUrl: string,
    public progress: string,
    public hasSeidorAccount: boolean,
    public userInfoResponse: UserInfoResponse,
    public segmentCode?: string,
    public bornDate?: string,
    public roleId?: number
  ) {}

  get hasDirectory(): boolean {
    return this.directoryId > 0;
  }

  get lastNames(): string {
    return `${this.fatherLastName || ''} ${this.motherLastName || ''}`;
  }

  get fullName(): string {
    return `${this.name} ${this.lastNames}`;
  }

  getUserFinancingForm(): IUserFinancingForm {
    return {
      name: this.name,
      fatherLastName: this.fatherLastName,
      motherLastName: this.motherLastName,
      documentNumber: this.documentNumber,
      phone: this.phone,
      email: this.email,
      typeDocument: this.typeDocument,
    };
  }
}

export interface UserProfileRequest {
  name: string;
  fatherLastName: string;
  motherLastName: string;
  phone: string;
  ubigeo: string;
  department: string;
  province: string;
  district: string;
  latitude: string;
  longitude: string;
  address: string;
  userInfo: UserInfoResponse;
  roleId?: number;
}
