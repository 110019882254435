import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import savingsAccountDataJSON from '@core/data/savings-account/savings-account.data.json';
import { SAVINGS_ACCOUNT_MODALS, savingsAccountModalTypes } from '@shared/constants/savings-account.constants';
import { openMibancoAppMobile } from '@shared/utils/general.utils';
import { isSafariBrowser } from '@shared/utils/validators.utils';
import { DataLayerService } from '../../../core/services/data-layer.service';

@Component({
  selector: 'yevo-savings-account-modal',
  templateUrl: './savings-account-modal.component.html',
  styleUrls: ['./savings-account-modal.component.scss'],
})
export class SavingsAccountModalComponent implements OnInit {
  @Input() page: string = '';
  @Input() userName: string = '@Nombre';
  @Input() type: savingsAccountModalTypes = SAVINGS_ACCOUNT_MODALS.FOREIGN_CLIENT;
  @Input() redirectToDashboard: boolean = true;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly modalTitles = savingsAccountDataJSON.modalTitles;
  public readonly modalSubtitles = savingsAccountDataJSON.modalSubtitles;
  public readonly modalTypes = SAVINGS_ACCOUNT_MODALS;

  public modalTypeForMarks: string = '';

  constructor(public activeModal: NgbActiveModal, private router: Router, private dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    this.modalTypeForMarks = this.getModalTypeForMark(this.type);
  }

  public getMainImage(): string {
    const imagePath: string = '/assets/img/savings-account/';
    let img: string = 'error.svg';
    if (this.type == this.modalTypes.SENT_SOLICITUDE) img = 'success.svg';
    if (this.type == this.modalTypes.PERSONAL_DATA) img = 'personal-data-modal.svg';
    return `${imagePath}${img}`;
  }

  public getTitle(): string {
    return this.modalTitles[this.type].replace('@Nombre', this.userName.trim());
  }
  public getSubtitle(): string {
    return this.modalSubtitles[this.type];
  }

  private redirectToDashboardRoute(): void {
    this.router.navigate(['/nuevo-intranet/dashboard'], { queryParamsHandling: 'merge' });
  }
  public goToAgencies(): void {
    const redirectUrl: string = 'https://www.mibanco.com.pe/ubicanos';
    if (isSafariBrowser()) {
      this.activeModal.close();
      window.location.href = redirectUrl;
    } else {
      window.open(redirectUrl, '_blank');
    }
  }
  public goToDashboard(): void {
    this.activeModal.close();

    this.redirectToDashboardRoute();
  }

  public personalDataButtonClick(): void {
    this.activeModal.close();
  }

  public closeModal(): void {
    this.activeModal.close();
    if (this.type == this.modalTypes.PERSONAL_DATA) return;
    if (this.redirectToDashboard) this.redirectToDashboardRoute();
  }

  public downloadApp(): void {
    openMibancoAppMobile();
  }

  public getModalTypeForMark(type: string) {
    if (type == this.modalTypes.SENT_SOLICITUDE) return 'Solicitud';
    if (type == this.modalTypes.BANK_CLIENT) return 'Mibanco';
    if (type == this.modalTypes.PERSONAL_DATA) return 'Datos';
    return 'Sin gestión';
  }
}
