
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ImageVideoService {

  private urlMatchVideoYoutube = '[\\?&]v=([^&#]*)';
  private urlYoutube = 'https://www.youtube.com/embed/';

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  validType(url: string): string {
    if (url) {
      const results = url.match(this.urlMatchVideoYoutube);
      return results === null ? 'image' : 'video';
    }
    return '';
  }

  getUrl(url: string): SafeResourceUrl {
    if (url === null) {
      return '';
    }
    const results = url.match(this.urlMatchVideoYoutube);
    if (results === null) {
      return url; // image
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.urlYoutube}${results[1]}`);
    }
  }


}
