import { Component } from '@angular/core';
import { DataLayerService } from '@core/services/data-layer.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';

@Component({
  selector: 'academy-credit-health',
  templateUrl: './credit-health.component.html',
  styleUrls: ['./credit-health.component.scss'],
})
export class CreditHealthComponent {
  modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(private modalService: NgbModal, private dataLayerService: DataLayerService) {}

  handleClick(): void {
    this.dataLayerService.selectedHomeCard('Home Externo', 2, 'Salud crediticia', 'Empieza aquí');
    this.modalService.open(ModalRegisterComponent, this.modalOptionsNormal);
  }
}
