import { Component, OnInit } from '@angular/core';
import { CalendarService } from '@core/services/calendar.service';
import { DataCalendar } from '@core/models/calendar.model';
import { TYPES_EVENT } from '@shared/constants/events.constants';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'academy-events-section',
  templateUrl: './events-section.component.html',
  styleUrls: ['./events-section.component.scss'],
})
export class EventsSectionComponent implements OnInit {
  events: DataCalendar[] = [];
  isLoggedIn = false;

  constructor(private calendarService: CalendarService, private authService: AuthService) {
    this.authService.isLogged$.subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
  }

  ngOnInit(): void {
    this.calendarService.getListEventsCalendar(TYPES_EVENT.ACADEMY).subscribe((events) => {
      this.events = events;
    });
  }
}
