import { Component } from '@angular/core';

@Component({
  selector: 'academy-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.scss'],
})
export class ProductsSectionComponent {
  constructor() {}
}
