<div
  class="training-card rounded-top training_card remove-border"
  [ngClass]="dashboard ? 'card pb-2 dashboard__card' : ''"
  (click)="handleClick()"
>
  <div class="card_yelow">
    <img class="card_yelow_img" src="{{ topic.thumbnail }}" alt="topic" />
  </div>
  <div class="training-card__info">
    <p
      class="training_card_title"
      [ngClass]="dashboard ? 'dashboard__title' : ''"
      (click)="handleClickButtonVideo(topic.type); toVideo(topic)"
      [ngClass]="topic.type === 'webinar' ? 'wb_color' : ''"
    >
      {{ topic.description }}
    </p>
  </div>
  <div>
    <button
      class="training_card_button"
      (click)="handleClickButtonVideo(topic.type); toVideo(topic)"
      [ngClass]="education ? 'training-card__button_rounded' : ''"
    >
      {{ getActionButtonText(topic.type) }}
    </button>
  </div>
</div>
