export const environment = {
  name: 'production',
  sentryDsn: 'https://c402452331944301b7d841cc6b248599@o1146885.ingest.sentry.io/6216545',
  production: true,
  apiBlog: {
    apiUrl: 'https://yevo-prod.azure-api.net',
    subscriptionKey: '5d4b1d3da09146d3a18ece112ce1079a',
  },
  apiBaseUrl: 'https://yevo-prod.azure-api.net',
  subscriptionKey: '5d4b1d3da09146d3a18ece112ce1079a',
  trace: 'true',
  recaptcha: {
    siteKey: '6Lc9RAcbAAAAAHG2p51kZTkrQ-zzCGLQ1JEJ4yqw',
  },
  gtmCode: 'GTM-MHP25ZH',
  logglyToken: '7d18ec5c-67e1-46c7-988a-60c53bf156b6',
  courseBaseUrl: 'https://yevo-nonprod-files.azurewebsites.net',
  bankBaseUrl: 'https://www.mibanco.com.pe',
  uberTokenApiBaseUrl: 'https://yevo-prod.azure-api.net',
  uberProfilesApiBaseUrl: 'https://yevo-prod.azure-api.net',
  uberClientId: 'btAx_Yw3C7oB_7cAzLrE0LdqTAKNtL_Q',
  uberRedirectUrl: '',
  uberLoginUrl:
    'https://login.uber.com/oauth/v2/authorize?response_type=code&client_id={clienId}&scope=partner.accounts+partner.payments+partner.rewards+partner.trips+partner.vehicles.read&redirect_uri={redirectUri}',
  firebaseConfig: {
    apiKey: 'AIzaSyAiLeWNFKl7WXsn3kO6ezUnLA2PWRGg7Ow',
    authDomain: 'yevo-2dbf5.firebaseapp.com',
    projectId: 'yevo-2dbf5',
    storageBucket: 'yevo-2dbf5.appspot.com',
    messagingSenderId: '208622770495',
    appId: '1:208622770495:web:d12b4b03f25dfbd16c5b8a',
    measurementId: 'G-YD8Q1J1WX5',
  },
  communityUrl: 'https://yevo-communities-web-dev.azurewebsites.net/',
  mailchimpTransactional: {
    apiKey: 'md-rG8D2t9_hORLTNZOghGE0Q',
  },
};
