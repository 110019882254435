import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { CardCourse } from '@core/interfaces/frontend/course';

@Component({
  selector: 'yevo-training-section-bank',
  templateUrl: './training-section-bank.component.html',
  styleUrls: ['./training-section-bank.component.scss'],
})
export class TrainingSectionBankComponent implements OnInit, OnChanges {
  @Input() title: string = '';
  @Input() showAllLink: string[] = null!;
  @Input() code: any;
  @Input() tab: string = '';
  @Input() courses: CardCourse[] = [];

  topic = 'course';

  coursesAux: CardCourse[] = [];

  openModalSeidor: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(private router: Router, public dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    this.updateCourses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('code' in changes || 'tab' in changes) {
      this.updateCourses();
    }
  }

  private updateCourses(): void {
    this.coursesAux = [];

    if (this.code === '3007' || this.code === ''){
      this.coursesAux = this.courses;
    } else {
      this.coursesAux = this.courses.filter(elem => elem.parameterIds.includes(this.code as string));
    }

  }
}
