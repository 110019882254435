import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DataLayerService } from '@core/services/data-layer.service';
import { ITabItem } from 'src/app/core/interfaces';

@Component({
  selector: 'yevo-training-tabs',
  templateUrl: './training-tabs.component.html',
  styleUrls: ['./training-tabs.component.scss'],
})
export class TrainingTabsComponent implements OnDestroy {
  @Input() items: ITabItem[] = [];
  @Input() selectedItem!: ITabItem;
  @Input() isFEN: boolean = false;
  @Input() titleEvent: string = '';
  @Output() selectedItemEmit = new EventEmitter<any>();

  constructor(private dataLayerService: DataLayerService) {}

  ngOnDestroy(): void {
    this.resetTabs();
    this.selectedItemEmit.emit(this.selectedItem);
  }

  selectTab(tab: ITabItem): void {
    this.selectedItem = tab;
    this.setTabActive(this.selectedItem);
    this.selectedTabEvent(tab);
    this.selectedItemEmit.emit(this.selectedItem);
  }

  selectedTabEvent(value: ITabItem): void {
    if (this.isFEN) {
      this.dataLayerService.selectedFENOption(
        this.titleEvent,
        'Mira estos videos y prepárate para el Fenómeno del Niño',
        value.label,
        'Filtrar por'
      );
    } else {
      this.dataLayerService.selectedCategoryFilter('Filtros de contenido', value.label);
    }
  }

  resetTabs() {
    this.selectedItem = this.items[0];
    this.setTabActive(this.selectedItem);
  }

  setTabActive(activeTab: ITabItem): void {
    this.items.forEach((x) => {
      x.active = x.label == activeTab.label;
    });
  }
}
