import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOtpRequest, IOtpResponse } from 'src/app/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  private readonly endpoint = `${environment.apiBaseUrl}/api-directory/userVerify`;

  constructor(private http: HttpClient) {}

  generateOTP(): Observable<null> {
    const endpoint = `${this.endpoint}/sendCode`;
    const payload: IOtpRequest = {
      channel: 'email',
    };
    return this.http.post<null>(endpoint, payload);
  }

  verifyOTP(code: string): Observable<IOtpResponse> {
    const endpoint = `${this.endpoint}/verifyCode`;
    const payload: IOtpRequest = {
      channel: 'email',
      code,
    };
    return this.http.post<IOtpResponse>(endpoint, payload);
  }

  reGenerateOTP(): Observable<null> {
    const endpoint = `${this.endpoint}/reSendCode`;
    const payload: IOtpRequest = {
      channel: 'email',
    };
    return this.http.post<null>(endpoint, payload);
  }
}
