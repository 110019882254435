<div class="products-section">
  <h1 class="d-lg-block d-none">¡Capacítate de forma rápida y gratuita de manera 100% digital!</h1>
  <h1 class="d-lg-none d-block">¡Capacítate de forma rápida y gratuita 100% digital!</h1>
  <div class="cards animate__animated animate__fadeInRight">
    <academy-credit-health></academy-credit-health>
    <academy-test-entrepreneur></academy-test-entrepreneur>
  </div>
</div>



