import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardVideo } from '@core/interfaces';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { CommentModel } from '@core/models/Video.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private readonly academyPublic: string = `${environment.apiBaseUrl}/api-academy/public`;
  private readonly academyHost: string = `${environment.apiBaseUrl}/api-academy`;
  private readonly cacheDuration = 3600000;

  constructor(private httpClient: HttpClient) {}

  getAllVideoComment(id: string): Observable<any> {
    return this.httpClient.get(`${this.academyHost}/topics/${id}/comments`);
  }

  setVideoComment(commentRequest: CommentModel): Observable<any> {
    return this.httpClient.post(
      `${this.academyHost}/topics/${commentRequest.topicId}/comment`,
      { comment: commentRequest.comment },
      { observe: 'response' }
    );
  }

  getAllPublicVideos(size: string): Observable<CardVideo[]> {
    const params = new HttpParams().set('size', size);
    return this.getCachedData('videos', `${this.academyPublic}/topics/videos`, params);
  }

  getAllPublicWebinars(size: string): Observable<CardVideo[]> {
    const params = new HttpParams().set('size', size);
    return this.getCachedData('webinars', `${this.academyPublic}/topics/webinars`, params);
  }

  private getCachedData(cacheKey: string, endpoint: string, params: HttpParams): Observable<CardVideo[]> {
    const cachedData = localStorage.getItem(cacheKey);
    const cacheTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const now = Date.now();

    if (cachedData && cacheTimestamp && (now - parseInt(cacheTimestamp) < this.cacheDuration)) {
      return of(JSON.parse(cachedData) as CardVideo[]);
    }

    return this.httpClient.get<CardVideo[]>(endpoint, { params }).pipe(
      tap(data => {
        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(`${cacheKey}_timestamp`, now.toString());
      })
    );
  }

  clearCache(): void {
    localStorage.removeItem('videos');
    localStorage.removeItem('videos_timestamp');
    localStorage.removeItem('webinars');
    localStorage.removeItem('webinars_timestamp');
  }
}
