import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { menuData } from '@core/data/dashboard/menu.json';
import { HeaderService } from '@core/services/header.service';

@Component({
  selector: 'yevo-intranet-side-nav',
  templateUrl: './intranet-side-nav.component.html',
  styleUrls: ['./intranet-side-nav.component.scss'],
})
export class IntranetSideNavComponent implements OnInit {
  menuItems = menuData;
  menuItemActive = menuData[0];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private headerService: HeaderService) {}

  ngOnInit(): void {
    const path = this.router.url;
    const params = this.activatedRoute.snapshot.queryParams;

    this.menuItems.forEach((item) => {
      if (path.includes('nuevo-intranet/dashboard') && item.param && params?.categoria === item.param) {
        this.menuItemActive = item;
      }
    });
  }

  handleClickedItem(item: any): void {
    if (item?.param) {
      this.menuItemActive = item;
      this.router.navigate(item.link, { queryParams: { categoria: item.param } });
      return;
    }

    if (item?.resources) {
      this.menuItemActive = menuData[0];
      this.router.navigate(item.link);
      this.headerService.setRedirectToDownloadFiles(true);
      return;
    }

    this.menuItemActive = item;
    this.router.navigate(item.link);
    window.scrollTo(0, 0);
  }
}
