import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IQuestionnaireFinalAnswerList } from 'src/app/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BusinessDiagnosisService {
  private readonly apiAcademyHost = `${environment.apiBaseUrl}/api-academy`;

  constructor(private http: HttpClient) {}

  public getSurveys() {
    const endpoint = `${this.apiAcademyHost}/survey/all`;
    return this.http.get(endpoint);
  }

  public getQuestionnaire(surveyId: number) {
    const endpoint = `${this.apiAcademyHost}/surveys/${surveyId}`;
    return this.http.get(endpoint);
  }

  public sendQuestionnaireAnswers(answerList: IQuestionnaireFinalAnswerList) {
    const endpoint = `${this.apiAcademyHost}/survey`;
    return this.http.post(endpoint, answerList);
  }

  public getLatestResult(parentSurveyId: number) {
    const endpoint = `${this.apiAcademyHost}/surveys/${parentSurveyId}/latest`;
    return this.http.get(endpoint);
  }

  public getSuggestions(userSurveyId: number) {
    const endpoint = `${this.apiAcademyHost}/user-surveys/${userSurveyId}/suggestions`;
    return this.http.get(endpoint);
  }

  public completeSurvey(parentSurveyId: number) {
    const endpoint = `${this.apiAcademyHost}/user-surveys/${parentSurveyId}/complete`;
    return this.http.put(endpoint, null);
  }
  
  public updateTopicAsSeen(surveyTopicId: string) {
    const endpoint = `${this.apiAcademyHost}/user-surveys/suggested-topics/${surveyTopicId}/seen`;
    return this.http.put(endpoint, {});
  }

  public updateCourseAsSeen(surveyCourseId: string) {
    const endpoint = `${this.apiAcademyHost}/user-surveys/suggested-courses/${surveyCourseId}/seen`;
    return this.http.put(endpoint, {});
  }
}
