import { Component } from '@angular/core';
import { CardCourse } from '@core/interfaces/frontend/course';
import { CourseService } from '@core/services/course.service';
import { DataLayerService } from '@core/services/data-layer.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';

@Component({
  selector: 'academy-certificate-section',
  templateUrl: './certificate-section.component.html',
  styleUrls: ['./certificate-section.component.scss'],
})
export class CertificateSectionComponent {
  courses: CardCourse[] = [];
  loaded = false;
  modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };
  constructor(
    private modalService: NgbModal,
    private dataLayerService: DataLayerService
  ) {}

  openRegisterModal(): void {
    this.dataLayerService.selectedShowMoreButton('Home Externo', 4, 'Ver más cursos');
    this.modalService.open(ModalRegisterComponent, this.modalOptionsNormal);
  }
}