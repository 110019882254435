import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuDashboard } from '@core/interfaces';
import { AuthService } from '@core/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { headerLinks,headerLinksLogin,menuData } from '@core/data/dashboard/menu.json';

@Component({
  selector: 'yevo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  isMobileMenuOpen: boolean = false;
  menu: MenuDashboard[] = menuData;
  isUserLogged: boolean = false;
  loadding=false;
  routerLinks: any = {
    default: headerLinks,
    logged: headerLinksLogin,
  };
  links: any = headerLinks;
  isMenuMobileExpande: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthService, 
  ) {}

  ngOnInit(): void {

    this.authService.isLogged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(logged => {
        this.isUserLogged = logged;
        this.links = this.getHeaderLinks(logged);
      });  
  }

  getHeaderLinks(isLogged: boolean) {
    if (isLogged) {
      return this.routerLinks.logged;
    }
    return this.routerLinks.default;
  }  

  mobileMenuIsExpanded(event: any) {
    if (event) {
      this.isMenuMobileExpande = true;
    } else {
      this.isMenuMobileExpande = false;
    }
  }

}
