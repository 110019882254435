import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { progressSpiralCardStyle, progressSpiralCardType } from '@shared/constants/progress-spiral.constants';
import * as PROGRESS_SPIRAL_DATA from '@core/data/progress-spiral/progress-spiral.data.json';

@Component({
  selector: 'yevo-progress-spiral-card',
  templateUrl: './progress-spiral-card.component.html',
  styleUrls: ['./progress-spiral-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressSpiralCardComponent implements OnInit {
  @Input() type!: progressSpiralCardType;
  @Input() style: progressSpiralCardStyle = 'full';
  @Input() isNew: boolean = false;
  @Input() hideHeartShadow: boolean = false;

  cardSpiralPath: string = '';
  cardHeartPath: string = '';
  cardHeartPathinternal: string = '';
  cardText: string = '';
  @Input() labelCreditHealth: string = '';

  ngOnInit(): void {
    this.getCreditHealthStatus();
    this.getCardIcons();
  }

  getCreditHealthStatus() {
    if (this.labelCreditHealth == '') {
      const creditHealth = JSON.parse(sessionStorage.getItem('credit-health') ?? '{}');
      this.labelCreditHealth = creditHealth?.labelTag || 'NUEVO';
    }
  }

  ngOnChanges(): void {
    this.getCardIcons();
  }

  private getCardIcons(): void {
    const path = '/assets/img/progress-spiral';
    const noShadow = this.hideHeartShadow && this.isCardWithSpiral() ? '-noshadow' : '';

    const spiralIcon = this.isCardWithSpiral() ? 'good' : this.type;
    const iconExtension = this.getIconExtension();
    this.cardSpiralPath = `${path}/spiral-${spiralIcon}.${iconExtension}`;
    this.cardHeartPath = `${path}/heart-${this.type}.png`;
    this.cardHeartPathinternal = `${path}/heart-internal-${this.type}${noShadow}.png`;
    this.cardText = PROGRESS_SPIRAL_DATA.spiralCardMessages[this.type];
  }

  public showHeartIcon(): boolean {
    return this.type !== 'course' && this.type !== 'negative';
  }

  public isCardWithSpiral(): boolean {
    return this.type !== 'course' && this.type !== 'negative' && this.type !== 'calculate';
  }

  private getIconExtension(): string {
    return this.type == 'course' ? 'png' : 'svg';
  }
}
