import { Component, EventEmitter, Input, Output } from '@angular/core';

export type buttonClassNames = 'primary' | 'light' | 'danger' | 'link';
export type buttonTypes = 'button' | 'submit';

@Component({
  selector: 'yevo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() id!: string;
  @Input() type: buttonTypes = 'button';
  @Input() className: buttonClassNames = 'primary';
  @Input() disabled: boolean = false;
  @Input() radius: boolean = false;
  @Output() buttonClick = new EventEmitter();

  handleClick($event: Event) {
    this.buttonClick.emit($event);
  }
}
