import { Component, HostListener, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesExamData, courseDataMeta } from '@core/data/training/course.json';
import { environment } from '@environments/environment';
import { CourseExam } from 'src/app/core/interfaces';
import { DataCourse } from 'src/app/core/models';
import { UserProfile } from 'src/app/core/models/UserProfileModel';
import { AuthService } from 'src/app/core/services/auth.service';
import { CourseService } from 'src/app/core/services/course.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
@Component({
  selector: 'yevo-training-iframe',
  templateUrl: './training-iframe.component.html',
  styleUrls: ['./training-iframe.component.scss'],
})
export class TrainingIframeComponent implements OnInit {
  private readonly urlRequest = `${environment.apiBaseUrl}/api-directory/course`;
  courseBaseUrl: string = environment.courseBaseUrl;
  courseUrl: string = '';
  url: string = '';
  urlSafe!: SafeResourceUrl;
  linkExam: string = '';
  coursesExam: CourseExam[] = CoursesExamData;
  courseMeta: any[] = courseDataMeta;
  user!: UserProfile;
  avanceCourse: DataCourse = {} as DataCourse;
  lesson: string = '';
  finish: boolean = false;
  paramCourseUrl = '';
  started: boolean = false;
  isLoading: boolean = true;

  utm_source: string = '';
  utm_medium: string = '';
  utm_campaign: string = '';

  finishCourse: boolean = false;

  constructor(
    private rutaActiva: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router,
    public courseService: CourseService,
    public authService: AuthService,
    private localStorageService: LocalStorageService,
    public dataLayerService: DataLayerService,
    private loaderService: LoaderService,
    private meta: Meta,
    private title: Title
  ) {
    this.paramCourseUrl = this.sanitizer.sanitize(
      SecurityContext.URL,
      this.sanitizer.bypassSecurityTrustUrl(this.rutaActiva.snapshot.paramMap.get('courseSlug')!)
    )!;
    this.authService.userProfile$.subscribe((user) => {
      this.user = user!;
    });
    if (this.isLoading) {
      this.loaderService.setIsLoadingLazyPage(true);
    }
  }

  ngOnInit(): void {
    this.getParamsShared();
    this.getSlugCourse();
    this.getExam();
    this.metaTags();
  }

  getParamsShared() {
    this.rutaActiva.queryParams.subscribe((params) => {
      this.utm_campaign = params.utm_campaign || '';
      this.utm_medium = params.utm_medium || '';
      this.utm_source = params.utm_source || '';
    });
  }

  getSlugCourse() {
    this.courseService.getAllVideo().subscribe((res: any) => {
      this.isLoading = false;
      this.loaderService.setIsLoadingLazyPage(false);
      this.url = res.find((val: any) => val.slug === this.paramCourseUrl).url;
    });
  }

  goToCourse() {
    if (this.avanceCourse && Object.keys(this.avanceCourse).length > 0) {
      this.lesson = `#/lessons/${this.avanceCourse.hash}`;
      this.finish = this.avanceCourse.finished;
      this.started = true;
    }

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.courseBaseUrl}/${this.paramCourseUrl}/index.html${this.lesson}?userId=${this.user.userId}&url=${
        this.urlRequest
      }&title=${this.paramCourseUrl}&token=${this.localStorageService.get(
        'access_token'
      )}&access=${this.localStorageService.get('application_token')}&subscription=${
        environment.subscriptionKey
      }&isTrue=${this.finish}&isStarted=${this.started}&utm_source=${this.utm_source}&utm_medium=${
        this.utm_medium
      }&utm_campaign=${this.utm_campaign}`
    );

    this.courseUrl = this.url.trim();
  }
  goBack() {
    this.router.navigate(['/capacitaciones'], { queryParamsHandling: 'merge' });
  }

  getExam() {
    const getLinkExam: any = this.coursesExam.find((val) => val.src === this.paramCourseUrl);
    this.linkExam = getLinkExam?.link || '';
  }

  goToExam() {
    window.open(this.linkExam, '_blank');
  }

  frameLoaded() {
    if (this.urlSafe) {
      this.isLoading = false;
      this.loaderService.setIsLoadingLazyPage(this.isLoading);
    }
  }

  metaTags() {
    const getMeta: any = this.courseMeta.find((val) => val.src === this.paramCourseUrl);

    this.title.setTitle(`${getMeta?.metaTitle}`);
    this.meta.updateTag({ name: 'title', content: `${getMeta?.metaTitle}` });
    this.meta.updateTag({ name: 'description', content: `${getMeta?.metaDescription}` });

    this.meta.updateTag({ property: 'og:type', content: 'website' });

    this.meta.updateTag({ property: 'og:url', content: `${getMeta.metaUrl}` });
    this.meta.updateTag({ property: 'og:title', content: `${getMeta.metaTitle}` });
    this.meta.updateTag({ property: 'og:description', content: `${getMeta.metaDescription}` });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://academiadelprogreso.mibanco.com.pe/assets/seo/logo.jpeg',
    });

    this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({
      property: 'twitter:url',
      content: `${getMeta.metaUrl}`,
    });
    this.meta.updateTag({ property: 'twitter:title', content: `${getMeta.metaTitle}` });
    this.meta.updateTag({ property: 'twitter:description', content: `${getMeta.metaDescription}` });
    this.meta.updateTag({
      property: 'twitter:image',
      content: 'https://academiadelprogreso.mibanco.com.pe/assets/seo/logo.jpeg',
    });
    this.meta.updateTag({ property: 'twitter:site', content: '@Yevo' });
  }

  @HostListener('window:message', ['$event'])
  onMessage(e: any) {
    const data = JSON.parse(e?.data);

    if (data.advanced || data.finished) {
      this.finishCourse = data.finished;
    }
  }
}
