<ul class="solutions">
  <!-- Desktop View -->
  <ng-container *ngIf="asDropdownChild">
    <li ngbDropdownItem class="solutions-li" *ngFor="let item of solutions; trackBy: trackByIndex">
      <a
        [routerLink]="item.routerLink"
        queryParamsHandling="merge"
        routerLinkActive="active"
        data-target=".navbar-collapse.show"
        [attr.data-layer-item-name]="item.name"
        class="solutions-link d-lg-flex"
        (click)="onNavigatehandler($event)"
      >
        <span class="wrap-icon mr-lg-3">
          <svg-icon-sprite [src]="item.icon.name" [width]="item.icon.width"></svg-icon-sprite>
        </span>
        {{ item.name }}
      </a>
    </li>
  </ng-container>
  <!-- END: Desktop View -->
  <!-- Mobile View -->
  <ng-container *ngIf="!asDropdownChild">
    <li *ngFor="let item of solutions; trackBy: trackByIndex" class="solutions-li" routerLinkActive="active">
      <a
        *ngIf="item.order != 4"
        [routerLink]="item.routerLink"
        queryParamsHandling="merge"
        data-target=".navbar-collapse.show"
        [attr.data-layer-item-name]="item.name"
        class="solutions-link d-lg-flex"
        (click)="onNavigatehandler($event)"
      >
        <span class="wrap-icon mr-lg-3">
          <svg-icon-sprite [src]="item.icon.name" [width]="item.icon.width"></svg-icon-sprite>
        </span>
        {{ item.name }}
      </a>

      <a
        *ngIf="item.order == 4"
        [href]="item.routerLink"
        queryParamsHandling="merge"
        rel="noopener noreferrer"
        class="solutions-link d-lg-flex"
        data-target=".navbar-collapse.show"
        [attr.data-layer-item-name]="item.name"
        class="solutions-link d-lg-flex"
      >
        <span class="wrap-icon mr-lg-3">
          <svg-icon-sprite [src]="item.icon.name" [width]="item.icon.width"></svg-icon-sprite>
        </span>
        {{ item.name }}
      </a>
    </li>
  </ng-container>
  <!-- END: Mobile View -->
</ul>
