<div class="d-flex justify-content-start align-items-center">
  <img class="mr-2" src="assets/icons/breadcrumb-arrow.svg" alt="breadcrumb" />
  <div
    *ngFor="let item of breadcrumbs; index as i"
    class="d-flex align-items-center justify-content-center breadcrumb-gap-2"
  >
    <a
      *ngIf="item.url !== null && !goBack"
      class="breadcrumb"
      [routerLink]="item.url"
      [queryParamsHandling]="queryParamsHandling"
      [ngClass]="{ 'breadcrumb-active': item.active }"
    >
      {{ item.pageName }}
    </a>

    <a
      *ngIf="goBack && i == 0"
      class="breadcrumb cursor"
      [ngClass]="{ 'breadcrumb-active': item.active }"
      (click)="doGoBack()"
    >
      {{ item.pageName }}
    </a>

    <a
      *ngIf="!item.url && goBack && i !== 0"
      class="breadcrumb"
      href="javascript:void(0)"
      [ngClass]="{ 'breadcrumb-active': item.active }"
    >
      {{ item.pageName }}
    </a>

    <a
      *ngIf="!item.url && !goBack"
      class="breadcrumb"
      href="javascript:void(0)"
      [ngClass]="{ 'breadcrumb-active': item.active }"
    >
      {{ item.pageName }}
    </a>
    <span *ngIf="i !== breadcrumbs.length - 1" class="mr-2">/</span>
  </div>
</div>
