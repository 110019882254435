import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'yevo-pagination-v2',
  templateUrl: './pagination-v2.component.html',
  styleUrls: ['./pagination-v2.component.scss'],
})
export class PaginationV2Component {
  @Input() pageSize!: number;
  @Input() dataSize = 0;
  @Input() page!: number;
  @Input() style: string = '';
  @Output() pageChange = new EventEmitter<number>();

  setPage(newCurrentPage: number): void {
    this.pageChange.emit(newCurrentPage);
  }
}
