<div class="side-nav h-100 d-block d-md-none">
  <nav>
    <div
      *ngFor="let menuItem of menuItems"
      class="menu_item"
      [class.active]="menuItemActive === menuItem"
      (click)="handleClickedItem(menuItem)"
    >
      <img
        [src]="menuItemActive === menuItem ? menuItem.iconSrcActive : menuItem.iconSrc"
        [alt]="menuItem.label + ' icono'"
      />
      <p>{{ menuItem.label }}</p>
    </div>
  </nav>
</div>
