import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'yevo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: { pageName: string; url: string[] | null; active: boolean }[] = [];
  @Input() goBack = false;
  @Input() bread = '';
  @Input() queryParamsHandling: 'merge' | '' = 'merge';

  constructor(private router: Router, private localStorageService: LocalStorageService) {}

  doGoBack(): void {
    this.router.navigate([this.bread], {
      queryParams: {
        estado: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
