import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { DataLayerService } from '@core/services/data-layer.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { map, take } from 'rxjs/operators';
import { SectionNames } from 'src/app/new-intranet/views/affiliate/affiliate.component';

@Component({
  selector: 'yevo-desktop-menu-user',
  templateUrl: './desktop-menu-user.component.html',
  styleUrls: ['./desktop-menu-user.component.scss'],
})
export class DesktopMenuUserComponent implements OnInit {
  userName$ = this.authService.userProfile$.pipe(map((user) => user?.fullName ?? 'Invitado'));
  isLOgged: boolean = false;
  userName: string = '';

  @Output() closedDesktopMenuUser = new EventEmitter();
  @Output() enterDesktopMenuUser = new EventEmitter();

  constructor(
    public authService: AuthService,
    private localStorageService: LocalStorageService,
    public dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      const name = user?.name.split(' ')[0];
      this.userName = `${name} ${user?.fatherLastName}`;
    });
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLOgged = true;
      } else {
        this.isLOgged = false;
      }
    });
  }

  closeSession(): void {
    this.localStorageService.remove('refresh_token');
    this.authService.logout();

    this.localStorageService.bulkRemove(Object.values(SectionNames));
    sessionStorage.clear();
    this.localStorageService.remove('reloaded');
    this.localStorageService.remove('activePageUrl');
    sessionStorage.removeItem('activePageUrl');
    if (sessionStorage.getItem('intranetPath')) {
      const dataUrl = JSON.parse(sessionStorage.getItem('intranetPath')!);
      if (dataUrl[dataUrl.length - 2].includes('nuevo-intranet')) {
        this.authService.logout();
      } else {
        this.authService.logout('ingresar');
      }
    }
    sessionStorage.removeItem('intranetPath');
  }

  onHoverLeave() {
    this.closedDesktopMenuUser.emit(true);
    const element: any = document.querySelector('.header-square-dashboard-course4');
    element?.classList.remove('d-block');
  }

  mouseEnter() {
    this.enterDesktopMenuUser.emit(true);
  }
}
