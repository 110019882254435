<div class="row">
  <div class="col-12">
    <div class="close new-terms-conditions-close">
      <span class="images__clouds"></span>
    </div>
  </div>
  <div class="col-12 img text-center mt-3">
    <img src="/assets/img/terms/logo-terms.png" alt="" />
  </div>
  <div class="col-12">
    <h1 class="new-terms-conditions-title mb-3 mt-3">
      ¡Nueva actualización<br />
      de Términos y<br />
      Condiciones!
    </h1>
  </div>
  <div class="col-12">
    <p class="new-terms-conditions-description">
      Hemos actualizado nuestros términos y condiciones para brindarte una mejor experiencia.
    </p>
  </div>
  <hr />
  <form [formGroup]="form" (submit)="getRecaptchaToken()" class="container">
    <div class="col-12 new-terms-conditions-container-term">
      <div class="row">
        <div class="form-group col-12 text-left mb-0">
          <div class="custom-control custom-checkbox">
            <input
              id="chkTermsModal"
              type="checkbox"
              class="form-control custom-control-input"
              formControlName="chkTerms"
              [attr.data-layer-checkbox-label]="'Acepto los terminos y condiciones'"
            />
            <label for="chkTermsModal" class="custom-control-label new-terms-conditions-term new-terms-conditions-label"
              >Acepto los
              <a class="link" [routerLink]="['/terminos-y-condiciones']" queryParamsHandling="merge" target="_blank"
                >términos y condiciones.*</a
              >
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 mb-0">
          <div class="custom-control custom-checkbox">
            <input
              id="chkPersonalData"
              type="checkbox"
              class="form-control custom-control-input"
              formControlName="chkPersonalData"
              [attr.data-layer-checkbox-label]="'Aceptar el tratamiento de mis datos personales'"
            />
            <label for="chkPersonalData" class="custom-control-label text-left new-terms-conditions-label">
              He leído y entendido el
              <a
                class="link"
                [routerLink]="['', { outlets: { modal: ['tratamiento-datos-personales'] } }]"
                queryParamsHandling="merge"
                >Consentimiento Informado para el Tratamiento de Datos Personales.*</a
              >
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-grou col-12 mb-0">
          <div class="custom-control custom-checkbox">
            <input
              id="shareThirdParties"
              type="checkbox"
              class="form-control custom-control-input"
              formControlName="shareThirdParties"
              [attr.data-layer-checkbox-label]="'Aceptar compartir mis datos personales con terceros'"
            />
            <label for="shareThirdParties" class="custom-control-label text-left new-terms-conditions-label"
              >Acepto compartir mis
              <a class="link" [routerLink]="['', { outlets: { modal: ['compartir-datos-personales'] } }]"
                >datos personales con terceros para usos adicionales.</a
              ></label
            >
          </div>
        </div>
      </div>

      <div class="register__submit mt-4 mb-2 text-center">
        <button type="submit" class="btn btn-primary button new-terms-conditions-button" [disabled]="form.invalid">
          Continuar
        </button>
      </div>
    </div>
  </form>

  <div class="col-12">
    <p class="new-terms-conditions-disclaimer">
      Al enviarnos esta información autorizas a Academia del Progreso al tratamiento de
      <span>tus datos personales</span>. Este sitio está protegido por reCAPTCHA y se aplican la
      <span>Política de privacidad</span> y <span>Términos del servicio de Google</span>.
    </p>
  </div>
</div>
