import { Component } from '@angular/core';

export interface ISchema {
  '@context': string;
  '@type': string;
  alternateName?: string;
  disambiguatingDescription?: string;
  logo?: string;
  name: string;
  parentOrganization?: string;
  url: string;
}

@Component({
  selector: 'ld-json-script',
  template: ` <ngx-json-ld [json]="schema"></ngx-json-ld> `,
})
export class LdJsonScriptComponent {
  schema: ISchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    alternateName: 'Academia del Progreso - Mibanco',
    disambiguatingDescription:
      'Hemos creado un espacio con servicios que te ayudarán a impulsar el crecimiento de tu negocio. Además, podrás encontrar miles de productos y servicios cerca de ti.',
    logo: 'https://academiadelprogreso.mibanco.com.pe/assets/img/logo.png',
    name: 'Academia del Progreso',
    parentOrganization: 'Mibanco',
    url: 'https://academiadelprogreso.mibanco.com.pe/',
  };
}
