import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ToolResponse } from 'src/app/core/interfaces/frontend/communities';
import { CourseService } from 'src/app/core/services/course.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

@Component({
  selector: 'yevo-downloadable-resources',
  templateUrl: './downloadable-resources.component.html',
  styleUrls: ['./downloadable-resources.component.scss'],
})
export class DownloadableResourcesComponent {
  @Input() tool!: ToolResponse;
  @Input() parentRoute!: string;
  btndissable: boolean = false;

  constructor(
    private http: HttpClient,
    private dataLayerService: DataLayerService,
    private downloadService: CourseService
  ) {}

  downloadFile(url: string, fileName: string) {
    this.btndissable = true;

    setTimeout(() => {
      this.btndissable = false;
    }, 2000);

    if (this.tool.fileId) {
      this.dataLayerService.downloadResourceFile(this.tool.title);
      this.downloadService.postTrainingDownload(this.tool.fileId).subscribe((res) => {
        window.open(url, '_blank');
      });
    }
  }
}
