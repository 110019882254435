import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { IntranetCardComponent } from './components/intranet-card/intranet-card.component';
import { IntranetServiceCardMobileComponent } from './components/intranet-service-card-mobile/intranet-service-card-mobile.component';
import { IntranetServiceCardComponent } from './components/intranet-service-card/intranet-service-card.component';
import { IntranetSideNavLinkComponent } from './components/intranet-side-nav-link/intranet-side-nav-link.component';
import { IntranetSideNavComponent } from './components/intranet-side-nav/intranet-side-nav.component';
import { IntranetTabComponent } from './components/intranet-tab/intranet-tab.component';
import { LabelPillComponent } from './components/label-pill/label-pill.component';
import { LoanRequestCardComponent } from './components/loan-request-card/loan-request-card.component';
import { ReferAdComponent } from './components/refer-ad/refer-ad.component';
import { ShortcutPillComponent } from './components/shortcut-pill/shortcut-pill.component';
import { NewIntranetRoutingModule } from './new-intranet-routing.module';
import { NewIntranetComponent } from './new-intranet.component';
import { UserProfileResolver } from './resolvers/user-profile.resolver';
import { AffiliateConfirmationComponent } from './views/affiliate/affiliate-confirmation.component';
import { AffiliateComponent } from './views/affiliate/affiliate.component';
import { ContactInfoComponent } from './views/affiliate/contact-info/contact-info.component';
import { GeneralInfoComponent } from './views/affiliate/general-info/general-info.component';
import { ImageUploaderComponent } from './views/affiliate/image-uploader/image-uploader.component';
import { LocationsComponent } from './views/affiliate/locations/locations.component';
import { ProductInfoComponent } from './views/affiliate/product-info/product-info.component';
import { FinancingComponent } from './views/financing/financing.component';
import { ProfilePersonalInfoComponent } from './views/profile-personal-info/profile-personal-info.component';
import { ProfileComponent } from './views/profile/profile.component';

import { CURRENCY_MASK_CONFIG, CurrencyMaskModule } from 'ng2-currency-mask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AffiliateAdComponent } from './components/affiliate-ad/affiliate-ad.component';
import { FinancingAdComponent } from './components/financing-ad/financing-ad.component';

import { LayoutModule } from '@angular/cdk/layout';
import localeEs from '@angular/common/locales/es';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { IntranetModule } from '../intranet/intranet.module';
import { BannerBenefitsComponent } from './components/banner-benefits/banner-benefits.component';
import { CommunityComponent } from './components/community/community.component';
import { BannerComponent } from './components/dashboard/banner/banner.component';
import { CardComponent } from './components/dashboard/card/card.component';
import { TrainingDashboardComponent } from './components/dashboard/training-dashboard/training-dashboard.component';
import { VideoDashboardComponent } from './components/dashboard/video-dashboard/video-dashboard.component';
import { IncompleteProfileModalComponent } from './components/incomplete-profile-modal/incomplete-profile-modal.component';
import { IntranetSocialIconsComponent } from './components/intranet-social-icons/intranet-social-icons.component';
import { AlliedComponent } from './views/allied/allied.component';
import { ProfileMainInfoComponent } from './views/profile-main-info/profile-main-info.component';

import { CustomCurrencyMaskConfig } from '@shared/constants/dashboard.constants';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeaderModule } from '../design/templates/header/header.module';
import { FooterModule } from '../design/templates/layout/footer/footer.module';
import { HeaderComponentModule } from '../design/templates/layout/header/header-component.module';
import { LandingModule } from '../presentation/landing/landing.module';
import { BannerAlliedNewComponent } from './components/banner-allied-new/banner-allied-new.component';
import { CardCommunityComponent } from './components/card-community/card-community.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    FinancingComponent,
    GeneralInfoComponent,
    ImageUploaderComponent,
    IntranetCardComponent,
    IntranetServiceCardComponent,
    IntranetServiceCardMobileComponent,
    IntranetSideNavComponent,
    IntranetSideNavLinkComponent,
    IntranetTabComponent,
    LabelPillComponent,
    LoanRequestCardComponent,
    NewIntranetComponent,
    ProfileComponent,
    ProfilePersonalInfoComponent,
    ProfileMainInfoComponent,
    ReferAdComponent,
    ShortcutPillComponent,
    FinancingAdComponent,
    AffiliateComponent,
    AffiliateConfirmationComponent,
    ContactInfoComponent,
    GeneralInfoComponent,
    ImageUploaderComponent,
    LocationsComponent,
    ProductInfoComponent,
    AffiliateAdComponent,
    IntranetSocialIconsComponent,
    CardComponent,
    BannerComponent,
    AlliedComponent,
    IncompleteProfileModalComponent,
    CommunityComponent,
    BannerBenefitsComponent,
    TrainingDashboardComponent,
    VideoDashboardComponent,
    CardCommunityComponent,
    BannerAlliedNewComponent,
  ],
  imports: [
    CommonModule,
    NewIntranetRoutingModule,
    AngularSvgIconModule,
    HeaderComponentModule,
    FooterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CurrencyMaskModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    LayoutModule,
    NgbModule,
    IntranetModule,
    NgxSkeletonLoaderModule,
    SlickCarouselModule,
    HeaderModule,
    LandingModule,
  ],
  exports: [
    IntranetSideNavComponent,
    IntranetSideNavLinkComponent,
    IntranetTabComponent,
    LabelPillComponent,
    ShortcutPillComponent,
    CardCommunityComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [UserProfileResolver, DecimalPipe, { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }],
})
export class NewIntranetModule {}
