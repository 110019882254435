<div
  class="modal-container d-flex flex-column align-items-center justify-content-center"
  [ngClass]="page === 'simulador' ? 'isModalSimulator' : ''"
>
  <div class="container-close-button" *ngIf="type != modalTypes.PERSONAL_DATA">
    <button type="button" class="close mr-3 mt-2" (click)="closeModal()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div
    class="container-img"
    [ngClass]="{
      'mt-2': type != modalTypes.PERSONAL_DATA,
      'mt-4': type == modalTypes.PERSONAL_DATA
    }"
  >
    <img class="modal-img mb-3" [src]="getMainImage()" alt="person-adviser" />
  </div>
  <h2 class="title mb-4 mt-3">{{ getTitle() }}</h2>
  <p
    class="subtitle d-none d-md-block"
    [ngClass]="{ 'personal-data-disclaimer': type == modalTypes.PERSONAL_DATA }"
    [innerHTML]="getSubtitle()"
  ></p>
  <p
    class="subtitle d-block d-md-none"
    [ngClass]="{ 'personal-data-disclaimer': type == modalTypes.PERSONAL_DATA }"
    id="modal-title-disclaimer"
  >
    <span class="subtitle" [innerHTML]="getSubtitle()"></span>
  </p>
  <yevo-button-animated
    *ngIf="type != modalTypes.SENT_SOLICITUDE && type != modalTypes.PERSONAL_DATA"
    [label]="'Ver agencias mas cercanas'"
    class="w-100 px-3 pt-2"
    [style]="'outline'"
    (buttonClick)="goToAgencies()"
  ></yevo-button-animated>
  <yevo-button-animated
    *ngIf="type != modalTypes.PERSONAL_DATA"
    [label]="'Ir al inicio'"
    class="w-100 px-3 pt-2"
    [ngClass]="type !== modalTypes.BANK_CLIENT ? 'pb-5' : 'pb-3'"
    [style]="'green'"
    [icon]="'/assets/icons/home-white.svg'"
    (buttonClick)="goToDashboard()"
  ></yevo-button-animated>
  <yevo-button-animated
    *ngIf="type == modalTypes.PERSONAL_DATA"
    [label]="'Entendido'"
    class="w-100 px-3 pt-2 pb-3"
    [style]="'green'"
    (buttonClick)="personalDataButtonClick()"
  ></yevo-button-animated>
  <p *ngIf="type == modalTypes.BANK_CLIENT" class="download-app mb-5">
    Descargar la App Mibanco <span class="download-app-link" (click)="downloadApp()">AQUÍ</span>
  </p>
</div>
