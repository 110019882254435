import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.checkIfNotLogged();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkIfNotLogged();
  }

  private checkIfNotLogged(): Observable<boolean> {
    return this.authService.isLogged$.pipe(
      map((isLogged) => {
        if(isLogged) this.router.navigate(['/']);
        return !isLogged;
      })
    );
  }
}
