<button
  [id]="id"
  [type]="type"
  [ngClass]="{
    primary: className === 'primary',
    light: className === 'light',
    danger: className === 'danger',
    link: className === 'link',
    'button-radius': radius
  }"
  [disabled]="disabled"
  (click)="handleClick($event)"
>
  <ng-content></ng-content>
</button>
