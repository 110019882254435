import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserCommunity } from '@core/interfaces/frontend/communities';
import { UserProfile } from '@core/models/UserProfileModel';
import { CommunityService } from '@core/services/community.service';
import { CourseService } from '@core/services/course.service';
import { DashboardService } from '@core/services/dashboard.service';
import { DataLayerService } from '@core/services/data-layer.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { MailchimpTransactionalService } from '@core/services/mailchimp-transactional.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yevo-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss'],
})
export class DesktopMenuComponent implements OnInit, OnDestroy {
  @Input() links: any = [];
  @Input() isLOgged: boolean = false;

  @Output() closedDesktopMenu = new EventEmitter();
  @Output() desktopMenuId = new EventEmitter();
  selectCommunity = '';
  titleButton = '';
  imgCard = '';
  titleCard = '';
  code = '';
  COMMUNITY_ID = 0;
  subscription: Subscription = new Subscription();
  user!: UserProfile;
  userCommunity: UserCommunity = {
    name: '',
    userId: 0,
    cellPhone: '',
    email: '',
    communityId: 0,
  };
  hover: string = '';
  linkActive!: any;

  openModalSeidor: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  iconHomeGreen: boolean = false;
  mainMenu = -1;

  constructor(
    private router: Router,
    private LocalStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    public dataLayerService: DataLayerService,
    private communityService: CommunityService,
    private mailchimpTransactionalService: MailchimpTransactionalService,
    private modalService: NgbModal,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    this.linkActive = this.LocalStorageService.get('activePageUrlSubmenu') ?? 0;
    this.mainMenu = sessionStorage.getItem('activePage') ? parseInt(sessionStorage.getItem('activePage')!) : -1;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onHoverLeave() {
    this.closedDesktopMenu.emit(true);
  }
  onLink(number: number, link: string[], params: any = {}, option: any = '') {
    const pathname = window.location.pathname.split('/')[2];

    this.closedDesktopMenu.emit(true);

    this.LocalStorageService.set('activePageUrlSubmenu', number.toString());

    if (number === 5 || number === 6) {
      this.LocalStorageService.set('activeCalendar', 'true');
      setTimeout(() => {
        this.dashboardService.changeCalendar(true);
        this.router.navigate(link, { queryParamsHandling: 'merge' });
      }, 300);
      return;
    }
    if (Object.keys(params).length) {
      this.router.navigate(link, { queryParams: params, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(link, { queryParamsHandling: 'merge' });
    }

    if (pathname === 'dashboard' && option === 'comunidades') {
      this.dashboardService.changeCommunity(true);
    } else if (pathname !== 'dashboard' && option === 'comunidades') {
      sessionStorage.setItem('redirectFaq', 'comunidades');
    }
  }

  onLinkCommunity() {
    sessionStorage.setItem('communityWoman', 'true');
    this.communityService.changeCommunitySection(true);
    this.router.navigate(['comunidades'], { queryParamsHandling: 'merge' });
  }

  onLinkCourses(courseId: string) {
    this.router.navigate(['/capacitaciones', { queryParamsHandling: 'merge' }]);
  }

  hoverDesktop(number: number) {
    if (this.isLOgged) {
      let element = document.getElementById('dropdown_hover-list-logged' + number);
      element?.classList.add('d-block');
    } else {
      let element = document.getElementById('dropdown_hover-list' + number);
      element?.classList.add('d-block');
    }
    this.hover = 'hover' + number;
  }

  leaveDesktop(number: number) {
    this.hover = '';
    if (this.isLOgged) {
      let element = document.getElementById('dropdown_hover-list-logged' + number);
      element?.classList.remove('d-block');
    } else {
      let element = document.getElementById('dropdown_hover-list' + number);
      element?.classList.remove('d-block');
    }
  }

  home() {
    this.closedDesktopMenu.emit(true);
    this.LocalStorageService.remove('activePage');
    this.LocalStorageService.remove('activePageUrlSubmenu');
    if (this.isLOgged) {
      this.router.navigate(['/nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/'], { queryParamsHandling: 'merge' });
    }
  }

  goToFinance() {
    this.closedDesktopMenu.emit(true);
    if (this.isLOgged) {
      this.router.navigate(['/financiamiento', 'filtro-financiamiento'], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/financiamiento', 'solicitud'], { queryParamsHandling: 'merge' });
    }
  }

  goToTraining() {
    this.closedDesktopMenu.emit(true);
    if (this.isLOgged) {
      this.router.navigate(['capacitaciones'], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/servicios', 'capacitaciones'], { queryParamsHandling: 'merge' });
    }
  }

  goToBenefits(number: number = 0) {
    this.LocalStorageService.set('activePageUrlSubmenu', number.toString());

    this.closedDesktopMenu.emit(true);
    this.router.navigate(['/aliados'], { queryParamsHandling: 'merge' });
  }

  gotoCalendar() {
    this.dashboardService.changeCalendar(true);
    this.LocalStorageService.set('activeCalendar', 'true');
  }

  validateUserCommunity(code: string): void {
    this.code = code;

    this.LocalStorageService.set('activePageUrlSubmenu', '6');
  }

  openBanner(titleButton: string): void {
    if (titleButton !== 'Ingresa aquí') {
      this.userCommunity.userId = this.user.userId;
      this.userCommunity.name = `${this.user.name} ${this.user.fatherLastName} ${this.user.motherLastName}`;
      this.userCommunity.cellPhone = this.user.phone;
      this.userCommunity.email = this.user.email;
      this.userCommunity.communityId = this.COMMUNITY_ID;

      this.communityService.createUserCommunity(this.userCommunity).subscribe(
        (response) => {
          this.openCommunity(this.code);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.openCommunity(this.code);
    }
  }

  private openCommunity(code: string): void {
    code === 'COMMUNITY_WOMEN'
      ? this.router.navigate(['/nuevo-intranet', 'comunidades', 'emprende-mujer'], { queryParamsHandling: 'merge' })
      : this.router.navigate(['/nuevo-intranet', 'comunidades', 'bodegueros'], { queryParamsHandling: 'merge' });

    this.closedDesktopMenu.emit(true);
  }

  private searchUser(): void {
    if (!this.user) {
      const userString = this.LocalStorageService.get('userProfile');
      if (userString && this.COMMUNITY_ID !== 0) {
        this.user = JSON.parse(userString) as UserProfile;

        this.communityService.getUserCommunity(this.user.userId, this.COMMUNITY_ID).subscribe(
          (response) => {
            this.userCommunity = response;
            if (this.userCommunity.userId !== 0) {
              this.openBanner('Ingresa aquí');
            } else {
              this.openBanner('Afiliate aquí');
            }
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  }

  goToSimulator() {
    if (this.isLOgged) {
      this.mailchimpTransactionalService.postSimulatorEmail().subscribe();
    }
  }

  iconHome() {
    this.iconHomeGreen = true;
  }
  iconHomeLeave() {
    this.iconHomeGreen = false;
  }

  goToLogin() {
    this.router.navigate(['/ingresar']);
  }

  goToRegister() {
    this.router.navigate(['/registro']);
  }

  goToHome() {
    this.router.navigate(['/'], { queryParamsHandling: 'merge' });
  }
  openModalRegister() {
    this.closedDesktopMenu.emit(true);
    this.modalService.open(ModalRegisterComponent, this._modalOptionsNormal);
  }

  goToCommunity(number: number = -1) {
    this.LocalStorageService.set('activePageUrlSubmenu', number.toString());
    sessionStorage.setItem('communityWoman', 'true');
    this.communityService.changeCommunitySection(true);
    setTimeout(() => {
      this.router.navigate(['/comunidades'], { queryParamsHandling: 'merge' });
    }, 300);
  }

  goTotrainingDownload(number: number = 0) {
    this.LocalStorageService.set('activePageUrlSubmenu', number.toString());
    sessionStorage.setItem('trainingDownload', 'true');
    this.courseService.changeDownloadSection(true);
    setTimeout(() => {
      this.router.navigate(['/capacitaciones'], { queryParamsHandling: 'merge' });
    }, 300);
  }

  isActiveMenuMobile(id: number) {
    if (id !== 1 && id !== 21) {
      this.desktopMenuId.emit(id);
      sessionStorage.setItem('activePageMobile', `${id}`);
    } else {
      sessionStorage.setItem('activePageMobile', `-1`);
    }
  }
}
