<div class="container">
  <div class="row">
    <!-- DESKTOP -->
    <div class="col-12 d-none d-md-block" *ngIf="loaded && items?.length !== 0">
      <div class="training-dashboard__container_arrow">
        <img
          [src]="cardsType !== 'course' ? '/assets/icons/arrow-next-white.svg' : '/assets/icons/arrow-next.svg'"
          class="training-dashboard_arrow_left"
          (click)="ListPrev()"
          alt="previous"
        />
        <p class="training-dashboard__arrow mb-0">&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <img
          [src]="cardsType !== 'course' ? '/assets/icons/arrow-next-white.svg' : '/assets/icons/arrow-next.svg'"
          class="training-dashboard_arrow_right"
          (click)="ListNext()"
          alt="next"
        />
      </div>
    </div>

    <!-- DESKTOP -->
    <swiper
      #swiperDashboardCourseDesktop
      class="swiperDashboardCourseDesktop col-12 pl-md-0 pr-md-0 training-dashboard-banner d-none d-md-block"
      [config]="configSwiperDesktop"
      [ngClass]="cardsType === 'course' ? 'courseSwiper' : 'courseSwiperWhite'"
      *ngIf="items.length"
    >
      <ng-template swiperSlide *ngFor="let item of items; let i = index">
        <div class="items-container" *ngIf="loaded">
          <academy-course [isLoggedIn]="isLogged" [course]="item"></academy-course>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
<!-- END DESKTOP -->

<!-- MOBILE -->
<swiper
  #swiperDashboard
  class="mySwiper col-12 pl-md-0 pr-md-0 training-dashboard-banner d-block d-md-none"
  [config]="configSwiper"
  [ngClass]="cardsType === 'course' ? 'courseSwiper' : 'courseSwiperWhite'"
  *ngIf="items.length"
>
  <ng-template swiperSlide *ngFor="let item of items; let i = index">
    <div class="items-container" *ngIf="loaded">
      <academy-course [isLoggedIn]="isLogged" [course]="item"></academy-course>
    </div>
  </ng-template>
</swiper>

<div class="container-fluid" *ngIf="loaded && items?.length !== 0">
  <div class="row">
    <div class="col-12">
      <!-- MOBILE -->
      <div class="d-block d-md-none arrow-mobile-training">
        <div class="position-absolute arrow-mobile-training__container">
          <div class="training-dashboard__container_arrow" [ngClass]="loaded ? 'mt-3' : 'mt-2 bg-white'">
            <img
              [src]="cardsType !== 'course' ? '/assets/icons/arrow-next-white.svg' : '/assets/icons/arrow-next.svg'"
              class="training-dashboard_arrow_left"
              (click)="listPrevMobile()"
              alt="arrow left"
            />
            <p class="training-dashboard__arrow mb-0"></p>
            <img
              [src]="cardsType !== 'course' ? '/assets/icons/arrow-next-white.svg' : '/assets/icons/arrow-next.svg'"
              class="training-dashboard_arrow_right"
              (click)="listNextMobile()"
              alt="arrow right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
