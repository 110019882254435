import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TopicModel } from 'src/app/core/models/Topic.model';
import { stripHtml } from '@shared/utils/strip-html.utils';
import { MOST_RECENT_LABEL } from '../constants/training.constants';

@Component({
  selector: 'yevo-training-card-v1',
  templateUrl: './training-card-v1.component.html',
  styleUrls: ['./training-card-v1.component.scss'],
})
export class TrainingCardV1Component {
  @Input() topic: TopicModel = {} as TopicModel;
  @Input() content: boolean = false;
  @Input() cardTitle: string = '';
  @Input() dashboard: boolean = false;
  @Input() education: boolean = false;
  readonly mostRecent = MOST_RECENT_LABEL;

  @Output() clickTopic: EventEmitter<TopicModel> = new EventEmitter();
  @Output() clickVideo: EventEmitter<boolean> = new EventEmitter();
  @Output() clickButtonVideo: EventEmitter<boolean> = new EventEmitter();
  @Output() clickTovideo: EventEmitter<string> = new EventEmitter();
  @Output() clickToItem: EventEmitter<any> = new EventEmitter();

  constructor(public sanitizer: DomSanitizer) {}

  handleClick(): void {
    if (!this.dashboard) {
      this.clickTopic.emit(this.topic);
    }
  }

  getTextFromHtml(html: string): string {
    return stripHtml(html);
  }

  getActionButtonText(type: string): string {
    if (type == 'video') {
      return 'Ver video';
    }
    if (type == 'course') {
      return 'Tomar curso';
    }
    if (type == 'webinar') {
      return 'Ver webinar';
    }
    return 'Ver más';
  }

  handleClickVideo(type: string): void {
    if (type == 'video' && this.dashboard) {
      this.clickVideo.emit(true);
    }
  }

  handleClickButtonVideo(type: string): void {
    if (type == 'video' && this.dashboard) {
      this.clickButtonVideo.emit(true);
    }
  }

  toVideo(topic: TopicModel): void {
    if (this.dashboard) {
      this.clickTovideo.emit(topic.slug);
      this.clickToItem.emit(topic);
    }
  }
}
