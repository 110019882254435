import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataCalendar } from 'src/app/core/models/calendar.model';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private readonly contentManagerApiPublic = `${environment.apiBaseUrl}/api-academy/public/events`;

  constructor(private httpClient: HttpClient) {}

  getListEventsCalendar(types: string = ''): Observable<DataCalendar[]> {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const start = new Date(year, month, 1);
    const nextDate = new Date(year, month + 1, 0);
    const startDate = start.toISOString().split('T')[0] + ' 00:00:00';
    const endDate = nextDate.toISOString().split('T')[0] + ' 23:59:59';

    const params = { startDate, endDate, types };
    return this.httpClient.get<DataCalendar[]>(`${this.contentManagerApiPublic}`, { params });
  }
}
