<div *ngIf="showSliderPills" class="category-pills">
  <div class="scroll-arrows left-arrow" [ngClass]="{ 'hidden': isLeftArrowHidden }" (click)="scrollLeft()">
    <img src="assets/icons/arrow-left-gray.svg" alt="left">
  </div>
  <div class="category-pills-container" #container>
    <div
      class="category-pill"
      [class.d-none]="items.length === 0"
      *ngFor="let item of items"
      [ngClass]="{ active: item?.value == itemSelected.value }"
      (click)="handleSelectItem($event, item)"
    >
      <span>{{ item.label }}</span>
    </div>
    <div class="category-pill" [class.d-none]="items.length !== 0" *ngFor="let n of [1,2,3,4,5,6]">
      <span>-----------</span>
    </div>
  </div>
  <div class="scroll-arrows right-arrow" [ngClass]="{ 'hidden': isRightArrowHidden }" (click)="scrollRight()">
    <img src="assets/icons/arrow-right-gray.svg" alt="right">
  </div>
</div>


<div *ngIf="!showSliderPills" class="filter-pills-container d-flex flex-wrap"
  [ngClass]="{ 'justify-content-between': showJustifyContentBetween, 'filter-pills-container-v2': showJustifyWrap }">
  <div *ngFor="let showedItem of showedItems" class="showed-pills" [ngClass]="{
    active: showedItem?.value == itemSelected?.value,
    'second-theme': showSecondTheme,
    'showed-pills': !showSecondTheme,
    inactive: showInactiveTheme && !showedItem?.enabled
  }" (click)="handleSelectItem($event, showedItem)">
    <p>{{ showCustomTitle ? getDropdownTitle(showedItem.label) : showedItem.label }}</p>
  </div>
  <div ngbDropdown class="hidden-pills" [placement]="dropdownPlacement" *ngIf="showSeeMore">
    <button class="more-pills-button" ngbDropdownToggle>
      {{ moreButtonText }}
    </button>
    <div ngbDropdownMenu class="select-pill-dropdown">
      <div class="select-pill-dropdown-header">
        <div class="select-pill-dropdown__title" *ngIf="!!dropdownTitle">
          {{ dropdownTitle }}
        </div>
        <div class="select-pill-dropdown__close" (click)="closeDropdownMenu()">
          <img src="/assets/img/close.png" alt="close_icon" />
        </div>
      </div>
      <div *ngFor="let hiddenItem of hiddenItems" class="select-pill-dropdown__item"
        (click)="handleSelectItem($event, hiddenItem); closeDropdownMenu()">
        <span [ngClass]="{ active: hiddenItem?.value == itemSelected?.value }">
          {{ hiddenItem.label }}
        </span>
      </div>
    </div>
  </div>
</div>
