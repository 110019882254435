import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VideoProgressService {
  private videoProgressInterval: any = null;

  startVideoProgressInterval(player: any, onVideoProgress75Percent: () => void) {
    if (!this.videoProgressInterval) {
      this.videoProgressInterval = setInterval(() => {
        const duration = player.getDuration();
        const currentTime = player.getCurrentTime();
        const videoProgress = (currentTime / duration) * 100;

        if (videoProgress >= 75) {
          onVideoProgress75Percent();
          this.stopVideoProgressInterval();
        }
      }, 2000);
    }
  }

  public stopVideoProgressInterval() {
    if (this.videoProgressInterval) {
      clearInterval(this.videoProgressInterval);
      this.videoProgressInterval = null;
    }
  }

  public initScriptPlayer(window: any, document: Document): boolean {
    if ((window['YT'] && navigator.userAgent.indexOf('Chrome') > -1) || navigator.userAgent.indexOf('Safari') > -1) {
      return true;
    }
    let tag: any = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    let firstScriptTag: any = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => {
      return navigator.userAgent.indexOf('Chrome') > -1 || navigator.userAgent.indexOf('Safari') > -1;
    };
    return false;
  }
}
