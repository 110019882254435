<div
  *ngIf="style === 'full'; else noimages"
  class="card spiral-card second-theme only-image"
  [ngClass]="{ type: !!type, 'only-image': !isCardWithSpiral() }"
>
  <div>
    <div *ngIf="isCardWithSpiral()" class="img-container mb-2 mt-2">
      <img class="pulse-animation-home" [src]="cardHeartPath" width="49" alt="heart" />
    </div>
    <div *ngIf="!isCardWithSpiral()" class="img-container without-icon my-0">
      <img [src]="cardSpiralPath" alt="spiral" class="card-img" />
    </div>
    <p class="spiral-card-text mx-0 text-center" [innerHTML]="cardText"></p>
    <div *ngIf="isNew && !isCardWithSpiral()" class="new">Nuevo</div>

    <div *ngIf="isCardWithSpiral() && labelCreditHealth == 'NUEVO'" class="new">Nuevo</div>
    <div *ngIf="isCardWithSpiral() && labelCreditHealth == 'ACTUALIZADO'" class="update-creditHealth">Actualizado</div>
    <div
      *ngIf="isCardWithSpiral() && labelCreditHealth != 'ACTUALIZADO' && labelCreditHealth != 'NUEVO'"
      class="credit-health-title"
    >
      <img src="assets/img/credit-health/grey-clock.svg" alt="oclock" class="credit-health-clock" />
      {{ labelCreditHealth }}
    </div>
  </div>
  <div class="img-container mb-2 mt-1" *ngIf="type === 'negative'"></div>
</div>

<ng-template #noimages>
  <div class="card spiral-card" [ngClass]="type">
    <div class="img-container only-images mb-2 mt-2">
      <img class="img-heart_logo" width="49" [src]="cardHeartPath" alt="heart" />
      <img class="img-heart" width="49" src="/assets/img/progress-spiral/heart_background.png" alt="heart" />
    </div>
  </div>
</ng-template>
