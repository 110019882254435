import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { headerLinks,headerLinksLogin,menuData } from '@core/data/dashboard/menu.json';
import { HeaderService } from '@core/services/header.service';
import { take } from 'rxjs/operators';
import { MenuDashboard } from 'src/app/core/interfaces';
import { UserProfile } from 'src/app/core/models/UserProfileModel';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'yevo-new-intranet',
  templateUrl: './new-intranet.component.html',
  styleUrls: ['./new-intranet.component.scss'],
})
export class NewIntranetComponent {
  readonly user$ = this.authService.userProfile$;
  user!: UserProfile;
  currentRoute: string;
  routerLinks: any = {
    default: headerLinks,
    logged: headerLinksLogin,
  };
  loadding=false;
  isMenuMobileExpande: boolean = false;
  menu: MenuDashboard[] = menuData;
  newDashoard: boolean = true;
  isMobileMenuOpen: boolean = false;
  isUserLogged: boolean = false;
  headerLinks = headerLinks;
  constructor(
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    private headerService: HeaderService
  ) {
    this.currentRoute = 'Demo';
    this.headerService.isMobileMenuOpen$.subscribe((isOpen) => {
      this.isMobileMenuOpen = isOpen;
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
        this.user = user!;
      });
    }

    this.authService.isLogged$.subscribe((logged) => {
      this.isUserLogged = logged;
      this.headerLinks = this.getHeaderLinks(logged);
    });
    setTimeout(() => {
     this.loadding=true;
    },500);
  }

  getHeaderLinks(isLogged: boolean) {
    if (isLogged) {
      return this.routerLinks.logged;
    }
    return this.routerLinks.default;
  }

  mobileMenuIsExpanded(event: any) {
    if (event) {
      this.isMenuMobileExpande = true;
    } else {
      this.isMenuMobileExpande = false;
    }
  }
}
