<div class="event_card" *ngIf="event">
    <div class="info_hour_title">
        <div class="hour">
            <img src="assets/icons/communities/event_icon.svg" alt="event_icon">
            {{eventHour}} hrs.
        </div>
        <div class="title">{{eventTitle}}</div>
    </div>
    <div class="info_date_btn">
        <div class="date">
            <div class="day">
                {{eventDay}}
            </div>
            <div class="month">
                {{eventMonth}}
            </div>
        </div>
        <div class="btn_container">
            <button (click)="seeMoreEvent()">
                Ver más
            </button>
        </div>
    </div>
</div>
<ng-template #modalContent let-close="close" class="event_card_modal">
  <div class="modal-body hidden">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 calendar-list bg-menu">
          <p class="calendar-list modal-title">{{ eventTitle }}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="modal-fecha mt-3 mb-3">
            <img class="modal-fecha-icon" src="/assets/icons/horario.svg" alt="horario" />
            {{ eventDate | date: 'MM/dd/yy hh:mm a' }}
          </p>

          <p class="modal-description">
            {{ eventDescription }}
          </p>

          <p class="modal-description">
            {{ eventOrganizer }}
          </p>
        </div>
        <div class="col-md-12 text-right mb-3 mt-3">
          <button
            type="button"
            class="btn btn-outline-primary button-modal mr-3"
            (click)="closeModal()"
          >
            Cerrar
          </button>
          <button *ngIf="!event?.onSite" target="_blank" class="btn btn-primary button-modal" (click)="goEvent()">
            Ir a evento
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
