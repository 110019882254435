import { Component, Input, SimpleChanges } from '@angular/core';
import { ARTICLE_BADGE_INDEX, BADGE_THEMES, BADGE_THEMES_DASHBOARD } from '@shared/constants/course.constants';
import { BadgeTheme } from 'src/app/core/interfaces/frontend';

@Component({
  selector: 'yevo-training-badge',
  templateUrl: './training-badge.component.html',
  styleUrls: ['./training-badge.component.scss'],
})
export class TrainingBadgeComponent {
  @Input() type: string = '';
  @Input() top: boolean = false;
  @Input() label: string = '';
  @Input() dashboard: boolean = false;

  badgeThemeSelected!: BadgeTheme;

  badge = this.dashboard ? BADGE_THEMES_DASHBOARD : BADGE_THEMES;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue) {
      this.badgeThemeSelected =
        this.badge.find((x) => x.code === changes.type?.currentValue)! || this.badge[ARTICLE_BADGE_INDEX];
    }
    if (this.label) {
      this.badgeThemeSelected.label = this.label;
    }
  }
}
