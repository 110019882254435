import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataLayerService } from '@core/services/data-layer.service';

@Component({
  selector: 'yevo-modal-register',
  templateUrl: './modal-register.component.html',
  styleUrls: ['./modal-register.component.scss'],
})
export class ModalRegisterComponent {
  constructor(private modalService: NgbModal, private router: Router, private dataLayerService: DataLayerService) {}

  closeModal() {
    this.modalService.dismissAll();
  }

  registerEvent() {
    this.dataLayerService.selectedDiscoverModalButton('¡Descubre esta funcionalidad!', 'Quiero registrarme');
    this.router.navigate(['/registro'], { queryParamsHandling: 'merge' });
    this.modalService.dismissAll();
  }

  login() {
    this.dataLayerService.selectedDiscoverModalButton('¡Descubre esta funcionalidad!', 'Iniciar sesión');
    this.router.navigate(['/ingresar'], { queryParamsHandling: 'merge' });
    this.modalService.dismissAll();
  }
}
