import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = new BehaviorSubject(false);

  private _isLoadingLazyPage$ = new BehaviorSubject(false);
  public isLoadingLazyPage = this._isLoadingLazyPage$.asObservable();

  public showLoading$ = combineLatest([this.isLoading, this.isLoadingLazyPage]).pipe(
    map((loading) => loading.reduce((acc, load) => acc || load, false))
  );

  constructor() {
    //DO NOTHING
  }

  setIsLoadingLazyPage(loading: boolean) {
    this._isLoadingLazyPage$.next(loading);
  }
}
