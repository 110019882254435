export enum ParamKeyValue {
  SortOrder = 'sortOrder',
  Categories = 'categories',
  Geolocation = 'geolocation',
  BusinessName = 'businessName',
  VirtualStore = 'virtualStore',
  Page = 'page',
}

export enum TypeSearch {
  DIRECTORY = 'dir',
  CATEGORY = 'cat',
  SEARCH = 'search',
}
