import { Injectable } from '@angular/core';
import departmentsJSON from '@core/data/geolocation/departments.json';
import provincesJSON from '@core/data/geolocation/provinces.json';
import districtsJSON from '@core/data/geolocation/districts.json';

import { IDepartment } from 'src/app/core/interfaces/IDepartment';
import { IProvince } from 'src/app/core/interfaces/IProvince';
import { IDistrict } from 'src/app/core/interfaces/IDistrict';

import { IDropdownItem } from 'src/app/core/interfaces/IDropdownItem';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private departmentsSource: IDepartment[] = departmentsJSON;
  private provincesSource: IProvince[] = provincesJSON;
  private districtsSource: IDistrict[] = districtsJSON;
  private currentPosition!: google.maps.Marker;

  getCurrentPosition(): google.maps.Marker {
    return this.currentPosition;
  }

  setCurrentPosition(position: google.maps.Marker) {
    this.currentPosition = position;
  }

  getDepartmentsAsDropdownItems(): IDropdownItem[] {
    return this.departmentsSource.map((department) => this.parseDropdownItem(department));
  }

  getDepartmentByIdAsDropdownItem(term: string, evaluateName: boolean = false): IDropdownItem {
    const departmentFound = this.departmentsSource.find((department) =>
      evaluateName ? department.name === term : department.id === term
    );
    return this.parseDropdownItem(departmentFound!);
  }

  getProvincesAsDropdownItems(departmentId: string): IDropdownItem[] {
    return this.provincesSource
      .filter((province) => province.department_id === departmentId)
      .map((province) => this.parseDropdownItem(province));
  }

  getProvinceByIdAsDropdownItem(term: string, evaluateName: boolean = false): IDropdownItem {
    const provinceFound = this.provincesSource.find((province) =>
      evaluateName ? province.name === term : province.id === term
    );
    return this.parseDropdownItem(provinceFound!);
  }

  getDistrictsAsDropdownItems(provinceId: string): IDropdownItem[] {
    return this.districtsSource
      .filter((district) => district.province_id === provinceId)
      .map((district) => this.parseDropdownItem(district));
  }

  getDistrictByIdAsDropdownItem(term: string, evaluateName: boolean = false): IDropdownItem {
    const districtFound = this.districtsSource.find((district) =>
      evaluateName ? district.name === term : district.id === term
    );
    return this.parseDropdownItem(districtFound!);
  }

  parseGeolocationToArray(geolocation?: string): IDropdownItem[] {
    if (!geolocation) {
      return [];
    }
    // Geolocation formatting
    geolocation = geolocation.substr(2, 6);
    const geolocationFormatted = [];
    const departmentCode = geolocation.substr(0, 2);
    if (departmentCode && departmentCode.length === 2) {
      const department = this.getDepartmentByIdAsDropdownItem(departmentCode);
      geolocationFormatted.push(department);
    }
    // Province formatting
    const provinceCode = geolocation.substr(0, 4);
    if (provinceCode && provinceCode.length === 4) {
      const province = this.getProvinceByIdAsDropdownItem(provinceCode);
      geolocationFormatted.push(province);
    }
    // District formatting
    const districtCode = geolocation.substr(0, 6);
    if (districtCode && districtCode.length === 6) {
      const district = this.getDistrictByIdAsDropdownItem(districtCode);
      geolocationFormatted.push(district);
    }
    return geolocationFormatted;
  }

  parseDropdownItem(valueToParse: IDepartment | IProvince | IDistrict): IDropdownItem {
    return {
      label: valueToParse.name,
      value: valueToParse.id,
    };
  }
}
