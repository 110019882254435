import footerData from '@core/data/footer.data.json';
import { RouterLinkModel, SocialNetworkModel } from 'src/app/core/models';

export const footerFirstColumnRoutes: RouterLinkModel[] = footerData.footerRoutes
.filter((footerRoute) => footerRoute.column === 1)
.sort((prev, curr) => prev.order - curr.order);

export const footerSecondColumnRoutes: RouterLinkModel[] = footerData.footerRoutes
.filter((footerRoute) => footerRoute.column === 2)
.sort((prev, curr) => prev.order - curr.order);

export const footerThirdColumnRoutes: RouterLinkModel[] = footerData.footerRoutes
.filter((footerRoute) => footerRoute.column === 3)
.sort((prev, curr) => prev.order - curr.order);

export const footerFourColumnRoutes: RouterLinkModel[] = footerData.footerRoutes
.filter((footerRoute) => footerRoute.column === 4)
.sort((prev, curr) => prev.order - curr.order);

export const footerFiveColumnRoutes: RouterLinkModel[] = footerData.footerRoutes
.filter((footerRoute) => footerRoute.column === 5)
.sort((prev, curr) => prev.order - curr.order);

export const footerFirstColumnRoutesLogin: RouterLinkModel[] = footerData.footerRoutesLogin
.filter((footerRoute) => footerRoute.column === 1)
.sort((prev, curr) => prev.order - curr.order);

export const footerSecondColumnRoutesLogin: RouterLinkModel[] = footerData.footerRoutesLogin
.filter((footerRoute) => footerRoute.column === 2)
.sort((prev, curr) => prev.order - curr.order);

export const footerThirdColumnRoutesLogin: RouterLinkModel[] = footerData.footerRoutesLogin
.filter((footerRoute) => footerRoute.column === 3)
.sort((prev, curr) => prev.order - curr.order);

export const footerOneColumnRoutesLoginMobile: RouterLinkModel[] = footerData.footerRoutesLoginMobile
.filter((footerRoute) => footerRoute.column === 1)
.sort((prev, curr) => prev.order - curr.order);

export const footerTwoColumnRoutesLoginMobile: RouterLinkModel[] = footerData.footerRoutesLoginMobile
.filter((footerRoute) => footerRoute.column === 2)
.sort((prev, curr) => prev.order - curr.order);

export const footerThirdColumnRoutesLoginMobile: RouterLinkModel[] = footerData.footerRoutesLoginMobile
.filter((footerRoute) => footerRoute.column === 3)
.sort((prev, curr) => prev.order - curr.order);

export const footerOneColumnRoutesMobile: RouterLinkModel[] = footerData.footerRoutesMobile
.filter((footerRoute) => footerRoute.column === 1)
.sort((prev, curr) => prev.order - curr.order);

export const footerTwoColumnRoutesMobile: RouterLinkModel[] = footerData.footerRoutesMobile
.filter((footerRoute) => footerRoute.column === 2)
.sort((prev, curr) => prev.order - curr.order);

export const footerThirdColumnRoutesMobile: RouterLinkModel[] = footerData.footerRoutesMobile
.filter((footerRoute) => footerRoute.column === 3)
.sort((prev, curr) => prev.order - curr.order);

export const footerFourColumnRoutesLogin: RouterLinkModel[] = footerData.footerRoutesLogin
.filter((footerRoute) => footerRoute.column === 4)
.sort((prev, curr) => prev.order - curr.order);

export const footerFiveColumnRoutesLogin: RouterLinkModel[] = footerData.footerRoutesLogin
.filter((footerRoute) => footerRoute.column === 5)
.sort((prev, curr) => prev.order - curr.order);

export const socialNetworks: SocialNetworkModel[] = footerData.socialNetworks.sort(
(prev, curr) => prev.order - curr.order
);