<div class="certificate-section">

  <h1>Certifícate con nosotros</h1>
  <p class="d-md-block d-none">Conoce los cursos que tenemos para ti y certifícate con el respaldo de Mibanco.</p>
  <p class="d-md-none d-block">Conoce los cursos que tenemos para ti y certifícate con el <span>respaldo de Mibanco.</span></p>

  <academy-carouse-course cardsType="course"></academy-carouse-course>

  <div class="view-more">
    <button (click)="openRegisterModal()" class="d-md-block d-none">Ver todos los cursos</button>
    <button (click)="openRegisterModal()" class="button-mobile d-md-none d-block">Ver más cursos</button>
  </div>
</div>
