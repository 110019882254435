import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserCommunity } from '@core/interfaces/frontend/communities';
import { SolutionsNavModel } from '@core/models';
import { UserProfile } from '@core/models/UserProfileModel';
import { AuthService } from '@core/services/auth.service';
import { CommunityService } from '@core/services/community.service';
import { CourseService } from '@core/services/course.service';
import { DashboardService } from '@core/services/dashboard.service';
import { DataLayerService } from '@core/services/data-layer.service';
import { HeaderService } from '@core/services/header.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { MailchimpTransactionalService } from '@core/services/mailchimp-transactional.service';
import { NgbDropdownConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';
import { NOTIFICATION } from '@shared/constants/dashboard.constants';
import { isSafariBrowser } from '@shared/utils/validators.utils';
import { filter, map } from 'rxjs/operators';
import { SectionNames } from 'src/app/new-intranet/views/affiliate/affiliate.component';

@Component({
  selector: 'yevo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() links: any = [];
  @Input() fluid: boolean = false;
  @Input() showLogoMobile: boolean = true;
  @Input() showLogoDesktop: boolean = true;
  @Input() mobileButtonRight: boolean = true;
  @Input() shadow: boolean = true;
  @Input() newDashoard: boolean = false;

  @Output() mobileMenuIsExpanded = new EventEmitter();

  markingCategory = '';

  selectCommunity = '';
  titleButton = '';
  imgCard = '';
  titleCard = '';
  code = '';
  COMMUNITY_ID = 0;
  user!: UserProfile;
  userCommunity: UserCommunity = {
    name: '',
    userId: 0,
    cellPhone: '',
    email: '',
    communityId: 0,
  };

  showServices = false;
  showFaqs = false;
  isLOgged = false;
  initaliced = false;
  isBrowser: boolean;
  userName$ = this.authService.userProfile$.pipe(map((user) => user?.fullName ?? 'Invitado'));
  navigate: string = '/';

  hover: boolean = false;
  hoverUser: boolean = false;
  selectedItem!: any;
  home: boolean = false;
  isPageDashboard: boolean = false;
  isPageTraining: boolean = false;
  isPageTrainingQA: boolean = false;
  loader: boolean = false;
  loaderDisabled = true;
  titleMenu: string = '';
  openModalSeidor: boolean = false;
  testNewLogo = false;
  isDashboard: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };
  userProfile!: any;
  parentRouteCommunity = '';
  topic = 'defaul';
  showNotification = false;
  notificationUrlSection = '';
  notificationData!: any;
  activerBackOutRight: boolean = false;
  maxTitleLength: number = NOTIFICATION.maxTitleLength;
  maxDescriptionLength: number = NOTIFICATION.maxDescriptionLength;
  mainMenu!: any;
  isDisabledNotification: boolean = false;
  isCommunityPage: boolean = false;
  userId!: number;
  userclient!: UserProfile;

  constructor(
    public authService: AuthService,
    public dataLayerService: DataLayerService,
    private dashboardService: DashboardService,
    private localStorageService: LocalStorageService,
    private el: ElementRef,
    private router: Router,
    private renderer2: Renderer2,
    dropdownConfig: NgbDropdownConfig,
    private communityService: CommunityService,
    private mailchimpTransactionalService: MailchimpTransactionalService,
    private modalService: NgbModal,
    private courseService: CourseService,
    private headerService: HeaderService,
    @Inject(PLATFORM_ID) platformId: Parameters<typeof isPlatformBrowser>[0],
    @Inject(DOCUMENT) private _document: any
  ) {
    dropdownConfig.autoClose = false;
    this.isBrowser = isPlatformBrowser(platformId);
    this.mainMenu = sessionStorage.getItem('activePageMobile')
      ? parseInt(sessionStorage.getItem('activePageMobile')!)
      : -1;
    this.isUserLoggedActive();
  }

  responsiveMenuClosed = true;
  settingNavExpanded = false;
  userMenuClosed = true;
  dashboard: boolean = false;
  trainingActive: boolean = false;

  isLogged$ = this.authService.isLogged$;

  subscription$ = this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe(() => this._close());

  public subscriber!: any;
  ngOnInit(): void {
    this.isTrainingPageActive();
    this.subscriber = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url.includes('capacitaciones')) {
          this.isPageTraining = true;
        }
        this.isActiveMenuMobile(parseInt(sessionStorage.getItem('activePageMobile') ?? '-1'));
      });

    this._close();
    this.navigateLogin();
    this.validateUrl();
    this.validateActiveUrlPage();
  }

  isUserLoggedActive() {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLOgged = true;
      } else {
        this.authService.removeSavedValues();
      }
    });
  }

  isTrainingPageActive() {
    if (window.location.pathname.includes('capacitaciones')) {
      this.isPageTraining = true;
    }
  }

  reviewArrow() {
    const activePage = parseInt(sessionStorage.getItem('activePage') ?? '9');
    if (activePage !== 9) {
      this.hiddenArrowMenu(activePage);
    } else {
      this.hiddenArrowMenu(-1);
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  closeSession(): void {
    this.localStorageService.remove('refresh_token');
    this.authService.logout();
    this.dataLayerService.categoryNewHome('Academia del Progreso', 'Cerrar sesión');
    this.dataLayerService.pushUserSession(null, null);
    this.localStorageService.bulkRemove(Object.values(SectionNames));
    sessionStorage.clear();
    this.localStorageService.remove('reloaded');
    this.localStorageService.remove('activePageUrl');
    sessionStorage.removeItem('activePageUrl');
    if (sessionStorage.getItem('intranetPath')) {
      const dataUrl = JSON.parse(sessionStorage.getItem('intranetPath')!);
      if (dataUrl[dataUrl.length - 2].includes('nuevo-intranet')) {
        this.authService.logout();
      } else {
        this.authService.logout('ingresar');
      }
    }
    sessionStorage.removeItem('intranetPath');
  }

  login(event: any): void {
    this.dataLayerService.pushHomeLoginEvent('Header', 'Ingresar a mi cuenta');
  }

  register(event: any): void {
    this.dataLayerService.pushHomeRegisterEvent('Menú', 'Registrate gratis');
  }

  trackByIndex(index: number, item: SolutionsNavModel): any {
    return index;
  }

  isInFinancingRoute(data: any) {
    return data.url === '/nuevo-intranet/financiamiento';
  }

  handleLinkClick($event: any) {
    this.titleMenu = $event?.target?.innerHTML;
  }

  handleLinkDefault($event: any, link: any) {
    this.titleMenu = $event?.target?.innerHTML;
    if (link.routerLink.length>0) {
      this.router.navigate(link.routerLink, { queryParamsHandling: 'merge' });
    } else {
      this.openModalRegister();
    }
  }

  handleLinkLogin($event: any, item: any) {
    console.log('Login', item)
    this.titleMenu = $event?.target?.innerHTML;
    if (item?.resources) {
      this.router.navigate(item.routerLink);
      this.headerService.setRedirectToDownloadFiles(true);
      return;
    }

    this.router.navigate(item.routerLink);
    window.scrollTo(0, 0);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this._close();
    }
  }

  public _close() {
    if (this.settingNavExpanded) this.settingNavExpanded = false;
    if (!this.userMenuClosed) this.userMenuClosed = true;
    if (!this.responsiveMenuClosed) this.responsiveMenuClosed = true;
    this.closeResponsiveMenu();
  }

  closeResponsiveMenu() {
    if (!this.responsiveMenuClosed) {
      this.mobileMenuIsExpanded.emit(true);
    } else {
      this.mobileMenuIsExpanded.emit(false);
    }
  }

  onHover(link: any) {
    if (link.title === 'Capacitaciones') {
      const training: any = this._document.getElementById('header-square-course2');
      training.style.background = '#FFF';
    }

    if (link.user) {
      this.hoverUser = true;
      return;
    }

    if (link.expanded && !link.expandedDeactivate) {
      this.hover = true;

      if (this.isPageTraining) {
        const element = this._document.getElementById('header-square-course1');
        this.renderer2.removeClass(element, 'navbar-logged__active_training');
      }
    } else {
      this.hover = false;
      this.hoverUser = false;
    }
  }

  onHoverActive(link: any, index: number) {
    this.isPageTraining = false;
    this.isPageTrainingQA = false;
    this.onHoverInTraining(index);

    const element = this._document.getElementById('header-square-course' + index);
    this.renderer2.addClass(element, 'd-block');

    this.hiddenArrowMenu(index);

    if (!link.expanded) {
      this.hover = false;
      this.hoverUser = false;
      if (this.trainingActive) {
        const element = this._document.getElementById('header-square-course' + index);
        this.renderer2.addClass(element, 'd-block');
        this.renderer2.addClass(element, 'navbar-logged__active_training');
      } else {
        const element = this._document.getElementById('header-square-course' + index);
        this.renderer2.addClass(element, 'd-block');
      }
    } else {
      const element = this._document.getElementById('header-square-course' + index);
      this.renderer2.addClass(element, 'd-block');
    }
  }

  onHoverInTraining(index: number) {
    if (this.trainingActive && index === 0) {
      this.isPageTraining = true;
    } else if ((this.trainingActive && index === 2) || index === 3) {
      this.isPageTrainingQA = true;
    }
  }

  onLeaveHoverActive(link: any, index: number) {
    if (link.expanded) {
      this.hoverUser = false;
    } else {
      this.hover = false;
      this.hoverUser = false;
      const activePage = sessionStorage.getItem('activePage') ?? -1;
      if (index != activePage) {
        const element = this._document.getElementById('header-square-course' + index);
        this.renderer2.removeClass(element, 'd-block');
      }
    }
  }

  closedDesktopMenu() {
    this.hover = false;
    this.isActiveTrainingPageMenu();
  }

  hiddenArrowMenu(selected: any) {
    const element = this._document.getElementById('header-square-course' + selected);
    this.renderer2.addClass(element, 'd-block');

    for (let index = 0; index < 6; index++) {
      if (selected != index) {
        const element = this._document.getElementById('header-square-course' + index);
        this.renderer2.removeClass(element, 'd-block');
      }
    }
  }

  navigateLogin() {
    if (this.isLOgged) {
      this.navigate = '/nuevo-intranet/dashboard';
    } else {
      this.navigate = '/';
    }
  }

  over(link: any) {
    this.hoverUser = false;
    this.hover = true;
    this.selectedItem = link;
  }

  overUser() {
    this.hover = false;
    if (!this.hoverUser) {
      this.hoverUser = true;
    }
    const element: any = document.querySelector('.header-square-dashboard-course5');
    element?.classList.add('d-block');
  }

  isHover() {
    if (!this.hoverUser) {
      this.hoverUser = true;
    }
    document.querySelector('.header-square-dashboard-course5')?.classList.add('d-block');
  }

  closedDesktopMenuUser() {
    this.hoverUser = false;
    this.hover = false;
    this.leaveMenuUserLogged();
  }

  validateUrl() {
    let pathname = window.location.pathname.split('/')[2];
    this.isPageDashboard = false;
    this.trainingActive = false;

    if (
      pathname === 'dashboard' ||
      pathname === 'dashboard-new' ||
      pathname === 'perfil' ||
      pathname === 'afiliacion'
    ) {
      this.dashboard = true;
      if (pathname === 'dashboard') {
        this.activateHome('/nuevo-intranet/dashboard');
        this.isPageDashboard = true;
      }
    } else if (pathname === 'capacitaciones') {
      this.activateTrainingPage();
    }
  }

  validateActiveUrlPage() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.localStorageService.set('activePageUrl', event.url);
        if (sessionStorage.getItem('intranetPath') && this.isLOgged) {
          const dataUrl = JSON.parse(sessionStorage.getItem('intranetPath')!);
          if (dataUrl[dataUrl.length - 1] !== event.url) {
            dataUrl.push(event.url);
          }
          sessionStorage.setItem('intranetPath', JSON.stringify(dataUrl));
        } else {
          sessionStorage.setItem('intranetPath', JSON.stringify([]));
        }
        sessionStorage.setItem('activePageUrl', event.url);
        this.activateHome(event.url);
      }
    });
  }

  activateHome(page: string) {
    page = page.split('?')[0];
    if (page === '/' || page === '/nuevo-intranet/dashboard' || page === '/nuevo-intranet/perfil/mi-perfil') {
      this.isDashboard = true;
      if (this.localStorageService.get('activeCalendar') != 'true') {
        this.localStorageService.set('activePageUrlSubmenu', '-1');
      }
      setTimeout(() => {
        this.removeToggleSettingNav();
      }, 400);
    } else {
      this.isDashboard = false;
    }
  }


  activePageMenu(item: number) {
    this.removeToggleSettingNav();
    this.toggleSettingNav(item);
  }

  toggleSettingNav(id: number) {
    setTimeout(() => {
      const test: any = this.isLOgged
        ? this._document.querySelector('.isLogged.header-square-dashboard-course' + id)
        : this._document.querySelector('.isNotLogged.header-square-course' + id);

      test.classList.add('d-block');
    }, 500);
  }

  removeToggleSettingNav() {
    const number = this.isLOgged ? 5 : 4;
    for (let index = 0; index < number; index++) {
      const test: any = this.isLOgged
        ? this._document.querySelector('.isLogged.header-square-dashboard-course' + index)
        : this._document.querySelector('.isNotLogged.header-square-course' + index);
      test.classList.remove('d-block');
      test.classList.remove('navbar-logged__active_training');
    }
  }


  menuStyles(i: number) {
    if (this.isPageDashboard) {
      return 'isLogged navbar-logged header-square-dashboard-course' + i;
    } else {
      return 'isLogged header-square-dashboard-course' + i;
    }
  }
  menuStylesTraining(i: number) {
    if (this.trainingActive && i === 0) {
      return 'd-block navbar-logged__active_training  isLogged header-square-dashboard-course' + i;
    } else {
      return 'd-none';
    }
  }

  menuActiveStyles(i: number) {
    if (sessionStorage.getItem('activePage') === i.toString()) {
      return 'nav-item-desktop active nav-item header-item-link-selected' + i;
    } else {
      return 'nav-item-desktop nav-item header-item-link-selected' + i;
    }
  }

  activateTrainingPage() {
    setTimeout(() => {
      this.trainingActive = true;
    }, 100);
  }

  closedDesktopMenuTop(event: any) {
    if (event.clientY < 70) {
      this.closedDesktopMenuUser();
      this.closedDesktopMenu();
    }
  }

  validateUserCommunity(title: string, code: string): void {
    this.code = code;
    this.loadCommunity(this.code);
  }

  private loadCommunity(code: string): void {
    this.communityService.getCommunityByCode(code).subscribe(
      (response) => {
        this.COMMUNITY_ID = response.id;
        this.searchUser();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  navigateCommunities(url: string) {
    if (isSafariBrowser()) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  }

  private searchUser(): void {
    if (!this.user) {
      const userString = this.localStorageService.get('userProfile');
      if (userString && this.COMMUNITY_ID !== 0) {
        this.user = JSON.parse(userString) as UserProfile;
      }
    }
  }

  notificationForeground() {
    this.showNotification = false;
    this.notificationUrlSection = this.notificationData.data.url;
    this.redirectToPage(this.notificationUrlSection);
  }

  truncateText(text: string, maxLength: number): string {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }

  hiddenNotification() {
    this.activerBackOutRight = true;
    setTimeout(() => {
      this.showNotification = false;
    }, 1000);
  }

  redirectToPage(urlSection: string): void {
    const segments = urlSection.split('/');
    const routeDynamic = [''].concat(segments.filter((segment) => segment.trim() !== ''));
    this.router.navigate(routeDynamic);
  }

  goToSimulator(link: any) {
    if (link?.isSimulator && this.isLOgged) {
      this.mailchimpTransactionalService.postSimulatorEmail().subscribe();
    }
  }

  goToLogin() {
    this.dataLayerService.categoryNewHome('Botones principales', 'Iniciar sesión');
    setTimeout(() => {
      this.router.navigate(['/ingresar']);
    }, 300);
  }

  goToRegister() {
    this.dataLayerService.categoryNewHome('Botones principales', 'Regístrate gratis');
    setTimeout(() => {
      this.router.navigate(['/registro']);
    }, 300);
  }

  goToHome() {
    this.dataLayerService.categoryNewHome('Sección principal', 'Ir al Inicio');
    if (this.isLOgged) {
      this.router.navigate(['/nuevo-intranet', 'dashboard']);
    } else {
      this.router.navigate(['/']);
    }
  }

  openmodalRegister() {
    this.modalService.open(ModalRegisterComponent, this._modalOptionsNormal);
  }

  mouseEnterMenu(link: any, i: number) {
    if (!link.user) {
      this.onLeaveHoverActive(link, i);
      this.onHoverActive(link, i);
    }
  }

  leaveMenuClose() {
    this.hover = false;
    this.hoverUser = false;
    this.removeToggleSettingNav();
  }

  mouseEnterUserMenu() {
    const menu: any = this.isLOgged
      ? this._document.querySelector('.isLogged.header-square-dashboard-course3')
      : this._document.querySelector('.isNotLogged.header-square-course3');
    menu.classList.remove('d-block');
    document.getElementById('header-square-course4')?.classList.add('d-block');
  }

  leaveMenuUserLogged() {
    this.hover = false;
    this.removeToggleSettingNav();
    const item = parseInt(sessionStorage.getItem('activePage') ?? '-1');
    this.toggleSettingNav(item);

    setTimeout(() => {
      document.getElementById('header-square-course4')?.classList.remove('d-block');
    }, 100);
  }

  onLink(number: number, link: string[], params: any = {}, option: any = '') {
    const pathname = window.location.pathname.split('/')[2];

    this.localStorageService.set('activePageUrlSubmenu', number.toString());

    if (number === 6) {
      this.localStorageService.set('activeCalendar', 'true');
      setTimeout(() => {
        this.dashboardService.changeCalendar(true);
        this.router.navigate(link, { queryParamsHandling: 'merge' });
      }, 300);
      return;
    }
    if (Object.keys(params).length) {
      this.router.navigate(link, { queryParams: params, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(link, { queryParamsHandling: 'merge' });
    }

  }

  isActiveMenuMobile(id: any) {
    if (id !== 1 && id !== 21) {
      this.mainMenu = id;
      sessionStorage.setItem('activePageMobile', `${id}`);
    } else {
      this.mainMenu = -1;
      sessionStorage.setItem('activePageMobile', `-1`);
    }
  }

  isActiveDesktopMenu(value: any) {
    this.isActiveDesktopMenu(value);
  }

  activeMarking(data: any) {
    if (data?.isLink) {
      this.dataLayerService.categoryNewHome('Mi Yevo', data?.title);
    }
  }

  isActiveTrainingPageMenu() {
    if (window.location.pathname === '/capacitaciones') {
      const training: any = this._document.getElementById('header-square-course2');
      training.style.background = '#151d18';
    }

    this.isActiveArrowMenu();
  }

  isActiveMenu(page: string) {
    if (page !== '/capacitaciones') {
      const training: any = this._document.getElementById('header-square-course2');
      training.style.background = '#FFF';
    } else if (page === '/capacitaciones') {
      const training: any = this._document.getElementById('header-square-course2');
      training.style.background = '#151d18';
    }
  }

  isActiveArrowMenu() {
    const activePage = parseInt(sessionStorage.getItem('activePage') ?? '9');
    this.leaveMenuClose();

    if (activePage !== 9) {
      this.hiddenArrowMenu(activePage);
    } else {
      this.hiddenArrowMenu(-1);
    }
  }

  loadingUser(): number {
    const user = JSON.parse(this.localStorageService.get('userProfile') ?? '{}') as UserProfile;
    return user?.userId || 0;
  }

  onLinkExternal(url: string): void {
    window.open(url, '_blank');
  }

  goToUrl(link: any): void {
    if (link.isExternal) {
      this.onLinkExternal(link.routerLink);
      return;
    }
    this.router.navigate(link.routerLink, { queryParamsHandling: 'merge' });
  }

  openModalRegister() {
    this.modalService.open(ModalRegisterComponent, this._modalOptionsNormal);
  }
}