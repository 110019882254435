<div class="geolocation-container">
  <div class="dropdown-container">
    <label *ngIf="showLabels" [ngClass]="labelStyle ? 'label' : ''"
      >{{ labels.department }}<span *ngIf="obligatory">*</span></label
    >
    <yevo-dropdown
      #departmentDropdown
      id="department"
      [items]="departments"
      [itemSelected]="departmentSelected"
      [buttonLabel]="'Departamento'"
      [disabled]="allDisabled"
      [showItemsOnMobile]="showItemsOnMobileVersion"
      [menuHeightOnMobile]="dropdownsConfig[0]?.menuHeightOnMobile"
      [containerTitle]="dropdownsConfig[0]?.containerTitle"
      [scrollActivated]="true"
      [canRemoveActiveItem]="canRemoveActive"
      (clickDropdown)="handleDepartmentDropdownClick()"
      (selectItem)="setDepartment($event, true)"
      [customClass]="showDepartmentError ? 'error' : ''"
    ></yevo-dropdown>
    <label *ngIf="showDepartmentError" class="form-inline">
      <div class="text-error">Departamento es requerido.</div>
    </label>
  </div>
  <div class="dropdown-container">
    <label *ngIf="showLabels" [ngClass]="labelStyle ? 'label mt-3' : ''"
      >{{ labels.province }}<span *ngIf="obligatory">*</span></label
    >
    <yevo-dropdown
      #provinceDropdown
      id="province"
      [items]="provinces"
      [itemSelected]="provinceSelected"
      [buttonLabel]="'Provincia'"
      [disabled]="allDisabled"
      [showItemsOnMobile]="showItemsOnMobileVersion"
      [menuHeightOnMobile]="dropdownsConfig[1]?.menuHeightOnMobile"
      [containerTitle]="dropdownsConfig[1]?.containerTitle"
      [scrollActivated]="true"
      [canRemoveActiveItem]="canRemoveActive"
      (clickDropdown)="handleProvinceDropdownClick()"
      (selectItem)="setProvince($event, true)"
      [customClass]="showProvinceError ? 'error' : ''"
    ></yevo-dropdown>
    <label *ngIf="showProvinceError" class="form-inline">
      <div class="text-error">Provincia es requerida.</div>
    </label>
  </div>
  <div class="dropdown-container">
    <label *ngIf="showLabels" [ngClass]="labelStyle ? 'label mt-4' : ''"
      >{{ labels.district }}<span *ngIf="obligatory">*</span></label
    >
    <yevo-dropdown
      #districtDropdown
      id="district"
      [items]="districts"
      [itemSelected]="districtSelected"
      [buttonLabel]="'Distrito'"
      [disabled]="allDisabled"
      [showItemsOnMobile]="showItemsOnMobileVersion"
      [menuHeightOnMobile]="dropdownsConfig[2]?.menuHeightOnMobile"
      [containerTitle]="dropdownsConfig[2]?.containerTitle"
      [scrollActivated]="true"
      [canRemoveActiveItem]="canRemoveActive"
      (clickDropdown)="handleDistrictDropdownClick()"
      (selectItem)="setDistrict($event, true)"
      [customClass]="showDistrictError ? 'error' : ''"
    ></yevo-dropdown>
    <label *ngIf="showDistrictError" class="form-inline">
      <div class="text-error">Distrito es requerido.</div>
    </label>
  </div>
</div>
