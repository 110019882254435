import { Component, EventEmitter, Input, Output } from '@angular/core';

export type buttonTypes = 'button' | 'submit';
export type buttonClassNames = 'primary' | 'light' | 'green' | 'danger' | 'link' | 'outline' | 'custom' | 'green-modal';

@Component({
  selector: 'yevo-button-animated',
  templateUrl: './button-animated.component.html',
  styleUrls: ['./button-animated.component.scss'],
})
export class ButtonAnimatedComponent {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() type: buttonTypes = 'button';
  @Input() style: buttonClassNames = 'primary';
  @Input() styleCustomName: string = '';
  @Input() icon: string = '';
  @Output() buttonClick = new EventEmitter();

  handleClick($event: Event) {
    this.buttonClick.emit($event);
  }

  getButtonClass(): string {
    if (this.style === 'primary') return 'primary-button';
    if (this.style === 'outline') return 'outline-button';
    if (this.style === 'green') return 'green-button';
    if (this.style === 'green-modal') return 'green-button-modal';
    if (this.style === 'light' || this.style === 'danger' || this.style === 'link') return this.style;
    if (this.style === 'custom') return this.styleCustomName;
    return '';
  }
}
