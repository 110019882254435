import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, of } from 'rxjs';
import { TypeSearch } from '@shared/constants/directory.constants';
import { IDataLayerEvent } from 'src/app/core/interfaces';

@Component({
  selector: 'yevo-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent {
  @Input() source!: string;
  @Input() placeholder: string = '';
  @Input() startSearchAt: number = 0;
  @Input() training: boolean = false;
  @Input() allData: any[] = [];
  @Input() webinarsMostVisited: any[] = [];
  @Output() selectItem = new EventEmitter();
  @Output() dataLayerEvent = new EventEmitter<IDataLayerEvent>();
  @Output() searchEvent = new EventEmitter<any[]>();
  @Output() searchValue = new EventEmitter<any>();
  @Output() searchClear = new EventEmitter<any>();

  hasDirectory = false;
  hasCategory = false;
  isSearchBlocked = false;
  isSearched: any = [];
  dataFiltered: any = [];
  text: string = '';

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;
  openModal: boolean = false;

  webinarsInit: boolean = false;

  constructor(public http: HttpClient, config: NgbTypeaheadConfig) {
    config.showHint = true;
  }

  isDirectoryTitle(item: any): boolean {
    return item.type === TypeSearch.DIRECTORY && item.index === 0;
  }

  getDirectories(item: any) {
    if (item.type === TypeSearch.DIRECTORY) {
      return item.name || item.title;
    }
    return '';
  }

  isLastDir(item: any) {
    if (item.type === TypeSearch.DIRECTORY) {
      return item.isLast;
    }
    return false;
  }

  isCategoryTitle(item: any) {
    return item.type === TypeSearch.CATEGORY && item.index === 0;
  }

  getCategories(item: any) {
    if (item.type === TypeSearch.CATEGORY) {
      return item.name;
    }
    return '';
  }

  isLastCat(item: any) {
    if (item.type === TypeSearch.CATEGORY) {
      return item.isLast;
    }
    return false;
  }

  search = (text$: Observable<string>) => {
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      filter((term: any) => {
        this.text = term;
        this.searchValue.emit(term);
        this.isSearchBlocked = false;
        return (term.length >= this.startSearchAt && term !== '') || term === '';
      }),
      switchMap((term) =>
        of(
          term !== ''
            ? ((this.webinarsInit = true),
              this.allData.filter(
                (item) =>
                  item.title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1
              ))
            : ((this.webinarsInit = false), this.webinarsMostVisited)
        ).pipe(
          map((result: any) => {
            let recommended: any[] = [];
            this.hasDirectory = false;
            this.hasCategory = false;

            this.hasDirectory = true;
            result.forEach((dir: any, index: number) => {
              dir.type = TypeSearch.DIRECTORY;
              dir.index = index;
              recommended.push(dir);
            });

            this.dataFiltered = recommended.slice(0, 7);

            return this.dataFiltered;
          }),
          catchError((e) => {
            console.error(e);
            return of([]);
          })
        )
      )
    );
  };

  formatter = (x: { name: string }) => x.name;

  changeInputValue(value: any) {
    this.searchEvent.emit(this.dataFiltered);
    const typeahead: any = document.getElementById('typeahead');
    if (value?.item.type === TypeSearch.CATEGORY) {
      typeahead.value = '';
    }

    typeahead.value = value?.item?.title || value?.item?.name;
    this.text = value?.item?.title || value?.item?.name;
    if (!value.item?.error) {
      this.selectItem.emit(value.item);
    }
    value.preventDefault();
  }

  keyUpEnter(item: any) {
    this.searchEvent.emit(this.dataFiltered);
    this.text = item.value;
    this.selectItem.emit({ name: item.value, type: TypeSearch.SEARCH });
    this.isSearchBlocked = true;
  }

  searchDirectory() {
    const item: any = document.getElementById('typeahead');
    this.selectItem.emit({ name: item.value, type: TypeSearch.SEARCH });
    this.openModal = true;
  }

  clearSearch() {
    this.searchClear.emit(true);
    const typeahead: any = document.getElementById('typeahead');
    typeahead.value = '';
    this.text = '';
    this.openModal = false;
  }
}
