<div class="training-section-list pl-md-0 pr-md-0" *ngIf="coursesAux.length > 0">
  <div class="mb-md-0 d-flex align-items-end justify-content-center justify-content-md-start">
    <h2 class="m-0 pl-0 pl-md-5 pl-lg-0 mr-md-2 mb-3 mb-md-5">{{ title }}</h2>
    <a *ngIf="!!showAllLink" class="d-flex back-button" [routerLink]="showAllLink" queryParamsHandling="merge">
      <span class="ml-2">Ver todos</span>
    </a>
  </div>

  <div class="row pt-0 card-courses">
    <academy-course *ngFor="let course of coursesAux; let index = index" [course]="course" [isLoggedIn]="true"></academy-course>
  </div>
</div>

<!--This section show message if don't find any result in filters-->
<!--DO NOT DELETE THIS, WE WILL NEED IN THE FUTURE-->
<div class="w-full centered" *ngIf="coursesAux.length == 0">
  <br /><br />
  <img
    src="/assets/img/intranet/training/no-filter-option.png"
    alt="Por el momento no contamos con contenido disponible"
  />
  <br /><br />
  <p class="titlewarning">¡Oh no! Por el momento no contamos con contenido disponible para esta sección.</p>
  <br />
  <p class="subtitlewarning">Nos encontramos trabajando para asesorarte con nuevos contenidos.</p>
</div>
