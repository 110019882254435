import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataCalendar } from '@core/models/calendar.model';
import { DataLayerService } from '@core/services/data-layer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TYPES_EVENT } from '@shared/constants/events.constants';

@Component({
  selector: 'yevo-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;
  @Input() event!: DataCalendar;
  @Input() isLogged = false;
  @Input() type = TYPES_EVENT.ACADEMY;
  months: string[] = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SET', 'OCT', 'NOV', 'DIC'];
  eventHour = '';
  eventDay = '';
  eventMonth = '';
  eventTitle = '';
  eventDate = '';
  eventDescription = '';
  eventOrganizer = '';

  constructor(private dataLayerService: DataLayerService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.eventTitle = this.event.title ?? '';
    this.eventDescription = this.event.description ?? '';
    this.eventOrganizer = this.event.organizer ?? '';
    if (this.event.eventDate) {
      this.eventDate = this.event.eventDate;
      const [fullDate, fullHour] = this.event.eventDate.split(' ');
      if (fullHour) {
        this.eventHour = fullHour.substring(0, 5);
      }
      if (fullDate) {
        this.eventMonth = this.months[Number(fullDate.substring(5, 7)) - 1];
        this.eventDay = fullDate.substring(8, 10);
      }
    }
  }

  seeMoreEvent(): void {
    if (this.type === TYPES_EVENT.ACADEMY) {
      const landing = this.isLogged ? 'Home Interno' : 'Home Externo';
      this.dataLayerService.selectedEvent(landing, this.eventTitle);
    } else {
      const landing = this.isLogged ? 'FEN Interno' : 'FEN Externo';
      this.dataLayerService.selectedFENCalendarEvent(
        landing,
        'Entérate de los eventos que tenemos para ti',
        this.eventTitle
      );
    }
    this.modal.open(this.modalContent, { size: 'md' });
  }

  closeModal(): void {
    if (this.type === TYPES_EVENT.ACADEMY) {
      const landing = this.isLogged ? 'Home Interno' : 'Home Externo';
      this.dataLayerService.selectedButtonEventModal(landing, 'Cerrar', this.eventTitle);
    } else {
      const landing = this.isLogged ? 'FEN Interno' : 'FEN Externo';
      this.dataLayerService.selectedFENOption(
        landing,
        'Entérate de los eventos que tenemos para ti',
        this.eventTitle,
        'Cerrar'
      );
    }
    this.modal.dismissAll();
  }

  goEvent(): void {
    if (this.type === TYPES_EVENT.ACADEMY) {
      const landing = this.isLogged ? 'Home Interno' : 'Home Externo';
      this.dataLayerService.selectedButtonEventModal(landing, 'Ir a evento', this.eventTitle);
    } else {
      const landing = this.isLogged ? 'FEN Interno' : 'FEN Externo';
      this.dataLayerService.selectedFENOption(
        landing,
        'Entérate de los eventos que tenemos para ti',
        this.eventTitle,
        'Ir a evento'
      );
    }
    window.open(this.event.link, '_blank');
    this.modal.dismissAll();
  }
}
