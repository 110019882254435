import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';
import { CardCourse } from '@core/interfaces/frontend/course';
import { CourseService } from '@core/services/course.service';
import { DataLayerService } from '@core/services/data-layer.service';

@Component({
  selector: 'academy-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit, OnChanges {
  @Input() course!: CardCourse;
  @Input() isLoggedIn = false;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private courseService: CourseService,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.formatTags();
  }

  ngOnChanges(): void {
    this.formatTags();
  }

  navigateCourse(course: CardCourse): void {
    if (this.isLoggedIn) {
      this.dataLayerService.selectedHomeCard('Home Interno', 3, course.title, 'Ver curso');
      this.router.navigate([`/cursos/${course.courseId}`], { queryParamsHandling: 'merge' });
    } else {
      this.dataLayerService.selectedHomeCard('Home Externo', 4, course.title, 'Ver curso');
      this.localStorageService.set('courseId', course.courseId.toString());
      this.courseService.getCourseBySlugPublic(course.slug).subscribe((res) => {
        this.courseService.courseId = res.courseId;
        this.router.navigate([`/cursos/${course.slug}`], { queryParamsHandling: 'merge' });
      });
    }
  }

  formatTags(): void {
    this.course.tags = this.course.thumbnailTags.split(',');
  }
}
