export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.([A-Za-z]{2,3})$/;

export const NOT_NUMBER_REGEX = /^[^0-9]+$/;
export const ONLY_NUMBER_REGEX = /^[0-9]+$/;
export const ONLY_POSITIVE_NUMBER_REGEX = /^[1-9]+$/;
export const ONLY_NUMBER_WITH_DECIMALS_REGEX = /^\d*(\.\d{1,})?$/;
export const ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX = /^\d*(\.\d{1,2})?$/;

export const PHONE_NUMBER_REGEX = /^9/;
export const LANDLINE_NUMBER_REGEX = /^[0-9]{1,9}$/;

export const ONLY_LETTERS_AND_NUMBERS = /^[a-z0-9]+$/i;
export const ONLY_LETTER_AND_SPACE_REGEX = /^[a-z ]+$/i;

export const NAME_REGEX = /^[a-záéíóúñü \-\']+$/i;

export const DNI_LENGTH_PATTERN = '[0-9]{8}';
export const CE_LENGTH_PATTERN = '[0-9]{9}';
export const RUC_PATTERN = '[0-9]{11}';

//social media regex
export const FACEBOOK_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;

export const INSTAGRAM_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;

export const TIKTOK_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;

export const TWITTER_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;

export const WEBPAGE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;

// webinar regex
export const WEBINAR_YOUTUBE_LINK_REGEX = /^https?:\/\/(www\.)?youtube\.com\/watch\?v=.+$/;

export const NAME_LASTNAME_REGEX = /^[a-zA-Záéíóúñü ]{1,100}$/i;
