import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CardWebinar } from '@core/interfaces';
import { AuthService } from '@core/services/auth.service';
import { DataLayerService } from '@core/services/data-layer.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';

@Component({
  selector: 'academy-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss'],
})
export class WebinarComponent {
  @Input() webinar!: CardWebinar;
  @Input() isLoggedIn = false;
  modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private dataLayerService: DataLayerService
  ) {}

  openRegisterModal(): void {
    this.modalService.open(ModalRegisterComponent, this.modalOptionsNormal);
  }

  navigateWebinar(webinar: CardWebinar): void {
    if (this.isLoggedIn) {
      this.dataLayerService.selectedHomeCard('Home Interno', 6, webinar.title, 'Ver webinar');
      this.router.navigate([`/capacitaciones/${webinar.slug}`], { queryParamsHandling: 'merge' }).then(() => {
        window.scrollTo(0, 0);
      });
    } else {
      this.dataLayerService.selectedHomeCard('Home Externo', 6, webinar.title, 'Ver webinar');
      this.openRegisterModal();
    }
  }
}
