import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { menuDesktop, menuMobile } from '@core/data/dashboard/linkCard.json';
import savingsAccountDataJSON from '@core/data/savings-account/savings-account.data.json';
import { progressSpiralCardType } from '@shared/constants/progress-spiral.constants';
import { SAVINGS_ACCOUNT_MODALS } from '@shared/constants/savings-account.constants';
import { take } from 'rxjs/operators';
import { UserProfile } from 'src/app/core/models/UserProfileModel';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

import { DOCUMENTTYPELIST } from '@core/data/login/login.json';
import * as PROGRES_SPIRAL_DATA from '@core/data/progress-spiral/progress-spiral.data.json';
import { SavingsAccountModalComponent } from '@shared/components/savings-account-modal/savings-account-modal.component';
import { getModalOptionsWithCustomClass } from '@shared/constants/ui-modal.constants';
import { FinancingService } from 'src/app/core/services/financing.service';
import { MailchimpTransactionalService } from 'src/app/core/services/mailchimp-transactional.service';

@Component({
  selector: 'yevo-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss'],
})
export class LinkCardComponent {
  cards = menuDesktop;
  cardMobile = menuMobile;

  userLogged!: UserProfile;
  aliado: string = '';
  utmSource: string = '';
  utmMedium: string = '';
  utmCampaign: string = '';
  utmId: string = '';

  showCreditHealthCard: boolean = true;

  public readonly savingsAccountID: string = 'SAVINGS_ACCOUNT';
  public readonly savingsAccountRejectCodes: any = savingsAccountDataJSON.rejectCodes;
  public readonly modalTypes = SAVINGS_ACCOUNT_MODALS;
  public readonly creditHealthLabels: any = PROGRES_SPIRAL_DATA.creditHealthLabels;

  @Input() loader: boolean = false;
  @Input() typeCreditHealth: progressSpiralCardType = 'neutral';
  @Input() labelCreditHealth: string = '';

  constructor(
    private dataLayerService: DataLayerService,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private financingService: FinancingService,
    private mailchimpTransactionalService: MailchimpTransactionalService
  ) {
    this.loadLoggedUser();
    this.getURLparams();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.typeCreditHealth) {
      if (this.typeCreditHealth == 'negative') this.showCreditHealthCard = false;
    }
  }

  private loadLoggedUser() {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.userLogged = user!;
    });
  }

  public selectedLinkCard(item: any): void {
    if (item?.type === 'progress' && this.typeCreditHealth === 'negative') return;
    this.selectedCardEvent(item);
    if (item?.id === this.savingsAccountID) {
      this.mailchimpTransactionalService.postSavingsEmail().subscribe();
      return this.savingsAccountValidation(item?.routerLink);
    }
    if (item?.type === 'progress') {
      this.dataLayerService.selectedHomeCard('Home Interno', 1, 'Salud Crediticia', 'Descubre tu salud crediticia');
    } else {
      this.dataLayerService.selectedHomeCard(
        'Home Interno',
        2,
        'Test digital',
        'Descubre qué necesitas para vender más digitalmente'
      );
    }
    this.router.navigate(item?.routerLink, { queryParamsHandling: 'merge' });
  }

  public redirectToCreditHealth() {
    if (this.typeCreditHealth === 'negative') return;

    const cardLabel = this.getCardLabel(this.typeCreditHealth);
    this.selectedCardEvent(cardLabel, false);

    this.router.navigate(['/salud-crediticia'], { queryParamsHandling: 'merge' });
  }

  public selectedCardEvent(item: any, linkCard: boolean = true) {
    if (!linkCard) {
      return;
    }

    let cardLabel: string;
    let exist = item.routerLink.includes('/test-del-emprendedor');

    if (item.type) {
      cardLabel = this.getCardLabel(item.type);
    } else if (item.title) {
      cardLabel = item.title;
    } else {
      cardLabel = this.getCardLabel(item);
    }

    cardLabel = exist ? this.creditHealthLabels[item.type] : cardLabel;
    if (item.routerLink.includes('/salud-crediticia')) {
      this.dataLayerService.cardCreditHealth(1, 'Salud Crediticia', cardLabel);
    }
  }

  private savingsAccountValidation(redirectUrl: string[]): void {
    const urlData = {
      aliado: this.aliado,
      utmSource: this.utmSource,
      utmMedium: this.utmMedium,
      utmCampaign: this.utmCampaign,
      utmId: this.utmId,
    };
    if (this.userLogged.typeDocument == DOCUMENTTYPELIST[1].value) {
      const rejectedData = { ...urlData, rejectedReason: 'Tipo de documento no es DNI' };
      this.financingService.sendSavingsAccountSolicitudRejected(rejectedData).subscribe();
      return this.openSavingsAccountModal(this.modalTypes.FOREIGN_CLIENT);
    }
    this.router.navigate(redirectUrl, { queryParamsHandling: 'merge' });
  }

  public ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  private openSavingsAccountModal(type: string = ''): void {
    const modalRef = this.modalService.open(
      SavingsAccountModalComponent,
      getModalOptionsWithCustomClass('custom-modal-savings-account')
    );
    modalRef.componentInstance.userName = this.userLogged?.name;
    modalRef.componentInstance.redirectToDashboard = false;
    if (type) modalRef.componentInstance.type = type;
  }
  private getCardLabel(type: string) {
    return type === 'course' ? 'Conoce tus beneficios' : this.creditHealthLabels[this.typeCreditHealth];
  }

  getURLparams() {
    this.route.queryParams.subscribe((params) => {
      this.aliado = params.aliado || '';
      this.utmSource = params.utm_source || '';
      this.utmMedium = params.utm_medium || '';
      this.utmCampaign = params.utm_campaign || '';
      this.utmId = params.utm_id || '';
    });
  }
  needsLetterSpacing(title: string) {
    return title === 'Ingresa a tus cursos para emprendedores';
  }

  goToSimulator() {
    this.mailchimpTransactionalService.postSimulatorEmail().subscribe();
    this.router.navigate(['/financiamiento', 'simulador-prestamo-mibanco'], { queryParamsHandling: 'merge' });
  }
}
