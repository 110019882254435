import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mapTo, retry, shareReplay, tap } from 'rxjs/operators';
import { PendingWebinar } from 'src/app/core/interfaces';
import { environment } from 'src/environments/environment';
import { Lookup } from '../models';
import { DataDirectory, Directory, GetDirectory, IProgressBarImage, TopicViewUser } from '../models/DirectoryModel';
import { FeedbackModel } from '../models/FeedbackModel';
import { FilterRecommendedModel } from '../models/FilterRecommendedModel';
import { Image } from '../models/ImageModel';
import { TopicModel } from '../models/Topic.model';
import { TopicOptionModel } from '../models/TopicOption.model';

@Injectable({
  providedIn: 'root',
})
export class DirectoryService {
  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;
  private readonly directoryApi = `${environment.apiBaseUrl}/api-directory/directory`;
  private readonly directoryApiPresaved = `${environment.apiBaseUrl}/api-directory/directory/presaved`;
  private readonly directoryApiNotPrivate = `${environment.apiBaseUrl}/directory`;
  private readonly directoryApiPublic = `${environment.apiBaseUrl}`;
  private readonly academyHost = `${environment.apiBaseUrl}/api-academy`;

  public imageProgressBarPercentage: IProgressBarImage[] = [];
  public imageProgressBarPercentageShadow: IProgressBarImage[] = [];

  private readonly academyPublicHost = `${environment.apiBaseUrl}/api-academy/public`;

  constructor(private httpClient: HttpClient) {}

  public getImageProgress(index: number): IProgressBarImage {
    const element = this.imageProgressBarPercentageShadow.find((element) => element.id === index);
    return element!;
  }

  public getLastImageAdded(): IProgressBarImage {
    let filtered = this.imageProgressBarPercentage.filter(
      (element) => element.progress == 0 && element.uploading == true
    )!;
    this.imageProgressBarPercentage.pop();
    return filtered[0];
  }

  public addImageProgress(imageIndex: number) {
    const element = {
      id: imageIndex,
      progress: 0,
      uploading: true,
    };
    this.imageProgressBarPercentage.push(element);
    this.imageProgressBarPercentageShadow.push(element);
  }

  public setImageProgress(index: number, progress: number = 0, uploading: boolean = true) {
    this.imageProgressBarPercentageShadow.forEach((element, position) => {
      if (element.id === index) {
        element.progress = progress;
        element.uploading = uploading;
        if (uploading == false) {
          this.imageProgressBarPercentage.splice(position, 1);
          this.imageProgressBarPercentageShadow.splice(position, 1);
        }
      }
    });
  }

  getPaymentMethods(): Observable<Lookup[]> {
    return this.httpClient.get<Lookup[]>(`${this.directoryApiNotPrivate}/paymentMethods`);
  }

  getCategories(): Observable<Lookup[]> {
    return this.httpClient.get<Lookup[]>(`${this.directoryApiNotPrivate}/categories`);
  }

  getContactTypes(): Observable<Lookup[]> {
    return this.httpClient.get<Lookup[]>(`${this.directoryApiNotPrivate}/contactTypes`);
  }

  getFilterRecommended(name: string): Observable<FilterRecommendedModel> {
    const params = { name };
    return this.httpClient.get<FilterRecommendedModel>(`${this.directoryApiNotPrivate}/filter-recommended`, { params });
  }

  getMostVisited(virtual: boolean = false): Observable<any> {
    const params: any = { virtual };
    return this.httpClient.get<any>(`${this.directoryApiNotPrivate}/most-visited`, { params });
  }

  addVisited(directoryId: number): Observable<any> {
    return this.httpClient.put(`${this.directoryApiNotPrivate}/${directoryId}/visited`, {});
  }

  save(directory: Directory): Observable<number> {
    return directory.directoryId
      ? this.httpClient
          .put(`${this.directoryApi}/${directory.directoryId}`, directory)
          .pipe(mapTo(directory.directoryId))
      : this.httpClient.post<{ id: number }>(this.directoryApi, directory).pipe(map((response) => response.id));
  }

  savePresaved(directory: Directory): Observable<number> {
    return directory.directoryId
      ? this.httpClient
          .put(`${this.directoryApiPresaved}/${directory.directoryId}`, directory)
          .pipe(mapTo(directory.directoryId))
      : this.httpClient.post<{ id: number }>(this.directoryApiPresaved, directory).pipe(map((response) => response.id));
  }

  publishPreSaved(directoryId: number): Observable<any> {
    return this.httpClient.put(`${this.directoryApiPresaved}/${directoryId}/publish`, null);
  }

  getDirectoryById(directoryId: number) {
    return this.httpClient.get<Directory>(`${this.directoryApi}/${directoryId}`);
  }

  uploadImages(directoryId: number, images: Image[]): Observable<any> {
    return this.httpClient.post<any>(
      `${this.directoryApi}/${directoryId}/images`,
      { images },
      { reportProgress: true, observe: 'events', responseType: 'json' }
    );
  }

  updateProfileStory(story: string) {
    return this.httpClient.post<any>(`${this.directoryBase}/user/progress`, { progress: story });
  }

  deleteImages(directoryId: number, images: number[]): Observable<boolean> {
    return this.httpClient
      .request('DELETE', `${this.directoryApi}/${directoryId}/images`, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: images,
      })
      .pipe(mapTo(true));
  }

  getUserDirectory(directoryId: number): Observable<Directory> {
    return this.httpClient.get<Directory>(`${this.directoryApiNotPrivate}/${directoryId}`);
  }

  getDirectoryBySlug(slug: string, accessToken?: string): Observable<Directory> {
    const params = new HttpParams({
      fromObject: {
        ...(!!accessToken ? { accessToken } : null),
      },
    });

    return this.httpClient.get<Directory>(`${this.directoryApiNotPrivate}/slug/${slug}`, { params });
  }

  getUserDirectoryImages(directoryId: number): Observable<Image[]> {
    return this.httpClient
      .get<[{ directoryImageId: number; url: string }]>(`${this.directoryApiNotPrivate}/${directoryId}/images`)
      .pipe(
        map((images) => {
          return images.map((image) => {
            return {
              directoryImageId: image.directoryImageId,
              value: image.url,
            };
          });
        })
      );
  }

  getAllDirectory(payload: GetDirectory): Observable<DataDirectory[]> {
    const params: any = payload;

    return this.httpClient
      .get<DataDirectory[]>(`${this.directoryApiNotPrivate}/filter`, {
        params,
      })
      .pipe(
        retry(3),
        catchError(() => {
          return EMPTY;
        }),
        shareReplay()
      );
  }

  getAllPosts(): Observable<TopicModel[]> {
    return this.httpClient.get<TopicModel[]>(`${this.academyHost}/topics`);
  }

  getTopics(): Observable<TopicOptionModel[]> {
    return this.httpClient.get<TopicOptionModel[]>(`${this.academyHost}/params/topic-categories`).pipe(
      tap()
    );
  }

  getTopicById(id: string): Observable<TopicModel> {
    return this.httpClient.get<TopicModel>(`${this.directoryBase}/contentManager/topic/${id}`);
  }

  getTopicBySlug(slug: string, accessToken?: string): Observable<TopicModel> {
    const params = new HttpParams({
      // fromObject: {
      //   ...(!!accessToken ? { accessToken } : null),
      // },
    });
    return this.httpClient.get<TopicModel>(`${this.academyHost}/topics/slug/${slug}`, { params });
  }

  /**
   * Deprecated method getTopicBySlugVideo review modal-video.component.ts for remove this method
   */
  getTopicBySlugVideo(slug: string, accessToken?: string): Observable<TopicModel> {
    return this.httpClient.get<TopicModel>(`${this.directoryApiPublic}/contentManager/topic/slug/${slug}`);
  }

  getTopicBySlugVideoAcademy(slug: string): Observable<TopicModel> {
    return this.httpClient.get<TopicModel>(`${this.academyPublicHost}/topics/videos/slug/${slug}`);
  }

  getTopicsByCode(code: string): Observable<TopicModel[]> {
    return this.httpClient.get<TopicModel[]>(`${this.directoryBase}/contentManager/topic/code/${code}`);
  }

  getTopicsMostRecent(): Observable<TopicModel[]> {
    return this.httpClient.get<TopicModel[]>(`${this.directoryBase}/contentManager/topic/mostrecent`);
  }

  getTopicsAll(): Observable<TopicModel[]> {
    return this.httpClient.get<TopicModel[]>(`${this.directoryBase}/contentManager/topics/all`);
  }

  getTopicsMostViewed(): Observable<TopicModel[]> {
    return this.httpClient.get<TopicModel[]>(`${this.directoryBase}/contentManager/topic/mostviewed`);
  }

  addTopicViewed(topicId: string): Observable<any> {
    return this.httpClient.put(`${this.academyHost}/topics/${topicId}/view`, {});
  }

  addTopicViewedV2(topicViewUser: TopicViewUser): Observable<any> {
    return this.httpClient.post(`${this.academyHost}/topics/${topicViewUser.topicId}/view`, {});
  }

  getFeedbackByTopicId(topicId: string): Observable<FeedbackModel> {
    return this.httpClient.get<FeedbackModel>(`${this.directoryBase}/contentManager/feedback/${topicId}`);
  }

  saveFeedback(feedback: FeedbackModel): Observable<any> {
    return this.httpClient.post<any>(`${this.directoryBase}/contentManager/feedback`, feedback);
  }

  updateFeedback(feedback: FeedbackModel): Observable<any> {
    return this.httpClient.put<any>(`${this.directoryBase}/contentManager/feedback`, feedback);
  }

  getPendingWebinars(): Observable<PendingWebinar[]> {
    return this.httpClient.get<PendingWebinar[]>(`${this.directoryBase}/topic/webinar/pending`);
  }

  updatePendingWebinar(topicId: number, userId: number, webinarLink: string): Observable<any> {
    return this.httpClient.put<any>(`${this.directoryBase}/topic/webinar/pending/${topicId}`, {
      userId: userId,
      image: webinarLink,
    });
  }
}
