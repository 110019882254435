<div class="card-video">
  <div class="card-video-body">
    <div class="card-video-img">
      <img [src]="video.thumbnail" [alt]="video.title" />
    </div>
    <p class="tag">Video</p>
    <div class="card-video-text">
      <p class="description">{{ video.description }}</p>
    </div>
  </div>
  <button (click)="navigateVideo(video)">Ver video</button>
</div>
