import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataCalendar } from '@core/models/calendar.model';
import { DataLayerService } from '@core/services/data-layer.service';
import { TYPES_EVENT } from '@shared/constants/events.constants';

@Component({
  selector: 'yevo-events-section',
  templateUrl: './events-section.component.html',
  styleUrls: ['./events-section.component.scss'],
})
export class EventsSectionComponent implements OnChanges {
  @Input() events: DataCalendar[] = [];
  @Input() type = TYPES_EVENT.ACADEMY;
  @Input() isLogged = false;
  @Input() title = 'Entérate de los eventos que tenemos para ti';
  @Input() subtitle = 'Disfruta de eventos exclusivos y útiles para tu negocio. ¡Inscríbete gratis!';
  @Input() page: 'dashboard' | 'FEN' | '' = '';
  eventsToShow: DataCalendar[] = [];
  showAll = false;
  loadMoreButton = 'Ver más eventos';
  loadMoreButtonFEN = 'Cargar más eventos'; 

  constructor(private dataLayerService: DataLayerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      if (this.events.length === 0) {
        this.eventsToShow = [];
        this.showAll = false;
      } else if (this.events.length > 4 && !this.showAll) {
        this.eventsToShow = this.events.slice(0, 4);
      } else {
        this.eventsToShow = this.events;
        this.showAll = true;
      }
    }
  }

  showAllEvents(): void {
    if (this.page === 'dashboard') {
      const landing = this.isLogged ? 'Home Interno' : 'Home Externo';
      this.dataLayerService.selectedShowMoreButton(landing, 7, this.loadMoreButton);
    } else if (this.page === 'FEN') {
      const landing = this.isLogged ? 'FEN Interno' : 'FEN Externo';
      this.dataLayerService.selectedFENCalendarEvent(
        landing,
        'Entérate de los eventos que tenemos para ti',
        this.loadMoreButtonFEN
      );
    }
    this.eventsToShow = this.events;
    this.showAll = true;
  }
}
