import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from './seo.service';
import { isPwaOrWeb } from '@shared/utils/general.utils';

interface IStoreEvent {
  id: number;
  name: string;
  deliveryMethods: string;
  categories: string;
  paymentMethods: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(private seoService: SEOService, private router: Router, private activatedRoute: ActivatedRoute) {
    //DO NOT DELETE THIS COMMENT
  }

  private pushDataLayer(event: string, values: any): void {
    this.dataLayer.push({
      event: event,
      ...values,
    });
  }

  private get dataLayer() {
    return (window as { [key: string]: any })['dataLayer'];
  }

  initDataLayerConfig(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        mergeMap((event) => combineLatest([of(event), of(this.activatedRoute)])),
        map(([event, route]) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return {
            event,
            route,
          };
        }),
        filter(({ route }) => route.outlet === 'primary'),
        mergeMap(({ event, route }) => combineLatest([of(event), route.data]))
      )
      .subscribe(([event, data]) => {
        const { pageName } = data;
        const { urlAfterRedirects } = event as NavigationEnd;
        this.pushVirtualPage(urlAfterRedirects, pageName || urlAfterRedirects.substring(1));
      });
  }

  pushVirtualPage(url: string, title: string): void {
    this.pushDataLayer('virtualPage', {
      pagePath: `/academia-progreso${url}`,
      pageName: this.seoService.generateTitle(title),
      Plataforma: 'WEB',
    });
  }

  /* ==================== NAVIGATION - HEADER - REDIRECT BUTTONS ==================== */
  selectedHeaderLink(landing: string, category: string, link: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - Header`,
      label: `${category}: ${link}`,
      Plataforma: 'WEB',
    });
  }

  selectedMainButton(landing: string, button: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - Botones Principales`,
      label: `Seleccionar: ${button}`,
      Plataforma: 'WEB',
    });
  }
  /* ==================== END ==================== */

  /* ==================== EXTERNAL HOME ==================== */
  selectedDiscoverModalButton(modales: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Home Externo - Modales',
      label: `${modales}: ${option}`,
      Plataforma: 'WEB',
    });
  }

  selectedHomeCard(landing: string, code: number, cardDetail: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - ${code}`,
      label: `${cardDetail}: ${option}`,
      Plataforma: 'WEB',
    });
  }

  selectedShowMoreButton(landing: string, code: number, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} -  ${code}`,
      label: `Seleccionar: ${option}`,
      Plataforma: 'WEB',
    });
  }

  selectedEvent(landing: string, eventName: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - 7`,
      label: `Seleccionar evento: ${eventName}`,
      Plataforma: 'WEB',
    });
  }

  selectedButtonEventModal(landing: string, button: string, eventName: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - 7`,
      label: `${button}: ${eventName}`,
      Plataforma: 'WEB',
    });
  }

  selectedToolType(toolType: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Home Interno - 5',
      label: `Filtrar herramientas: ${toolType}`,
      Plataforma: 'WEB',
    });
  }

  downloadResourceFile(fileName: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Home Interno - 5',
      label: `Descargar: ${fileName}`,
      Plataforma: 'WEB',
    });
  }

  selectedCategoryFilter(filterType: string, filterOption: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Home Interno - 3',
      label: `${filterType} - ${filterOption}`,
      Plataforma: 'WEB',
    });
  }

  searchContentEvent(searchStatus: string, searchValue: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Home Interno - 3',
      label: `${searchStatus}: ${searchValue}`,
      Plataforma: 'WEB',
    });
  }
  /* ==================== END ==================== */

  /* ==================== CREDIT HEALTH ==================== */
  cardCreditHealth(codigo: number, details: string, selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Home Externo - ${codigo}`,
      label: `${details} - ${selected}`,
      Plataforma: 'WEB',
    });
  }

  startCreditHealth(selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Espiral crediticio - Paso 1`,
      label: `Seleccionar: ${selected}`,
      Plataforma: 'WEB',
    });
  }

  routeProgressCreditHealth(resource: string, details: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Espiral crediticio - Paso 2`,
      label: `Seleccionar ${resource}: ${details}`,
      Plataforma: 'WEB',
    });
  }

  selectedVideoCreditHealth(details: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Espiral crediticio - Paso 2`,
      label: `Vista previa: ${details}`,
      Plataforma: 'WEB',
    });
  }

  selectedModalCreditHealth(details: string, selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Espiral crediticio - Modales`,
      label: `${details}: ${selected}`,
      Plataforma: 'WEB',
    });
  }

  selectedTrophyCreditHealth(): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Espiral crediticio - Paso 3`,
      label: `Recibir trofeo`,
      Plataforma: 'WEB',
    });
  }

  selectedModalTrophyCreditHealth(selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Espiral crediticio - Felicitaciones`,
      label: `Seleccionar: ${selected}`,
      Plataforma: 'WEB',
    });
  }

  /* ==================== END CREDIT HEALTH ==================== */

  /* ==================== REGISTER ==================== */
  StepOneContinueRegister(selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Crea tu cuenta - Paso 1',
      label: `${selected}`,
      Plataforma: 'WEB',
    });
  }

  StepTwoContinueRegister(state: string, selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Crea tu cuenta - Paso 2',
      label: `${state}: ${selected}`,
      Plataforma: 'WEB',
    });
  }

  StepThreeSMSContinueRegister(selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Crea tu cuenta - Paso 3',
      label: `Activación SMS: ${selected}`,
      Plataforma: 'WEB',
    });
  }
  /* ==================== END REGISTER ==================== */

  /* ==================== LOGIN ==================== */
  selectedButtonLogin(state: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Inicio de sesión',
      label: `Estado: ${state}`,
      Plataforma: 'WEB',
    });
  }

  selectedButtonTermLogin(state: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: 'Home Externo - Inicio de sesión',
      label: `Seleccionar: ${state}`,
      Plataforma: 'WEB',
    });
  }
  /* ==================== LOGIN END ==================== */

  /* ==================== ENTREPRENEUR TEST ==================== */
  selectedDiagnosticType(type: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Diagnóstico digital - ${type}`,
      label: `Seleccionar: ${option}`,
      Plataforma: 'WEB',
    });
  }

  selectedAnswerDiagnosticType(type: string, option: string, step: number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Diagnóstico digital - ${type}`,
      label: `Paso ${step}: ${option}`,
      Plataforma: 'WEB',
    });
  }

  selectedViewSuggestionsDiagnosticType(type: string, suggestionType: string, suggestionTitle: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Diagnóstico digital - ${type}`,
      label: `${suggestionType}: ${suggestionTitle}`,
      Plataforma: 'WEB',
    });
  }
  /* ==================== ENTREPRENEUR TEST END ==================== */

  /* ==================== FEN ==================== */

  selectedFENOption(landing: string, type: string, option: string, label: string = 'Seleccionar') {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - ${type}`,
      label: `${label}: ${option}`,
      Plataforma: 'WEB',
    });
  }

  selectedFENCalendarEvent(landing: string, section: string, eventName: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `${landing} - ${section}`,
      label: `Seleccionar evento: ${eventName}`,
      Plataforma: 'WEB',
    });
  }

  /* ==================== FEN END ==================== */
  pushFooterMenu(option: string): void {
    this.pushVirtualEvent('Footer - Menú', option);
  }

  private pushVirtualEvent(action: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action,
      label,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushHomeLoginEvent(section: string, contentName: string) {
    this.pushVirtualEvent(`Home - Inicio de sesión - ${section}`, `Seleccionar botón: ${contentName}`);
  }

    // ********** EVENT NEW HOME ***********
    categoryNewHome(category: string, option: string) {
      this.pushDataLayer('virtualEvent', {
        category: 'MiBanco - Academia del Progreso',
        action: `Dashboard - Header`,
        label: `${category}: ${option}`,
        Plataforma: isPwaOrWeb(),
      });
    }

    pushHomeRegisterEvent(section: string, selectedCard: string) {
      this.pushVirtualEvent(`Home - Registro - ${section}`, `Seleccionar botón: ${selectedCard}`);
    }

    pushUserSession(userId: number | null, businessName: string | null): void {
      const layerInformation: any = {
        userID: userId ?? '(not available)',
        loginStatus: userId ? 'Sesión Iniciada' : 'Sesión no Iniciada',
        empresa: businessName ?? '(not available)',
        Plataforma: isPwaOrWeb(),
      };
      this.dataLayer.push(layerInformation);
    }

    /* ==================== COURSES ==================== */
  selectProgressCourseButton(courseName: string, state: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Curso - ${courseName}`,
      label: `Seleccionar: ${state}`,
      Plataforma: 'WEB',
    });
  }

  showExamModal(courseName: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Curso - ${courseName}`,
      label: `Visualizar modal de finalización`,
      Plataforma: 'WEB',
    });
  }

  selectStartQuizButton(courseName: string, buttonText: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Curso - ${courseName}`,
      label: `Seleccionar: ${buttonText}`,
      Plataforma: 'WEB',
    });
  }

  selectDownloadCertificateButton(courseName: string, action: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Academia del Progreso',
      action: `Curso - ${courseName}`,
      label: `Certificado: ${action}`,
      Plataforma: 'WEB',
    });
  }
  /* ==================== END COURSES ==================== */
}
