import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TopicModel } from 'src/app/core/models/Topic.model';
import { stripHtml } from '@shared/utils/strip-html.utils';
import { MOST_RECENT_LABEL } from '../constants/training.constants';

@Component({
  selector: 'yevo-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent {
  @Input() topic: TopicModel = {} as TopicModel;
  @Input() content: boolean = false;
  @Input() cardTitle = '';
  @Input() dashboard: boolean = false;
  @Input() education: boolean = false;
  readonly mostRecent = MOST_RECENT_LABEL;

  @Output() clickTopic: EventEmitter<TopicModel> = new EventEmitter();
  @Output() clickVideo: EventEmitter<boolean> = new EventEmitter();
  @Output() clickButtonVideo: EventEmitter<boolean> = new EventEmitter();
  @Output() clickTovideo: EventEmitter<string> = new EventEmitter();

  constructor(public sanitizer: DomSanitizer) {}

  handleClick(): void {
    this.clickTopic.emit(this.topic);
  }

  getTextFromHtml(html: string): string {
    return stripHtml(html);
  }

  getActionButtonText(type: string): string {
    if (type == 'video') {
      return 'Ver video';
    }
    if (type == 'course') {
      return 'Tomar curso';
    }
    return 'Ver más';
  }

  handleClickVideo(type: string): void {
    if (type == 'video' && this.dashboard) {
      this.clickVideo.emit(true);
    }
  }

  handleClickButtonVideo(type: string): void {
    if (type == 'video' && this.dashboard) {
      this.clickButtonVideo.emit(true);
    }
  }

  toVideo(slug: string): void {
    if (this.dashboard) {
      this.clickTovideo.emit(slug);
    }
  }
}
