import { environment } from '@environments/environment';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

export const SentryConfig = {
  dsn: environment.sentryDsn,
  environment: environment.name,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.apiBaseUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  beforeSend(event: any, hint: any): Sentry.Event | null {
    if (hint?.originalException === 'Timeout') {
      return null;
    }
    return event;
  },
  ignoreErrors: ['reCAPTCHA client element has been removed', 'fbq', 'fbq is not defined', 'Non-Error exception captured', 'Non-Error promise rejection captured'],
  tracesSampleRate: 0.3,
};
