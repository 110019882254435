<div
  id="footer-main"
  class="container-fluid pt-5 pb-4 text-left text-md-center text-lg-left"
  *ngIf="!(hideFooter$ | async)"
>
  <div class="row">
    <div class="d-none d-lg-block col-12 my-3 my-lg-0 col-lg">
      <div class="right-container">
        <a [routerLink]="navigate" queryParamsHandling="merge" (click)="isRemoveCalendar(); goHeaderSection()">
          <img class="logo-footer" src="/assets/icons/header/negative-logo.png" alt="logo negativo" />
        </a>
        <div class="col-12 my-4 copyright">
          <p> 
            &copy; {{ YEAR }} Academia del &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br />Progreso - Todos los <br /> 
            derechos reservados
          </p>
        </div>
        <div class="col-12 my-4 copyright">
          <p>
            MIBANCO - BANCO DE LA  <br />
            MICROEMPRESA S.A. <br />
            RUC 20382036655
          </p>
        </div>
      </div>
    </div>
    <!-- MOBILE VERSION-->
    <div id="firstInfoAccordion" class="d-lg-none col-12 my-lg-0">
      <button
        class="btn btn-link w-100 d-flex justify-content-between justify-content-md-center justify-content-lg-between pl-0"
        (click)="isRemoveCalendar(); questionOnClick(INDEX_FIRST_LIST)"
        [attr.aria-expanded]="isVisible[INDEX_FIRST_LIST]"
      >
        <p class="title-list m-0 mb-0 mb-lg-4">Capacitaciones</p>
        <i class="arrow-icon ml-2">
          <img
            [src]="
              isVisible[INDEX_FIRST_LIST] ? '/assets/icons/arrow-up-yellow.svg' : '/assets/icons/arrow-down-yellow.svg'
            "
            alt="arrow"
          />
        </i>
      </button>
      <ul class="collapse list-unstyled footer-nav mt-3" [ngbCollapse]="!isVisible[INDEX_FIRST_LIST]">
        <li *ngFor="let footerRoute of footerOneColumnRoutesMobile; trackBy: trackByIndex">
          <!-- DEFAULT LINK -->
          <div *ngIf="!footerRoute.external && footerRoute.community">
            <a
              *ngIf="isHidden(footerRoute.hiddenIn) | async"community
              class="text-white"
              queryParamsHandling="merge"
              routerLinkActive="active"
              data-layer-section="Footer"
              (click)="isRemoveCalendar(); footerLinkOnClick($event); onLinkCourses('COMMUNITY_COURSE')"
            >
              <span data-layer-section="Footer - Menu" [innerHTML]="footerRoute.name"></span>
            </a>
          </div>
          <div *ngIf="!footerRoute.external && footerRoute.calendar">
            <a
              *ngIf="isHidden(footerRoute.hiddenIn) | async"
              class="text-white"
              [routerLink]="footerRoute.routerLink"
              queryParamsHandling="merge"
              routerLinkActive="active"
              data-layer-section="Footer"
              (click)="footerLinkOnClick($event); gotoCalendar()"
            >
              <span data-layer-section="Footer - Menu" [innerHTML]="footerRoute.name"></span>
            </a>
          </div>
          <div *ngIf="!footerRoute.external && !footerRoute.community && !footerRoute.calendar">
            <a
              *ngIf="isHidden(footerRoute.hiddenIn) | async"
              class="text-white"
              queryParamsHandling="merge"
              routerLinkActive="active"
              data-layer-section="Footer"
              (click)="goToSimulator(footerRoute); isRemoveCalendar(); footerLinkOnClick($event); onLink(footerRoute)"
            >
              <span data-layer-section="Footer - Menu" [innerHTML]="footerRoute.name"></span>
            </a>
          </div>

          <!-- TEMPORARY LINK -->
          <div *ngIf="footerRoute.external">
            <a
              *ngIf="isHidden(footerRoute.hiddenIn) | async"
              class="text-white"
              [href]="footerRoute.routerLink"
              target="_blank"
              data-layer-section="Footer"
              (click)="isRemoveCalendar(); footerLinkOnClick($event)"
            >
              <span data-layer-section="Footer - Menu" [innerHTML]="footerRoute.name"></span>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div id="fiveInfoAccordion" class="d-lg-none col-12 my-lg-0">
      <button
        class="btn btn-link w-100 d-flex justify-content-between justify-content-md-center justify-content-lg-between pl-0"
        (click)="questionOnClick(5)"
        [attr.aria-expanded]="isVisible[5]"
      >
      <p class="title-list m-0 mb-0 mb-lg-4">Academia de Progreso</p>
        <i class="arrow-icon ml-2">
          <img
            [src]="isVisible[5] ? '/assets/icons/arrow-up-yellow.svg' : '/assets/icons/arrow-down-yellow.svg'"
            alt="arrow"
          />
        </i>
      </button>
      <ul class="collapse list-unstyled footer-nav mt-3" [ngbCollapse]="!isVisible[5]">
        <li *ngFor="let footerRoute of footerTwoColumnRoutesMobile; trackBy: trackByIndex">
          <a
            class="text-white"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="isRemoveCalendar(); footerLinkOnClick($event); onLink(footerRoute)"
          >
            <span [innerHTML]="footerRoute.name"></span>
          </a>
        </li>
      </ul>
    </div>
    <!-- END MOBILE VERSION-->

    <!-- DESKTOP VERSION-->
    <div class="d-none d-lg-block col-12 my-3 my-lg-0 col-lg">
      <p class="title-list mb-0 mb-lg-4">Capacitaciones</p>
      <ul class="list-unstyled footer-nav mt-3">
        <li *ngFor="let footerRoute of footerFirstColumnRoutes; trackBy: trackByIndex">
          <!-- DEFAULT LINK -->
          <a
            *ngIf="!footerRoute.external && footerRoute.calendar"
            class="text-white"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="footerLinkOnClick($event); gotoCalendar()"
          >
            <span [innerHTML]="footerRoute.name"></span>
          </a>

          <a
            *ngIf="!footerRoute.external && !footerRoute.calendar"
            class="text-white"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="onLink(footerRoute)"
          >
            <span [innerHTML]="footerRoute.name"></span>
          </a>
          <!-- TEMPORARY LINK -->
          <a
            *ngIf="footerRoute.external"
            class="text-white"
            target="_blank"
            (click)="isRemoveCalendar(); footerLinkOnClick($event); onLink(footerRoute)"
          >
            <span [innerHTML]="footerRoute.name"></span>
          </a>
        </li>
        <li class="menu-community">
          <a class="text-white">
            <span>&nbsp;&nbsp;</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="d-none d-lg-block col-12 my-3 my-lg-0 col-lg-2">
      <p class="title-list m-0 mb-0 mb-lg-4"></p>
      <ul class="list-unstyled footer-nav mt-3">
        <li *ngFor="let footerRoute of footerSecondColumnRoutes; trackBy: trackByIndex">
          <a
            class="text-white"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="isRemoveCalendar(); footerLinkOnClick($event); onLink(footerRoute)"
          >
            <span [innerHTML]="footerRoute.name"></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="d-none d-lg-block col-12 my-3 my-lg-0 col-lg-3">
      <p class="title-list m-0 mb-0 mb-lg-4"></p>
      <ul class="list-unstyled footer-nav mt-3">
        <li *ngFor="let footerRoute of footerThirdColumnRoutes; trackBy: trackByIndex">
          <a
            class="text-white"
            [routerLink]="footerRoute.routerLink"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="isRemoveCalendar(); footerLinkOnClick($event)"
          >
            <span [innerHTML]="footerRoute.name"></span>
          </a>
        </li>
      </ul>
    </div>
    <!-- END DESKTOP VERSION-->
    <div class="col-12 col-lg text-center">
      <div class="left-container-book">
        <div class="btn-login-container mb-4" *ngIf="!isLOgged">
          <a
            [hidden]="authService.isLogged$ | async"
            class="btn btn-login"
            [routerLink]="['/ingresar']"
            queryParamsHandling="merge"
            data-layer-section="Footer"
            (click)="isRemoveCalendar(); dataLayerService.pushHomeLoginEvent('Footer', 'Iniciar sesión')"
          >
            Iniciar sesión</a
          >
        </div>
        <div class="btn-login-container center" *ngIf="isLOgged">
          <a class="btn btn-login-logged" [routerLink]="['/nuevo-intranet', 'dashboard']" queryParamsHandling="merge">
            <img src="/assets/icons/home-outline.svg" width="32" height="32" alt="home" class="image-home" />Ir al
            Inicio</a
          >
        </div>

        <div class="d-block d-lg-block book-title">
          <a
            href="https://www.mibanco.com.pe/libro-reclamaciones"
            target="_blank"
            class="claim d-flex justify-content-center  mb-4"
            (click)="isRemoveCalendar()"
          >
            <svg-icon-sprite [src]="'reclamaciones-secondary'" [width]="'19px'"></svg-icon-sprite>
            <span class="align-self-center ml-3"> Libro de reclamaciones </span>
          </a>
        </div>
      </div>
    </div>

    <div class="d-lg-none col-12 col-lg-3 text-center">
      <a [routerLink]="navigate" queryParamsHandling="merge" (click)="isRemoveCalendar(); goHeaderSection()">
        <img class="logo-footer" src="/assets/icons/header/negative-logo.png" alt="logo negativo" />
      </a>
      <div class="col-12 my-4 my-lg-0 copyright">
        <p>&copy; {{ YEAR }} Academia del Progreso - Todos los derechos reservados</p>
      </div>
      <div class="col-12 my-4 my-lg-0 copyright mb-5">
        <p>MIBANCO - BANCO DE LA MICROEMPRESA S.A. <br />RUC 20382036655</p>
      </div>
    </div>
  </div>
</div>